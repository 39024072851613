import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number): string {
    if (!value || !limit) {
      return value;
    }

    if (value.length > limit + 3) {
      return value.substring(0, limit) + '...';
    }

    return value;
  }

}