

<app-modal-header
  (close)="onCancel()">
  <span slot="title">{{ 'admin.event-publish-missing-info-modal.title' | translate }}</span>
</app-modal-header>

<div class="modal-body">

  <app-org-legal-name-input *ngIf="legalNameFC" [parentForm]="form"></app-org-legal-name-input>

  <app-org-terms-conditions-inputs *ngIf="orgTosFC" [parentForm]="orgTosFC"></app-org-terms-conditions-inputs>

  <app-org-privacy-policy-inputs *ngIf="orgPrivacyPolicyFC" [parentForm]="orgPrivacyPolicyFC"></app-org-privacy-policy-inputs>

  <app-invoice-profile-input *ngIf="invoiceProfileFC" [invoiceProfileFC]="invoiceProfileFC"></app-invoice-profile-input>

</div>

<div class="modal-footer">
  <button
    (click)="onCancel()"
    pButton
    label="{{ 'admin.event-publish-missing-info-modal.cancel-btn.label' | translate }}"
    class="p-button-sm p-button-plain p-button-text">
  </button>

  <button
    (click)="onSubmit()"
    [loading]="saving"
    pButton
    label="{{ 'admin.event-publish-missing-info-modal.submit-btn.label' | translate }}"
    class="p-button-sm p-button-success p-button-text">
  </button>
</div>
