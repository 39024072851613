import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { ICoordinator } from 'src/app/shared/models/user/coordinator/coordinator.model';
import { CallableNames, DbService } from '../../../db.service';
import { IUser } from 'src/app/shared/models/user/user.model';

interface callablesCoordinatorGetForEventFilterData {
  orgId: number;
}

@Injectable({
  providedIn: 'root'
})
export class CoordinatorsService {

  constructor(
    private dbService: DbService,
    private ff: Functions
  ) { }

  public getCoordinatorsForEventFilter(data: callablesCoordinatorGetForEventFilterData): Observable<(ICoordinator & { user: IUser })[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCoordinatorGetForEventFilter, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getAllCoordinatorsInOrganization(organizationId: number): Observable<ICoordinator[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesUsersCoordinatorGet, data: { organizationId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
