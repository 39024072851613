
import { Component, ElementRef, HostListener, inject, OnDestroy, OnInit } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  template: ''
})
export class Modal implements OnInit, OnDestroy {

  modalId: number = Math.random();

  ref: NgbModalRef | undefined;

  elRef: ElementRef = inject(ElementRef);

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    if (window.history.state?.lektoryModal !== this.modalId) {
      this.close();
    }
  }

  // Unique observers for dynamic element tracking and scroll monitoring.
  private _modalScrollHostObserver: MutationObserver | undefined;
  private _modalScrollContentObserver: MutationObserver | null = null;
  private _modalScrollResizeObserver: ResizeObserver | null = null;

  // Unique element references.
  private _modalScrollBody: HTMLElement | null = null;
  private _modalScrollFooter: HTMLElement | null = null;
  private _modalScrollHeader: HTMLElement | null = null;

  ngOnInit(): void {
    // Set up an observer on the host element to catch dynamic changes.
    this._modalScrollHostObserver = new MutationObserver(() => {
      this._checkForModalElements();
    });
    this._modalScrollHostObserver.observe(this.elRef.nativeElement, { childList: true, subtree: true });

    // Run an initial check.
    this._checkForModalElements();
  }

  /**
   * Checks for the presence of .modal-body, .modal-footer, and .modal-header.
   * If their presence changes, (re)initialize or tear down the dedicated observers.
   */
  private _checkForModalElements(): void {
    const newModalBody = this.elRef.nativeElement.querySelector('.modal-body');
    const newModalFooter = this.elRef.nativeElement.querySelector('.modal-footer');
    const newModalHeader = this.elRef.nativeElement.querySelector('.modal-header');

    // If any element has changed (or been removed), disconnect old observers.
    if (
      newModalBody !== this._modalScrollBody ||
      newModalFooter !== this._modalScrollFooter ||
      newModalHeader !== this._modalScrollHeader
    ) {
      if (this._modalScrollContentObserver) {
        this._modalScrollContentObserver.disconnect();
        this._modalScrollContentObserver = null;
      }
      if (this._modalScrollResizeObserver) {
        this._modalScrollResizeObserver.disconnect();
        this._modalScrollResizeObserver = null;
      }

      this._modalScrollBody = newModalBody;
      this._modalScrollFooter = newModalFooter;
      this._modalScrollHeader = newModalHeader;

      // Only attach observers if modal body is present and at least one of header/footer exists.
      if (this._modalScrollBody && (this._modalScrollFooter || this._modalScrollHeader)) {
        this._initModalScrollObservers();
      } else {
        if (this._modalScrollFooter) {
          this._modalScrollFooter.classList.remove('modal-footer--shadow');
        }
        if (this._modalScrollHeader) {
          this._modalScrollHeader.classList.remove('modal-header--shadow');
        }
      }
    } else {
      // If nothing changed, still check the scroll state.
      this._checkScroll();
    }
  }

  /**
   * Sets up a MutationObserver and ResizeObserver on the modal body
   * to monitor its scrollability, updating the header and footer accordingly.
   */
  private _initModalScrollObservers(): void {
    const checkScroll = () => {
      if (this._modalScrollBody) {
        const isScrollable = this._modalScrollBody.scrollHeight > this._modalScrollBody.clientHeight;
        if (this._modalScrollFooter) {
          if (isScrollable) {
            this._modalScrollFooter.classList.add('modal-footer--shadow');
          } else {
            this._modalScrollFooter.classList.remove('modal-footer--shadow');
          }
        }
        if (this._modalScrollHeader) {
          if (isScrollable) {
            this._modalScrollHeader.classList.add('modal-header--shadow');
          } else {
            this._modalScrollHeader.classList.remove('modal-header--shadow');
          }
        }
      }
    };

    // Run an initial check.
    checkScroll();

    // Observe content changes in the modal body.
    this._modalScrollContentObserver = new MutationObserver(checkScroll);
    if (this._modalScrollBody) {
      this._modalScrollContentObserver.observe(this._modalScrollBody, {
        childList: true,
        subtree: true,
        characterData: true
      });
    }

    // dont do this for now
    // // Observe size changes in the modal body (if supported).
    // if (window.ResizeObserver) {
    //   this._modalScrollResizeObserver = new ResizeObserver(checkScroll);
    //   this._modalScrollResizeObserver.observe(this._modalScrollBody);
    // }
  }

  /**
   * Manually checks whether the modal body is scrollable and toggles shadow classes
   * on the header and footer accordingly.
   */
  private _checkScroll(): void {
    if (this._modalScrollBody) {
      const isScrollable = this._modalScrollBody.scrollHeight > this._modalScrollBody.clientHeight;
      if (this._modalScrollFooter) {
        if (isScrollable) {
          this._modalScrollFooter.classList.add('modal-footer--shadow');
        } else {
          this._modalScrollFooter.classList.remove('modal-footer--shadow');
        }
      }
      if (this._modalScrollHeader) {
        if (isScrollable) {
          this._modalScrollHeader.classList.add('modal-header--shadow');
        } else {
          this._modalScrollHeader.classList.remove('modal-header--shadow');
        }
      }
    }
  }

  close() {
    this.ref?.close();
  }


  ngOnDestroy(): void {
    setTimeout(() => { // vznika race condition (asi?) .. na ios se stavalo to, že
      // kdyz byl otevreny modal, ktery po kliknuti na btn mel sebe zavrit a otevrit dalsi modal,
      // tak se ten druhy modal okamzite zavrel, protoze zachytil popstate event z toho prvniho modalu
      if (this.ref && window.history.state?.lektoryModal === this.modalId) {
        window.history.replaceState({}, '', window.location.href);
      }
    });

    // Disconnect all observers to avoid memory leaks.
    this._modalScrollHostObserver?.disconnect();
    this._modalScrollContentObserver?.disconnect();
    this._modalScrollResizeObserver?.disconnect();
  }
}
