
<ng-container *ngIf="order">

  <div *ngIf="orderItem as item" class="flex ">

    <div class="flex-1">
      <h5 class="m-0 mb-1">
        {{ (item.itemTitle | itemTitle:item.count | async) }}
      </h5>
      
      <div class=" text-color-secondary">
        <div *ngIf="item.shopItem as si" class="details-block-wrap ">
          <span *ngIf="siHelper.getSessionGroupDate(si.sessionGroup) as sgDate" class="detail-block">
            {{ sgDate | translate }}
          </span>
      
          <span *ngIf="siHelper.getSessionGroupTime(si.sessionGroup) as sgTime" class="detail-block">
            {{ sgTime }}
          </span>
      
          <span *ngIf="siHelper.getSessionGroupLocation(si.sessionGroup, true) as sgLocation" class="detail-block">
            <span [ngbTooltip]="sgLocation" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
              {{ sgLocation }}
            </span>
          </span>
        </div>

        <div class="details-block-wrap">
          <span class="detail-block">
            {{ orderItem.reservation | reservationUsers }}
          </span>
        </div>

        <span *ngIf="orderHelper.getIsOrderItemCancelled(item)" class="flex align-items-center">
          <i class="pi pi-times mr-2"></i>
          <span>
            {{ 'admin.customer-card-order-order-item.cancelled' | translate }}
          </span>
        </span>
        
  
        <div *ngIf="item.reservation.partnerReservation?.beneficiaryUser as bu" class="flex align-items-center">
          <i class="pi pi-ticket mr-2"></i>
          <span>
           {{ 'admin.customer-card-order-order-item.in-couple-with' | translate:{partner: bu.orgUserObj| userNameOrEmail:false } }}
          </span>
        </div>
  
        <ng-container *ngIf="!orderHelper.getIsOrderItemCancelled(item)">
  
          <div *ngIf="item.reservation.partnerReservation?.order?.state === OrderState.PENDING" class="flex align-items-center text-orange-400">
            <i class="pi pi-clock mr-2"></i>
            <span>
              {{ 'admin.customer-card-order-order-item.waiting-for-partner-payment' | translate:{date: item.reservation.partnerReservation?.order?.expiration | localizedDate:'d.M.'} }}
            </span>
          </div>
  
          <div *ngIf="!item.reservation.partnerReservation?.order && item.reservation.partnerReservation?.state === ReservationState.APPROVED" class="flex align-items-center text-orange-400">
            <i class="pi pi-clock mr-2"></i>
            <span>
              {{ 'admin.customer-card-order-order-item.waiting-for-partner-to-order' | translate:{date: item.reservation.partnerReservation?.expiration | localizedDate:'d.M.'} }}
            </span>
          </div>
  
        </ng-container>
  
  
      </div>
  
    </div>
  
    <div class="flex flex-column">
      <span class="font-bold flex flex-column align-items-center justify-content-end">
        <span [class.line-through]="orderHelper.getIsOrderItemCancelled(item)">
          {{ (([{ value: item.basePriceValue, currency: item.currency }] | price | async) | itemTitle:item.count | async) }}
        </span>
        @if (item.vat) {
          <span class="text-xs text-bluegray-200 align-self-end">
            {{ 'customer-orders.orders.item.including-vat-label' | translate }}
          </span>
        }
      </span>
  
      <span *ngIf="menuItems.length" class="flex justify-content-end">
        <button
          (click)="menu.toggle($event)"
          pButton
          icon="pi pi-ellipsis-h"
          class="p-button-rounded p-button-text p-button-plain p-button-sm"
        ></button>
        <p-menu
          #menu
          [model]="menuItems"
          [popup]="true"
          [baseZIndex]="1000"
          appendTo="body">
        </p-menu>
      </span>
    </div>
  </div>

</ng-container>