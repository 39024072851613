import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { IPublicTag } from 'src/app/shared/models/public-tag/public-tag.model';
import { IPublicTagGroup } from 'src/app/shared/models/public-tag/public-tag-group.model';

interface IPublicTagsGetForSiPublicTagsModalData {
  shopItemId: number;
}
interface IPublicTagsGetForSiPublicTagsModalDataResult {
  shopItem: IShopItem & { publicTags: IPublicTag[], sessionGroup: any };
  orgPublicTagsWithNullGroup: (IPublicTag & { publicTagGroup: null })[];
  orgPublicTagGroups: (IPublicTagGroup & { publicTags: IPublicTag[] })[];
}

export interface IPublicTagsCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
  code: string | null;
  publicTagGroupId: number | null;
  shopItemId: number | null;
};

export interface IPublicTagsUpdateData {
  publicTagId: number;

  title: string;
  shortDescription: string | null;
  code: string | null;
  publicTagGroupId: number | null;
};

interface IPublicTagGroupsGetAllByOrgData {
  orgId: number;
}

export interface IPublicTagGroupCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
};
export interface IPublicTagGroupUpdateData {
  publicTagGroupId: number;
  
  title: string;
  shortDescription: string | null;
};

interface IPublicTagsSetToShopItemData {
  shopItemId: number;

  publicTagIds: number[];
};

interface IPublicTagsArchiveData {
  publicTagId: number;
};

interface ICallablesPublicTagsGetForFilterItemData {
  orgId: number;
}
type ICallablesPublicTagsGetForFilterItemResult = {
  id: number;
  title: string;
  publicTagGroup: {
    id: number;
    title: string;
  } | null;
}[];

interface IPublicTagUnarchiveData {
  publicTagId: number;
};

@Injectable({
  providedIn: 'root'
})
export class PublicTagsService {

  constructor(
    private dbService: DbService
  ) { }

  public unarchive(data: IPublicTagUnarchiveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsUnarchive, data });
    return this.dbService.handleObs(obs);
  }

  public getForFilterItem(data: ICallablesPublicTagsGetForFilterItemData): Observable<ICallablesPublicTagsGetForFilterItemResult>{
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsGetForFilterItem, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public archivePublicTag(data: IPublicTagsArchiveData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsArchive, data });
    return this.dbService.handleObs(obs);
  }

  public setToShopItem(data: IPublicTagsSetToShopItemData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsSetToShopItem, data });
    return this.dbService.handleObs(obs);
  }

  public updatePublicTagGroup(data: IPublicTagGroupUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagGroupUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createPublicTagGroup(data: IPublicTagGroupCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagGroupCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getAllOrgPublicTagGroups(data: IPublicTagGroupsGetAllByOrgData): Observable<IPublicTagGroup[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagGroupsGetAllByOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updatePublicTag(data: IPublicTagsUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createPublicTag(data: IPublicTagsCreateData): Observable<IPublicTag> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getForSiPublicTagsModal(data: IPublicTagsGetForSiPublicTagsModalData): Observable<IPublicTagsGetForSiPublicTagsModalDataResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPublicTagsGetForSiPublicTagsModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
