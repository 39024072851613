import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslateModule } from '@ngx-translate/core';
import { CoordinatorsService } from 'src/app/shared/services/entities/users/coordinators/coordinators.service';
import { UserHelperService } from 'src/app/shared/services/helpers/user-helper.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_COORDINATOR = {
  type: EventShopItemFilterItemType.COORDINATOR
  value: SelectItem[] | null;
}


@Component({
  selector: 'app-event-si-filter-item-coordinator',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    MultiSelectModule,
    FilterItemChipsComponent,
  ],
  templateUrl: './event-si-filter-item-coordinator.component.html',
  styleUrl: './event-si-filter-item-coordinator.component.scss'
})
export class EventSiFilterItemCoordinatorComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_COORDINATOR | undefined;
  @Input({required: true}) orgId: number | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_COORDINATOR>();

  FC = new FormControl<number[] | null>(null);

  coordinatorOptions: SelectItem[] = [];
  fetchingCoordinators: boolean = false;
  coordinatorsFetched: boolean = false;
  chips: IFilterItemChip[] = [];

  subs: Subscription[] = [];

  constructor(
    private coordinatorsService: CoordinatorsService,
    private userHelper: UserHelperService,
  ) {
    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedOptions });
        this.updateChips();
      })
    )
  }

  ngOnInit(): void {
    this.coordinatorOptions = [ ...(this.item?.value ?? []) ];
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? [], { emitEvent: false });
    this.updateChips();
  }

  fetchCoordinators() {
    if (!this.orgId) return;
    if (this.coordinatorsFetched) return;
    
    this.fetchingCoordinators = true;
    this.coordinatorsService.getCoordinatorsForEventFilter({
      orgId: this.orgId,
    }).subscribe({
      next: res => {
        this.fetchingCoordinators = false;
        this.coordinatorsFetched = true;

        this.coordinatorOptions = res.map(x => ({ value: x.id, label: this.userHelper.getOrgUserFullname(x.user.orgUsers, this.orgId ?? null, false) }));
      }
    })
  }

  onRemoveChip(chip: IFilterItemChip) {
    this.FC.setValue(this.FC.value?.filter(x => x !== chip.value) ?? []);
  }

  get selectedOptions() {
    return this.coordinatorOptions.filter(x => this.FC.value?.includes(x.value));
  }

  private updateChips() {
    this.chips = this.selectedOptions.map(x => {
      const chip: IFilterItemChip = {
        label: x.label,
        value: x.value
      };
      return chip;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

}
