

@if (reservation; as r) {

  @if (r.entityType === ReservationTableEntityType.RESERVATION) {
    <app-price-cell-reservation-entity [reservation]="r"></app-price-cell-reservation-entity>
  } @else if (r.entityType === ReservationTableEntityType.INVITATION) {
    <app-price-cell-invitation-entity [invitation]="r"></app-price-cell-invitation-entity>
  }

}

