import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';

export type ILegalNameFC = FormControl<string | null>;

export const getLegalNameFC = (required: boolean = true): ILegalNameFC => {
  const validators: any = [];
  if (required) validators.push( Validators.required );

  return new FormControl(null, { validators: validators });
}

@Component({
  selector: 'app-org-legal-name-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    InputTextModule
  ],
  templateUrl: './org-legal-name-input.component.html',
  styleUrls: ['./org-legal-name-input.component.scss']
})
export class OrgLegalNameInputComponent {

  @Input() parentForm: FormGroup | undefined;
  @Input() placeholder: string = 'admin.org-legal-name-input.placeholder';

}
