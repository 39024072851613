import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Countries, ICountry } from 'src/app/shared/enums/utils/countries';
import { min, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { InputMaskModule } from 'primeng/inputmask';
import { UtilsService } from '../../services/utils.service';
import { CustomValidators } from '../../services/form/custom-validators';

interface IFormGroup {
  dialCode: FormControl<string>;
  phoneLength: FormControl<number>;
  minPhoneLength: FormControl<number>;
  maxPhoneLength: FormControl<number>;
  phoneNumber: FormControl<string | null>;
}

interface ISelectItem {
  country: string;
  value: string;
  label: string;
  selectedLabel: string;
}

@Component({
  selector: 'app-phone-number-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, DropdownModule, InputMaskModule,
  ],
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {

  @Input({required: true}) FC?: FormControl<string | null>;
  @Input() placeholder?: string;
  @Input() required: boolean = false;
  @Input() markAsTouched$?: Subject<boolean>;

  form?: FormGroup<IFormGroup>;

  dialCodesOptions: ISelectItem[] = Countries.map((country) => {
    const translationCode = `country.${country.code.toLowerCase()}`;
    let countryNameTranslated = this.translateService.instant(`country.${country.code.toLowerCase()}`);
    if (countryNameTranslated === translationCode) {
      countryNameTranslated = country.name;
    }
    return {
      value: country.dialCode,
      label: `${country.emoji} ${countryNameTranslated.slice(0, 26) + (countryNameTranslated.length > 26 ? '...' : '')} ${country.dialCode}`,
      country: countryNameTranslated,
      selectedLabel: `${country.emoji} ${country.dialCode}`
    };
  });

  maskValidator: ValidatorFn | null = null;

  phoneNumberStatus: 'DISABLED' | 'VALID' | 'INVALID' | 'PENDING' = 'VALID';

  lastFCStatus: string | null = null;

  subs: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.maskValidator = CustomValidators.maskInputValidator(9);
    this.form = new FormGroup({
      dialCode: new FormControl({ value: '+420', disabled: this.FC?.disabled || false }, { nonNullable: true }),
      phoneNumber: new FormControl<string | null>({ value: null, disabled: this.FC?.disabled || false }, { nonNullable: true, validators: this.required ? [Validators.required, this.maskValidator] : [this.maskValidator] }),
      minPhoneLength: new FormControl({ value: 9, disabled: this.FC?.disabled || false }, { nonNullable: true }),
      maxPhoneLength: new FormControl({ value: 9, disabled: this.FC?.disabled || false }, { nonNullable: true }),
      phoneLength: new FormControl({ value: 9, disabled: this.FC?.disabled || false }, { nonNullable: true })
    });

    this.subs.push(
      this.FC?.valueChanges.subscribe((value) => {
        const formValue = this.form?.getRawValue();
        if (value && value !== `${formValue?.dialCode}${formValue?.phoneNumber}`) {
          this.patchForm(value);
        }
      }) || Subscription.EMPTY,
      this.FC?.statusChanges.subscribe((status) => {
        if (this.lastFCStatus !== status) {
          this.lastFCStatus = status;
          if (this.FC?.disabled) {
            this.form?.controls.dialCode.disable();
            this.form?.controls.phoneNumber.disable();
            this.form?.controls.phoneLength.disable();
          } else {
            this.form?.controls.dialCode.enable();
            this.form?.controls.phoneNumber.enable();
            this.form?.controls.phoneLength.enable();
          }
        }
      }) || Subscription.EMPTY
    );

    if (this.FC?.value) {
      this.patchForm(this.FC.value);
    }

    this.subs.push(this.form.valueChanges.subscribe((form) => {
      if (form.phoneNumber && !form.phoneNumber.includes('_')) {
        this.FC?.setValue(`${form.dialCode}${form.phoneNumber}`);
      } else {
        this.FC?.setValue(null);
      }
      this.FC?.markAsDirty();
    }));
    this.subs.push(this.form.controls.phoneNumber.statusChanges.subscribe((status) => {
      this.phoneNumberStatus = status;
    }));
    this.subs.push(this.form.controls.dialCode.valueChanges.subscribe((dialCode) => {
      const country = Countries.find((country) => country.dialCode === dialCode);
      if (!country) {
        console.error('Country not found!');
      }
      if (this.maskValidator) this.form?.controls.phoneNumber.removeValidators(this.maskValidator);
      this.maskValidator = CustomValidators.maskInputValidator(country?.minPhoneLength ?? country?.phoneLength ?? 10);
      this.form?.controls.minPhoneLength.setValue(country?.minPhoneLength ?? country?.phoneLength ?? 10);
      this.form?.controls.maxPhoneLength.setValue(country?.maxPhoneLength ?? country?.phoneLength ?? 10);
      this.form?.controls.phoneNumber.addValidators(this.maskValidator);
      this.form?.controls.phoneNumber.updateValueAndValidity();
    }));

    this.subs.push(this.markAsTouched$?.subscribe((markAsTouched) => {
      if (markAsTouched) this.form?.controls.phoneNumber?.markAsDirty();
      if (this.form?.controls.phoneNumber.invalid) this.phoneNumberStatus = 'INVALID';
    }) || Subscription.EMPTY);
  }

  patchForm(value: string) {
    const possibleDialCodes = [
      value.slice(0, 2),
      value.slice(0, 3),
      value.slice(0, 4),
      value.slice(0, 5)
    ];
    const foundPossibleCountries: ICountry[] = [];
    possibleDialCodes.forEach((possibleDialCode) => {
      Countries.forEach((country) => {
        const phoneLength = country.phoneLength || 10;
        if (country.dialCode === possibleDialCode && (country.dialCode.length + phoneLength) === value.length) foundPossibleCountries.push(country);
      });

      if (!foundPossibleCountries.length) {
        Countries.forEach((country) => {
          const minPhoneLength = country.minPhoneLength || 10;
          const maxPhoneLength = country.maxPhoneLength || 10;
          if (country.dialCode === possibleDialCode && (country.dialCode.length + minPhoneLength) <= value.length && (country.dialCode.length + maxPhoneLength) >= value.length) foundPossibleCountries.push(country);
        });
      }
    });
    if (foundPossibleCountries.length) {
      if (foundPossibleCountries.length > 1) {
        this.utilsService.logError(`found more than 1 foundPossibleCountries for input: ${JSON.stringify(value)} - ${JSON.stringify(foundPossibleCountries)}`);
      }
      const country = foundPossibleCountries.at(0)!;
      this.form?.patchValue({
        dialCode: country.dialCode,
        phoneLength: country.phoneLength ?? 10,
        minPhoneLength: country.minPhoneLength ?? country.phoneLength ?? 10,
        maxPhoneLength: country.maxPhoneLength ?? country.phoneLength ?? 10,
        phoneNumber: value.slice(country.dialCode.length)
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}
