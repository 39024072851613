<div class="flex flex-1 flex-column">
  <div>
  
    <label class="text-xs text-bluegray-400">
      {{ 'admin.reservation-table.item-filter-modal.states-label' | translate }}
    </label>

    <p-multiSelect
      [options]="stateMultiSelectItems"
      [formControl]="stateMultiSelectFC"
      optionLabel="label"
      optionValue="value"
      display="chip"
      [showClear]="false"
      [filter]="false"
      [showToggleAll]="false"
      [maxSelectedLabels]="100"
      [displaySelectedLabel]="false"
      [placeholder]="('admin.reservation-table.item-filter-modal.states-placeholder' | translate)"/>

  </div>
  
  <div class="divider"></div>
  
  <div class="flex flex-1 flex-column">
    <ng-container *ngIf="fetchingItems$ | async; else fetched">
      <div class="flex justify-content-center align-items-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-container>
  
    <ng-template #fetched>
  
      <label class="flex justify-content-between">
        <div>
          @if (showLabels) {
            {{ 'admin.reservation-table.item-filter-modal.items-label' | translate }}
            <ng-container *ngIf="this.itemsForm.controls.shopItemIds.value.length === 0">
              {{ 'admin.reservation-table.item-filter-modal.selected-items-all-label' | translate }}
            </ng-container>
            <ng-container *ngIf="this.itemsForm.controls.shopItemIds.value.length > 0">
              {{ 'admin.reservation-table.item-filter-modal.selected-items-cnt-label' | translate:{cnt:this.itemsForm.controls.shopItemIds.value.length} }}
            </ng-container>
          }
        </div>
  
        <div>
          <span *ngIf="this.itemsForm.controls.shopItemIds.value.length > 0" (click)="onResetItems()" class="cursor-pointer hover:underline">
            {{ 'admin.reservation-table.item-filter-modal.reset-selected-items-label' | translate }}
          </span>
        </div>
      </label>
  
      <p-listbox
        [options]="filteredItemsForListbox"
        [(ngModel)]="selectedItems"
        (onChange)="onListboxChange($event)"
        [group]="true"
        [checkbox]="true"
        [showToggleAll]="maxSelectedItemsCount === null"
        [multiple]="true"
        [filter]="true"
        [filterFields]="['label']"
        scrollHeight="100%"
        [listStyle]="{'min-height': '17rem'}"
        optionDisabled="disabled"
        class="items-listbox">
  
        <ng-template let-group pTemplate="filter">
          <div class="p-input-icon-right w-full">
            <i *ngIf="itemsListboxFilter.length > 0" (click)="onItemsListboxFilter('')" class="pi pi-times cursor-pointer"></i>
            <i *ngIf="itemsListboxFilter.length === 0" class="pi pi-search"></i>
  
            <input
              [ngModel]="itemsListboxFilter"
              icon="pi pi-search"
              iconPos="right"
              (ngModelChange)="onItemsListboxFilter($event)"
              pInputText
              class="w-full"/>
  
          </div>
          <div class="ml-3 text-bluegray-300">
            <i *ngIf="sortItemsBy === SortItemsBy.ALPHABETICAL_ASC" (click)="sortItems(SortItemsBy.CHRONOLOGICAL_DESC)" [ngbTooltip]="'admin.reservation-table.item-filter-modal.sort-by.ALPHABETICAL_ASC.tooltip' | translate" class="pi pi-sort-alpha-up cursor-pointer"></i>
            <i *ngIf="sortItemsBy === SortItemsBy.CHRONOLOGICAL_ASC" (click)="sortItems(SortItemsBy.ALPHABETICAL_ASC)" [ngbTooltip]="'admin.reservation-table.item-filter-modal.sort-by.CHRONOLOGICAL_ASC.tooltip' | translate" class="pi pi-sort-numeric-down-alt cursor-pointer"></i>
            <i *ngIf="sortItemsBy === SortItemsBy.CHRONOLOGICAL_DESC" (click)="sortItems(SortItemsBy.CHRONOLOGICAL_ASC)" [ngbTooltip]="'admin.reservation-table.item-filter-modal.sort-by.CHRONOLOGICAL_DESC.tooltip' | translate" class="pi pi-sort-numeric-up-alt cursor-pointer"></i>
          </div>
        </ng-template>
  
        <ng-template let-group pTemplate="group">
          <div (click)="onEventCollapse(group.event.id)" class="flex column-gap-2 cursor-pointer">
            @if (!hideEventCheckbox) {
              <p-checkbox [ngModel]="areAllEventShopItemsSelected(group.shopItems)" (click)="onShopItemsFilterEventClick(group, $event)" [binary]="true"></p-checkbox>
            }
  
            <div class="flex justify-content-between flex-1 align-items-center">
  
              <div class="flex flex-column">
                <span class="font-bold">
                  {{ group.label }}
                </span>
    
                <div class="details-block-wrap font-normal">
                  <span class="detail-block">
                    {{ 'admin.reservation-table.item-filter-modal.event-items-count-label' | translate:{cnt:group.shopItems.length} }}
                  </span>
                </div>
              </div>
  
              <span class="pi {{ collapsedEventIds.includes(group.event.id) ? 'pi-chevron-up' : 'pi-chevron-down' }}">
              </span>
            </div>
          </div>
        </ng-template>
  
        <ng-template let-item pTemplate="item">
          
          <!-- <p-checkbox [inputId]="item.shopItem.uuid" [ngModel]="!!itemsForm.controls.shopItemIds.value.includes(item.shopItem.id)" [binary]="true"></p-checkbox> -->
          <div class="flex flex-1" [class.justify-content-between]="item.disabled" [@collapse]="collapsedEventIds.includes(item.shopItem.eventId)" [class.collapsed-item]="collapsedEventIds.includes(item.shopItem.eventId)">
            <span>
              <p class="m-0 font-bold">
                {{ item.shopItem.title }}
              </p>
    
              <div class="details-block-wrap ">
                <span *ngIf="siHelper.getSessionGroupDate(item.shopItem.sessionGroup) as sgDate" class="detail-block">
                  {{ sgDate | translate }}
                </span>
            
                <span *ngIf="siHelper.getSessionGroupTime(item.shopItem.sessionGroup) as sgTime" class="detail-block">
                  {{ sgTime }}
                </span>
            
                <span *ngIf="siHelper.getSessionGroupLocation(item.shopItem.sessionGroup, true) as sgLocation" class="detail-block">
                  <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
                    {{ sgLocation }}
                  </span>
                </span>
              </div>
            </span>
            @if (item.disabled) {
              <span class="flex align-items-center pointer-events-auto" container="body" [ngbTooltip]="item.tooltip | translate">
                <i class="pi pi-exclamation-triangle" style="color: var(--l-warn);"></i>
              </span>
            }
          </div>
        </ng-template>
  
      </p-listbox>
  
  
      <!-- <p-listbox
        [options]="itemsForListbox"
        (onClick)="onListboxClick($event)"
        [filter]="true"
        [filterFields]="['label']">
  
        <ng-template let-item pTemplate="item">
          <ng-container *ngIf="item.IS_EVENT">
            <p-checkbox [inputId]="item.event.uuid" [ngModel]="areAllEventShopItemsSelected(item.shopItems)" [binary]="true"></p-checkbox>
    
            <span class="m-0 font-bold">
              {{ item.event.title }}
            </span>
          </ng-container>
  
          <div *ngIf="item.IS_SHOP_ITEM" class="pl-4 flex align-items-center">
            <p-checkbox [inputId]="item.shopItem.uuid" [ngModel]="!!itemsForm.controls.shopItemIds.value.includes(item.shopItem.id)" [binary]="true"></p-checkbox>
            <div>
              <span>
                <p class="m-0 font-bold">
                  {{ item.shopItem.title }}
                </p>
      
                <div class="details-block-wrap ">
                  <span *ngIf="siHelper.getSessionGroupDate(item.shopItem.sessionGroup) as sgDate" class="detail-block">
                    {{ sgDate | translate }}
                  </span>
              
                  <span *ngIf="siHelper.getSessionGroupTime(item.shopItem.sessionGroup) as sgTime" class="detail-block">
                    {{ sgTime }}
                  </span>
              
                  <span *ngIf="siHelper.getSessionGroupLocation(item.shopItem.sessionGroup, true) as sgLocation" class="detail-block">
                    <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
                      {{ sgLocation }}
                    </span>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </ng-template>
  
      </p-listbox> -->
  
      <!-- <ng-container *ngFor="let item of items$ | async">
        <div class="mb-3">
          <div class=" flex column-gap-2 align-items-center">
            <p-checkbox [inputId]="item.event.uuid" [ngModel]="areAllEventShopItemsSelected(item.shopItems)" [binary]="true" (onChange)="onEventSelectChange(item.shopItems, $event)"></p-checkbox>
  
            <label for="{{item.event.uuid}}" class="m-0 font-bold">
              {{ item.event.title }}
            </label>
          </div>
          
          <div *ngFor="let si of item.shopItems" class="pl-3 my-2 flex column-gap-2 align-items-center">
  
            <p-checkbox [inputId]="si.uuid" [ngModel]="!!itemsForm.controls.shopItemIds.value.includes(si.id)" [binary]="true" (onChange)="onShopItemSelectChange(si, $event)"></p-checkbox>
  
            <div>
              <label for="{{si.uuid}}">
              <p class="m-0 font-bold">
                {{ si.title }}
              </p>
    
              <div class="details-block-wrap ">
                <span *ngIf="siHelper.getSessionGroupDate(si.sessionGroup) as sgDate" class="detail-block">
                  {{ sgDate | translate }}
                </span>
            
                <span *ngIf="siHelper.getSessionGroupTime(si.sessionGroup) as sgTime" class="detail-block">
                  {{ sgTime }}
                </span>
            
                <span *ngIf="siHelper.getSessionGroupLocation(si.sessionGroup, true) as sgLocation" class="detail-block">
                  <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
                    {{ sgLocation }}
                  </span>
                </span>
              </div>
              </label>
  
            </div>
            
          </div>
        </div>
      </ng-container> -->
    </ng-template>
  
  </div>
</div>
