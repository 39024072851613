import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILocationArchiveData } from 'src/app/shared/models/location/location-archive-data.model';
import { ILocationPostData } from 'src/app/shared/models/location/location-post-data.model';
import { ILocationUpdateData } from 'src/app/shared/models/location/location-update-data.model';
import { ILocation } from 'src/app/shared/models/location/location.model';
import { CallableNames, DbService } from '../../db.service';

interface ICallablesLocationsGetForEventFilterData {
  orgId: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getLocationsForEventFilter(data: ICallablesLocationsGetForEventFilterData): Observable<ILocation[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesLocationsGetForEventFilter, data });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

  public postLocation(data: ILocationPostData): Observable<ILocation> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesLocationsPost, data });
    return this.dbService.handleObs(obs);
  }

  public updateLocation(data: ILocationUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesLocationsUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public deleteLocation(data: ILocationArchiveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesLocationsArchive, data });
    return this.dbService.handleObs(obs);
  }

  public getLocationsByOrgId(orgId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesLocationsGet, data: { orgId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
