import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { IAddress } from '../modals/address-form-group-modal/address-form-group-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {

  constructor(
    public utilsService: UtilsService,
    private translateService: TranslateService
  ) {}

  transform(address?: IAddress | string | null, separator: string = ', ', multiLine = false): string {
    if (!address) return '';
    let items: string[] = [];
    if (typeof address === 'string') {
      items = address.split(', ');
    } else {
      const countryTranslation = address.countryCode ? this.translateService.instant(`country.${address.countryCode.toLowerCase()}`) : null;
      if (multiLine) {
        return `${address.street || ''} ${address.buildingNumber || ''}\n${address.zip || ''} ${address.city || ''}\n${countryTranslation || address.country || ''}`.trim();
      } else {
        items = [`${address.street || ''} ${address.buildingNumber || ''}`, `${address.zip || ''}`, `${address.city || ''}`, `${countryTranslation || address.country || ''}`];
      }
    }
    return items.filter((item) => item.trim() !== '').join(separator);
  }
}
