<div (click)="locationsSelect.show()" class="filter-wrap relative">
  <p class="filter-title">
    {{ 'admin.event-shop-items-filter.state.label' | translate }}
  </p>

  <app-filter-item-chips
    [chips]="chips"
    emptyMessage="admin.event-shop-items-filter.state.all"
    (remove)="onRemoveChip($event)">
  </app-filter-item-chips> 

  <!-- todo use virtualscroll - was bugged when writing... from 7 options showed only 3-->
  <p-multiSelect
    #locationsSelect
    [formControl]="FC"
    [options]="stateOptions"
    [optionValue]="'value'"
    [optionLabel]="'label'"
    [overlayOptions]="{appendTo: 'body'}">
  </p-multiSelect>
</div>