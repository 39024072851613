@if (detailsInfo; as di) {
  <div class="details-block-wrap">
    @if (partsMap[ShopItemDetailsPart.DATE] && siHelper.getShopItemDate(di.sessions?.first?.startAt ?? null, di.sessions?.last?.endAt ?? null, di.registrationType); as date) {
      <span class="detail-block">
        {{ date | translate }}
      </span>
    }
  
    @if (partsMap[ShopItemDetailsPart.TIME] && siHelper.getShopItemTime(di.sessions?.first?.startAt ?? null, di.sessions?.last?.endAt ?? null, di.registrationType); as time) {
      <span class="detail-block">
        {{ time }}
      </span>
    }

    @if (partsMap[ShopItemDetailsPart.DATE_TIME_RANGE] && siHelper.getShopItemDateTimeRange(di.sessions?.first?.startAt ?? null, di.sessions?.last?.endAt ?? null, di.registrationType); as dateTimeRange) {
      <span class="detail-block">
        {{ dateTimeRange }}
      </span>
    }
  
    @if (partsMap[ShopItemDetailsPart.LOCATION] && siHelper.getShopItemLocation(di.sessions?.first ?? null, di.registrationType, locationUseInternalTitle); as location) {
      <span class="detail-block">
        <span [ngbTooltip]="location" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
          {{ location }}
        </span>
      </span>
    }

    @if (partsMap[ShopItemDetailsPart.CUSTOM_NUMBER] && di.customItemNumber) {
      <span class="detail-block">
        {{ di.customItemNumber }}
      </span>
    }

    <ng-content></ng-content>
  </div>
}