import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleFormSectionComponent } from '../collapsible-form-section/collapsible-form-section.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { IEventSingleSgCollapsibleFormSection } from '../../../admin-event-single-sg/admin-event-single-sg.component';
import { IEventMultiSgCollapsibleFormSection } from '../../../admin-event-multiple-sg/admin-event-multiple-sg.component';
import { Subscription } from 'rxjs';
import { OrgTermsConditionsInputsComponent } from './components/org-terms-conditions-inputs/org-terms-conditions-inputs.component';

export interface IOrgTermsConditionsFG {
  url: FormControl<string | null>;
  html: FormControl<string | null>;
};

export const getOrgTermsConditionsFG = () => {
  return new FormGroup<IOrgTermsConditionsFG>({
    url: new FormControl(null),
    html: new FormControl(null),
  });
}

@Component({
  selector: 'app-org-terms-conditions-form-section',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    EditorModule, InputTextModule,
    CollapsibleFormSectionComponent, OrgTermsConditionsInputsComponent,
  ],
  templateUrl: './org-terms-conditions-form-section.component.html',
  styleUrls: ['./org-terms-conditions-form-section.component.scss']
})
export class OrgTermsConditionsFormSectionComponent {

  @Input() formSection: IEventSingleSgCollapsibleFormSection | IEventMultiSgCollapsibleFormSection | undefined;

  subs: Subscription[] = [];

  ngOnInit(): void {
    this.watchFormSection();
  }

  private watchFormSection() {
    this.checkFormSection();
    this.subs.push(
      this.formSection?.control?.valueChanges.subscribe(v => {
        this.checkFormSection();
      }) ?? Subscription.EMPTY
    );
  }
  private checkFormSection() {
    if (!this.formSection?.control) return;
    const c = this.formSection.control as FormGroup<IOrgTermsConditionsFG>;
    const v = c.getRawValue();

    if (this.formSection?.unset !== undefined) {
      this.formSection.unset = !v.url?.length && !v.html;
    }
    if (this.formSection?.warning !== undefined) {
      this.formSection.warning = !v.url?.length && !v.html;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
