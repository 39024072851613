


<app-modal-header
  [highlighted]="true"
  (close)="close()">
  <span slot="breadcrumb">
    {{ 'admin.event-shop-item-filter-toggler-modal.title' | translate }}
  </span>


  <div slot="undercontent" class="relative pb-5" >
    <div style="max-height: 35dvh; overflow: auto;">
      <div cdkDropList (cdkDropListDropped)="onFilterDropListDrop($event)">
        @for (selectedType of selectedFilterTypes; track selectedType; let first = $first; let last = $last) {
          <div cdkDrag cdkDragPreviewContainer="parent" cdkDragRootElement="#filter{{selectedType}}">
            <app-event-si-filter-type-item
              [type]="selectedType"
              [selected]="true"
              (checkboxChange)="onSelectionChange($event)"
              class="block surface-border border-1"
              [class.border-top-none]="!first"
              [class.border-round-top]="first"
              [class.border-round-bottom]="last"
              style="background-color: var(--surface-0);">

              <div cdkDragHandle class="drag" slot="drag">
                <div cdkDragHandle class="cursor-move">
                  <i class="pi pi-bars text-bluegray-200"></i>
                </div>
              </div>

            </app-event-si-filter-type-item>
    
          </div>
        }
      </div>
    </div>

    <div class="w-full absolute z-1" style="bottom: -2rem;">
      <div class="p-input-icon-left w-full">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText
          [formControl]="searchFC"
          [placeholder]="'admin.reservations.filters-modal.search-input.placeholder' | translate"
          class="focus-input w-full" />
      </div>
    </div>
  </div>
</app-modal-header>

<div class="modal-body">


  <!-- spacer bcs shifted search input -->
  <div class="pt-5"></div>

  @for (unselectedType of unselectedFilterTypes | searchFilter:searchFC.value ; track unselectedType) {
    <app-event-si-filter-type-item
      [type]="unselectedType"
      [selected]="false"
      (checkboxChange)="onSelectionChange($event)"
      class="block surface-border border-1"
      [class.border-top-none]="!$first"
      [class.border-round-top]="$first"
      [class.border-round-bottom]="$last"
      style="background-color: var(--surface-0);">
    </app-event-si-filter-type-item>
  }


</div>

<div class="modal-footer">

  <button
    (click)="close()"
    pButton
    [label]="'admin.event-shop-item-filter-toggler-modal.cancel-btn.label' | translate"
    class="p-button-sm p-button-text p-button-plain flex-1">
  </button>

  <button
    [disabled]="saving"
    [loading]="saving"
    (click)="onSubmit()"
    pButton
    [label]="'admin.event-shop-item-filter-toggler-modal.save-btn.label' | translate"
    class="p-button-sm flex-1">
  </button>
</div>