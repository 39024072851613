import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { IAccreditation } from 'src/app/shared/models/accreditation/accreditation.model';
import { IAccreditationTemplate } from 'src/app/shared/models/accreditation/accreditation-template.model';

export interface IAccreditationTemplatesCreateData {
  label: string;
  number: string | null;
  accreditationDate: Date | null;
  validTo: Date | null;
  authorityName: string | null;
  authorityNameShortcut: string | null;
  
  organizationId: number;
}

export interface IAccreditationTemplatesUpdateData {
  accreditationTemplateId: number;

  label: string;
  number: string | null;
  accreditationDate: Date | null;
  validTo: Date | null;
  authorityName: string | null;
  authorityNameShortcut: string | null;
}

export interface IAccreditationTemplatesDeleteData {
  accreditationTemplateId: number;
}

export interface IAccreditationsSetData {
  accreditationTemplateId: number | null;
  shopItemId: number;
}

export interface IAccreditationsDeleteData {
  accreditationId: number;
}

interface IAccreditationTemplatesGetForSiAccreditationModalData {
  shopItemId: number;
}
interface IAccreditationTemplatesGetForSiAccreditationModalResult {
  shopItem: IShopItem & { accreditations: IAccreditation[], sessionGroup: any };
  orgAccreditationTemplates: IAccreditationTemplate[];
}

interface IAccreditationTemplatesInData {
  accreditationTemplateId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AccreditationsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getAccreditationTemplateIn(data: IAccreditationTemplatesInData): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationTemplatesIn, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForSiAccreditationModal(data: IAccreditationTemplatesGetForSiAccreditationModalData): Observable<IAccreditationTemplatesGetForSiAccreditationModalResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationTemplatesGetForSiAccreditationModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public createAccreditationTemplate(data: IAccreditationTemplatesCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationTemplatesCreate, data });
    return this.dbService.handleObs(obs);
  }

  public updateAccreditationTemplate(data: IAccreditationTemplatesUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationTemplatesUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public deleteAccreditationTemplate(data: IAccreditationTemplatesDeleteData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationTemplatesDelete, data });
    return this.dbService.handleObs(obs);
  }

  public setAccreditation(data: IAccreditationsSetData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationsSet, data });
    return this.dbService.handleObs(obs);
  }

  public deleteAccreditation(data: IAccreditationsDeleteData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesAccreditationsDelete, data });
    return this.dbService.handleObs(obs);
  }
}
