import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { ICollectedUserData } from 'src/app/shared/models/collecting-data/collected-user-data.model';
import { CollectingFrom } from 'src/app/shared/enums/collecting-data/collecting-from.enum';
import { Observable } from 'rxjs';
import { GetCustomDataResultType } from '../users/customer/customers.service';

interface ICallablesCollectedUserDataUpdateData extends Omit<ICollectedUserData, 'shortUid'> {
  organizationId: number;
  oldCollectedUserDataId: number;
}

export interface IUnusedCollectedUserData {
  data: ICollectedUserData;
  used: { [key in CollectingFrom]?: boolean; };
}

interface ICallablesCollectedUserDataGetForNewCustomerModalData {
  orgId: number;
}

@Injectable({
  providedIn: 'root'
})
export class CollectedUserDataService {

  constructor(
    private dbService: DbService
  ) { }

  public getForNewCustomerModal(data: ICallablesCollectedUserDataGetForNewCustomerModalData): Observable<GetCustomDataResultType[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataGetForNewCustomerModal, data });
    return this.dbService.handleObs(obs, { allSilent: true });
  }
  
  public removeCollectedUserData(collectedUserDataId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataRemove, data: { collectedUserDataId } });
    return this.dbService.handleObs(obs, {});
  }

  public getCollectedUserDataShortUid(collectedUserDataId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataGetShortUid, data: { collectedUserDataId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public get(collectedUserDataId: number): Observable<ICollectedUserData> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataGet, data: { collectedUserDataId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateCollectedUserDataField(data: ICallablesCollectedUserDataUpdateData): Observable<ICollectedUserData> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataUpdate, data: data });
    return this.dbService.handleObs(obs, {});
  }

  // TODO: move elsewhere?
  public getUnusedCollectedUserData(shopItemId: number): Observable<IUnusedCollectedUserData[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataGetUnused, data: { shopItemId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
