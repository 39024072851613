

<ng-container *ngIf="parentForm" [formGroup]="parentForm">

  <div class="field">
    <input pInputText formControlName="url" placeholder="{{ 'admin.org-privacy-policy-inputs.url-input.placeholder' | translate }}" type="text" class="w-full">
  </div>

  <p-editor #ed formControlName="html" placeholder="{{ 'admin.org-privacy-policy-inputs.text.placeholder' | translate }}" [modules]="{clipboard:{matchVisual:false}}" [style]="{'height':'8rem'}" class="">
    <ng-template pTemplate="header">
      <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-strike" aria-label="Strike"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>
      <span class="ql-formats">
        <button aria-label="Link" class="ql-link"></button>
      </span>
      <span class="ql-formats">
        <button (click)="ed.getQuill().removeFormat(ed.getQuill().getSelection().index,ed.getQuill().getSelection().length)" class="cust-clear" title="{{ 'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
      </span>
    </ng-template>
  </p-editor>

</ng-container>
