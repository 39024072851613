import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Modal } from '../../modal';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Operation } from 'src/app/shared/enums/utils/operations.enum';
import { IExtrasShopItem } from 'src/app/shared/models/shop-item/form-field-settings.model';
import { getExtrasShopItemFG, IExtrasShopItemFG } from '../form-field-settings-modal.component';
import { BlankInputComponent } from 'src/app/shared/components/blank-input/blank-input.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IShortCodeMenuItem } from 'src/app/shared/components/short-codes-menu/short-codes-menu.component';
import { EditorWrapComponent } from 'src/app/shared/components/editor-wrap-component/editor-wrap-component.component';
import { ShortCodesOverlayComponent } from 'src/app/shared/components/short-codes-menu/short-codes-overlay/short-codes-overlay.component';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ExtrasShopItemPickerModalComponent } from './extras-shop-item-picker-modal/extras-shop-item-picker-modal.component';
import { take } from 'rxjs';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { createShopItemDescriptionShortCodeMenuItems } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-description/shop-item-module-description.component';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
// import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { UserHelperService } from 'src/app/shared/services/helpers/user-helper.service';
import { IShopItemDetailInfo } from 'src/app/shared/models/shop-item/shop-item.model';
import { ShopItemDetailsInfoComponent, ShopItemDetailsPart } from 'src/app/shared/components/shop-item-components/shop-item-details-info/shop-item-details-info.component';
import { InputTextareaSubtleComponent } from 'src/app/shared/components/subtle-inputs/input-textarea-subtle/input-textarea-subtle.component';

export interface IExtrasShopItemInfo extends IShopItemDetailInfo {
  id: number;
  title: string;
  maxReservationCount: number | null;
}

export interface IExtrasShopItemInfoMap {
  [shopItemId: number]: IExtrasShopItemInfo | undefined;
}

@Component({
  selector: 'app-extras-form-field-modal',
  standalone: true,
  imports: [
    ModalHeaderComponent, ButtonModule, TranslateModule, InputTextareaSubtleComponent, EditorWrapComponent, ShortCodesOverlayComponent,
    NgbTooltipModule, InputNumberModule, ReactiveFormsModule, ShopItemDetailsInfoComponent
  ],
  templateUrl: './extras-form-field-modal.component.html',
  styleUrl: './extras-form-field-modal.component.scss'
})
export class ExtrasFormFieldModalComponent extends Modal implements OnInit, OnDestroy {
  ShopItemDetailsPart = ShopItemDetailsPart;

  @Input({ required: true }) operation!: Operation; // TODO: keep for now and ask if needed
  @Input({ required: true }) sessionGroupRegistrationType!: SessionGroupRegistrationType | null;
  @Input({ required: true }) alreadyAddedShopItemIds!: number[];
  @Input() extrasShopItem?: IExtrasShopItem;

  @Output() result = new EventEmitter<IExtrasShopItem>();

  _form: FormGroup<IExtrasShopItemFG> | null = null;
  get form() { return this._form };
  set form(value) { this._form = value; this.setShortCodeMenuItems(); };

  descriptionShortCodeMenuItems: IShortCodeMenuItem[]  = [];
  leftDescriptionShortCodeMenuItems: IShortCodeMenuItem[]  = [];

  @ViewChild('led') leftDescriptionEditor: EditorWrapComponent | undefined;
  get leftDescriptionEditorOverlay () { return this.leftDescriptionEditor?.editorOverlay };

  @ViewChild('ed') descriptionEditor: EditorWrapComponent | undefined;
  get descriptionEditorOverlay () { return this.descriptionEditor?.editorOverlay };

  constructor(
    public siHelper: ShopItemHelperService,
    private modalService: ModalService,
    private utilsService: UtilsService,
    private localizedDatePipe: LocalizedDatePipe,
    // private pricePipe: PricePipe,
    private translate: TranslateService,
    private userHelper: UserHelperService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.form = getExtrasShopItemFG(this.extrasShopItem);
  }

  ngAfterViewInit(): void {
    this.setShortCodeMenuItems();
  }

  private setShortCodeMenuItems() {
    if (this.descriptionEditorOverlay) {
      this.descriptionShortCodeMenuItems = createShopItemDescriptionShortCodeMenuItems({
        entity: this.sessionGroupRegistrationType ? 'SG' : 'SI',
        editorOverlay: this.descriptionEditorOverlay,
        ldp: this.localizedDatePipe,
        siHelper: this.siHelper,
        pricePipe: {} as any, // this.pricePipe,
        translate: this.translate,
        userHelper: this.userHelper,
        utils: this.utilsService
      });
    }

    if (this.leftDescriptionEditorOverlay) {
      this.leftDescriptionShortCodeMenuItems = createShopItemDescriptionShortCodeMenuItems({
        entity: this.sessionGroupRegistrationType ? 'SG' : 'SI',
        editorOverlay: this.leftDescriptionEditorOverlay,
        ldp: this.localizedDatePipe,
        siHelper: this.siHelper,
        pricePipe: {} as any, // this.pricePipe,
        translate: this.translate,
        userHelper: this.userHelper,
        utils: this.utilsService
      });
    }
  }

  onChangeShopItem(): void {
    const excludeShopItemIds = this.alreadyAddedShopItemIds.filter((x) => x !== this.extrasShopItem?.shopItemId);
    this.modalService.openExtrasShopItemPickerModal(ExtrasShopItemPickerModalComponent, excludeShopItemIds, this.sessionGroupRegistrationType, true).pipe(take(1)).subscribe((pickedShopItem) => {
      if (!this.extrasShopItem) {
        this.extrasShopItem = {
          description: '',
          leftSideDescription: '',
          label: '',
          minCount: 0,
          maxCount: 1,
          shopItemId: pickedShopItem[0].id,
          info: pickedShopItem[0]
        };
      } else {
        this.extrasShopItem.shopItemId = pickedShopItem[0].id;
        this.extrasShopItem.info = pickedShopItem[0];
        this.extrasShopItem.label = pickedShopItem[0].title;
      }
      this.form?.controls.label.setValue(pickedShopItem[0].title);
      this.form?.controls.shopItemId.setValue(pickedShopItem[0].id);
      this.form?.controls.info.setValue(pickedShopItem[0]);
    });
  }

  onSubmit() {
    if (!this.form) return;
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      console.error('invalid');
      return;
    }

    const value = this.form.getRawValue();
    if (!value.shopItemId) {
      console.error('invalid shopItemId');
      return;
    }

    this.result.emit({
      description: value.description,
      leftSideDescription: value.leftSideDescription,
      label: value.label,
      minCount: value.minCount,
      maxCount: value.maxCount,
      shopItemId: value.shopItemId,
      info: this.extrasShopItem?.info || null
    });
    this.close();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
  }
}
