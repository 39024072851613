import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { take } from 'rxjs';
import { ReservationsService } from 'src/app/shared/services/entities/reservations/reservations.service';

@Component({
  selector: 'td[app-admin-note-cell]',
  standalone: true,
  imports: [
    InputTextareaModule, FormsModule
  ],
  templateUrl: './admin-note-cell.component.html',
  styleUrl: './admin-note-cell.component.scss'
})
export class AdminNoteCellComponent implements OnInit {
  @Input({ required: true }) adminNote!: string;
  @Input({ required: true }) reservationId!: number;

  adminNoteInputValue: string = '';

  constructor(
    private reservationService: ReservationsService
  ) {}

  ngOnInit(): void {
    this.adminNoteInputValue = this.adminNote;
  }

  onBlur() {
    if (this.adminNote !== this.adminNoteInputValue) {
      this.reservationService.updateSimpleAdminNote({
        note: this.adminNoteInputValue,
        reservationId: this.reservationId
      }).pipe(take(1)).subscribe(() => {});
    }
  }
}
