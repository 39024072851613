import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, take, tap } from 'rxjs';
import { ITransfer } from '../../models/transfers/transfer.model';
import { ICreateCustomerCreditCallableData, IRecordPaymentData, ITransfersPair, TransfersService } from '../entities/transfers/transfers.service';
import { SelectedOrgService } from '../selected-org.service';
import { UtilsService } from '../utils.service';
import { ITransferFE } from 'src/app/pages/admin/org-admin/payments-tabs/payments/payments.component';

export interface IOrgPaymentsState {
  payments$: BehaviorSubject<ITransferFE[]>;
  fetchingPayments$: BehaviorSubject<boolean>;
};

@Injectable({
  providedIn: 'root'
})
export class OrgPaymentsStoreService {

  private initState: IOrgPaymentsState = {
    payments$: new BehaviorSubject<ITransferFE[]>([]),
    fetchingPayments$: new BehaviorSubject<boolean>(false),
  };
  public state = this.utilsService.initializeState(this.initState) as IOrgPaymentsState;

  constructor(
    private utilsService: UtilsService,
    private selectedOrgService: SelectedOrgService,
    private transfersService: TransfersService,
  ) { }

  public init(includeData?: { user: boolean }) {

    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;

    this.state.fetchingPayments$.next(true);
    this.transfersService.getManyPayments({
      organizationId: orgId,
      include: { user: includeData?.user || false }
    }).pipe(
      take(1),
      finalize(() => this.state.fetchingPayments$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.payments$.next(res);
      }
    });

  }


  public recordPayments(data: IRecordPaymentData[], silentError?: boolean): Observable<number[]> {
    return this.transfersService.recordPayments(data, silentError).pipe(
      take(1),
      tap((res) => {
        this.init({ user: true });
      }),
    );
  }

  public pairTransfer(data: ITransfersPair) {
    return this.transfersService.pair(data).pipe(
      take(1),
      tap((res) => {
        this.init({ user: true });
      })
    );
  }

  public createCustomerCredit(data: ICreateCustomerCreditCallableData): Observable<number> {
    return this.transfersService.createCustomerCredit(data);
  }


}
