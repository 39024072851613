import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';
import { ShopItemState } from 'src/app/shared/enums/shop-item/shop-item-states.enum';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_STATE = {
  type: EventShopItemFilterItemType.STATE;
  value: SelectItem[] | null;
}

@Component({
  selector: 'app-event-si-filter-item-state',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    MultiSelectModule,
    FilterItemChipsComponent,
  ],
  templateUrl: './event-si-filter-item-state.component.html',
  styleUrl: './event-si-filter-item-state.component.scss'
})
export class EventSiFilterItemStateComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_STATE | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_STATE>();

  chips: IFilterItemChip[] = [];
  stateOptions: SelectItem[] = Object.values(ShopItemState).map(state => ({ label: state, value: state }));

  FC = new FormControl<ShopItemState[]>([], { nonNullable: true });

  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService
  ) {
    this.subs.push(
      this.translate.onLangChange.subscribe(() => { this.createOptions() }),

      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedOptions });
        this.updateChips();
      })
    );
  }

  ngOnInit(): void {
    this.createOptions();
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? [], { emitEvent: false });
    this.updateChips();
  }

  private createOptions() {
    this.stateOptions = Object.values(ShopItemState).map(state => ({ label: this.translate.instant(`admin.ShopItemState.${state}`), value: state }));
  }

  onRemoveChip(chip: IFilterItemChip) {
    this.FC.setValue(this.FC.value?.filter(x => x !== chip.value) ?? []);
  }

  private updateChips() {
    this.chips = this.selectedOptions.map(x => {
      const chip: IFilterItemChip = {
        label: x.label,
        value: x.value
      };
      return chip;
    });
  }

  get selectedOptions() {
    return this.stateOptions.filter(x => this.FC.value?.includes(x.value));
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
  
}
