
@if (data && data.moduleDefinition && data.moduleData) {

  <section cdkDrag cdkDragRootElement="#module_{{data.moduleData.uuid}}" class="module-wrap" [class.is-last]="data.isLast" [class.loading-box]="(state.updatingSettingModule$ | async)?.includes(data.moduleData.uuid)">
  
    <div (click)="onModule()" class="module-header">
  
      <div cdkDragHandle class="drag">
        <div cdkDragHandle class="cursor-move">
          <i class="pi pi-bars text-bluegray-200"></i>
        </div>
      </div>
      <div
        [class.module-status--error]="data.moduleDefinition.control?.invalid && data.moduleDefinition.control?.dirty"
        class="module-status">
  
      </div>

      <div class="btns">
        <div (click)="onDeteleModule($event)" *ngIf="data.moduleDefinition.mandatory === false" class="btn">
          <i class="pi pi-minus"></i>
        </div>
  
        @if (data.moduleDefinition.cloneable) {
          <div
            container="body"
            [ngbTooltip]="data?.moduleDefinition?.control?.dirty ? ('admin-shop-item-modules.clone-btn-disabled.tooltip' | translate) : ('admin-shop-item-modules.clone-btn.tooltip' | translate)"
            (click)="data?.moduleDefinition?.control?.dirty ? null : onCloneModule($event)"
            class="btn"
            [class.cursor-auto]="data?.moduleDefinition?.control?.dirty"
          >
            <i class="pi pi-sitemap"></i>
          </div>
        }
      </div>
      
      <div>
  
        <h5 class="module-title m-0">
          @if (customModuleTitleFC) {
            <app-blank-input (click)="$event.stopPropagation()" [FC]="customModuleTitleFC" [placeholder]="data.moduleDefinition.title | translate"></app-blank-input>
          } @else {
            {{ data.moduleData.customTitle ?? (data.moduleDefinition.title | translate) }}
          }
        </h5>
  
        <div class="">
          <ng-content select="[slot=subTitleContent]"></ng-content>
        </div>
      </div>
  
      <div class="center-content">
        <ng-content select="[slot=centerContent]"></ng-content>
  
      </div>
  
      <div (click)="onActionsSection($event)" class="actions">
  
        <ng-content select="[slot=actions]"></ng-content>
  
  
      </div>
  
    </div>
  
    @if (data.moduleDefinition.collapsible === true) {
      <div
    
        [@collapse]="data.moduleDefinition.collapsed"
        [class.overflow-hidden]="data.moduleDefinition.collapsed"><!-- ten overflow je proste blbej no .. bez nej je to blby a s nim taky .. kvuli tomu pak se nezobrazuje dropdown .... chtelo by to tu classu prirazovat pouze pokud je animace hotova -->
    
        <div class="p-3">
          <ng-content>
    
          </ng-content>
        </div>
    
      </div>
    }
  
  </section>
}

