import { Pipe, PipeTransform } from '@angular/core';
import { IOrgUser, IOrgUserObj } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';
import { UtilsService } from '../services/utils.service';
import { ErrorSeverity } from '../services/error.service';

@Pipe({
  name: 'userInitials',
  standalone: true
})
export class UserInitialsPipe implements PipeTransform {

  constructor(
    private userHelper: UserHelperService,
    private utilsService: UtilsService
  ) {}

  transform(orgUsers: IOrgUser[], orgId: number | null): string;
  transform(orgUser: Partial<IOrgUser | IOrgUserObj>): string;
  transform(userOrUsers: Partial<IOrgUser | IOrgUserObj> | IOrgUser[], orgId?: number | null): string {
    let orgUser: Partial<IOrgUser | IOrgUserObj> | null | undefined;

    if (Array.isArray(userOrUsers)) {
      if (orgId == null) {
        this.utilsService.logError('orgId missing in userInitials pipe, qweq65q4w', ErrorSeverity.ERROR);
        return '';
      }
      orgUser = this.userHelper.getOrgUser(userOrUsers, orgId);
    } else {
      orgUser = userOrUsers;
    }

    if (!orgUser) {
      this.utilsService.logError('User not found in userInitials pipe, err1i68t', ErrorSeverity.ERROR);
      return '';
    }

    const firstInitial = orgUser.name?.charAt(0) || '';
    const surnameInitial = orgUser.surname?.split(' ').at(-1)?.charAt(0) || '';

    return (firstInitial + surnameInitial) || orgUser.email?.charAt(0) || '';
  }

}
