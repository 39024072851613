import { Currency } from "../../enums/price/currencies.enum";
import { IAddress } from "../../modals/address-form-group-modal/address-form-group-modal.component";
import { IPaymentInstructions } from "../../services/entities/payments/payments.service";
import { ApiProvider } from "../api/api.model";
import { IOrder, IVatValues } from "../order/order-model";
import { ICompanyBillingInfo } from "../organization/company-billing-info.model";
import { IPrice } from "../price/price.model";

// TODO: rename to InvoiceType - merge with existing InvoiceType?
export enum InvoiceTypeDB {
  FINAL = 'FINAL',
  PROFORMA = 'PROFORMA',
  CREDIT_NOTE = 'CREDIT_NOTE'
}

export interface IInvoiceExternalData {
  fakturoidHtmlUrl?: string;
  fakturoidPublicHtmlUrl?: string;
  fakturoidPdfUrl?: string;
  externalUrl?: string;
}

export enum ExternalInvoiceSyncErrorType {
  UNKNOWN = 'UNKNOWN'
}

export interface IInvoiceExternalSyncStatus {
  [updatedAt: string]: {
    apiProvider: ApiProvider;
    error: any;
    axiosErrorData: any;
    errorType: ExternalInvoiceSyncErrorType;
  };
}

export enum InvoiceItemType {
  ORDER_ITEM = 'ORDER_ITEM',
  DISCOUNT = 'DISCOUNT',
  CREDIT = 'CREDIT',
  PAID_ADVANCE = 'PAID_ADVANCE'
}

export interface IInvoiceItem {
  id: number;
  title: string;
  titleTranslationData?: object;
  shopItemId?: number;
  count: number;
  vat: number;
  type: InvoiceItemType;
  currency: Currency;
  unitPriceWithoutVat: number;
  unitPriceWithVat: number;
  unitVatPrice: number;
  totalPriceWithoutVat: number;
  totalPriceWithVat: number;
  totalVatPrice: number;
  discountPercentValue?: number;
  cancelled?: boolean;
  cancelledAt?: string;
}

export interface IInvoiceCreditNoteItem {
  title: string;
  old: IPrice | null;
  new: IPrice | null;
  vatRate: number | null;
}

export interface IInvoiceSummaryValues {
  totalWithoutVat: IPrice;
  totalVat: IPrice;
  totalVatDomesticCurrency?: IPrice;
  paidAdvance: IPrice;
  total: IPrice;
  rounding: IPrice;
  vatValues: IVatValues;
}

export interface IInvoiceData {
  /**
   * Required if `invoice.type === CREDIT_NOTE`
   *
   * ID of the invoice being corrected
   */
  creditNoteInvoiceId?: number;
  /**
   * Required if `invoice.type === CREDIT_NOTE`
   *
   * Number of the invoice being corrected
   */
  creditNoteInvoiceNumber?: string;
  /**
   * Required if `invoice.type === CREDIT_NOTE`
   *
   * Credit note items - old and new values of the invoice
   */
  creditNoteItems?: IInvoiceCreditNoteItem[];
  /**
   * Required if `invoice.type === PROFORMA || invoice.type === FINAL`
   * 
   * Invoice summary values
   */
  summaryValues?: IInvoiceSummaryValues;
  /**
   * If a proForma invoice is already issued, final invoice is an accounting invoice of that proForma
   */
  accountingProFormaNumber?: string;
  /**
   * If true, set the invoice state to cancelled
   */
  overrideCancelled?: boolean;
  overrideCancelledAt?: Date | string;
  /**
   * If invoice is in a different currency than the domestic currency, show exchange rate
   */
  exchangeRate?: {
    invoicePrice: IPrice;
    domesticPrice: IPrice;
    exchangeRate: number;
  };
}



export interface IInvoice {
  id: number;
  uuid: string;

  // Payee
  legalName: string;
  address: string;
  addressStructured: IAddress | null;
  country: string;
  companyNumber: string; // IČO
  vatNumber?: string; // DIČ
  vatPayer: boolean;
  invoiceFooterText?: string;

  externalData: IInvoiceExternalData;

  invoiceNumber: string;
  type: InvoiceTypeDB;
  taxableTransactionDate: string | Date | null;
  text: string | null;
  items: IInvoiceItem[];
  data: IInvoiceData;
  reverseCharge: boolean;
  maturity: string;

  // Payer
  payerName: string;
  payerCompanyBillingInfo: ICompanyBillingInfo | null;
  paymentInstructions: IPaymentInstructions;

  externalSyncStatus: IInvoiceExternalSyncStatus | null;

  orderId: number;
  order?: IOrder;

  createdAt: string;
  cancelledAt: string | null;
  updatedAt: string;
}