<app-modal-header
  [highlighted]="true"
  (close)="close()">
  <span slot="breadcrumb">
    {{ 'admin.extras-shop-item-picker-modal.breadcrumb' | translate }}
  </span>
</app-modal-header>

<div class="modal-body">
  <app-shop-items-picker
    [statesForm]="statesForm"
    [itemsForm]="itemsForm"
    [(selectedItems)]="selectedItems"
    [initValues]="initValues"
    [additionalFilters]="additionalFilters"
    [eventUuid]="null"
    [showLabels]="false"
    [includeOnDemand]="false"
    [hideEventCheckbox]="true"
    [onlyRepresentatives]="false"
    [maxSelectedItemsCount]="singleSelect ? 1 : null"
  />
</div>

<div class="modal-footer justify-content-between">
  <button
    (click)="close()"
    pButton
    label="{{ 'admin.extras-shop-item-picker-modal.close-btn' | translate }}"
    class="p-button-sm p-button-text p-button-plain">
  </button>

  <button
    pButton
    (click)="onSubmit()"
    label="{{ 'admin.extras-shop-item-picker-modal.submit-btn' | translate }}"
    class="p-button-sm">
  </button>
</div>
