import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IShopItemSettingModule } from 'src/app/shared/models/shop-item/shop-item-setting-module.model';
import { IShopItemAdminState, IShopItemAdminStoreType, ShopItemAdminStoreService } from 'src/app/shared/services/store/shop-item-admin-store.service';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { IOrganizationState, OrganizationStoreService } from 'src/app/shared/services/store/organization-store.service';
import { IOrganization } from 'src/app/shared/models/organization/organization.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IShopItemSettingModuleDefinition } from '../shop-item-modules-definitions';
import { ShopItemModulesComponent } from '../_shop-item-modules/shop-item-modules.component';
import { StoreService } from 'src/app/shared/services/store/store.service';

export interface ISiModuleInputData {
  parent?: ShopItemModulesComponent;
  moduleDefinition?: IShopItemSettingModuleDefinition;
  moduleData?: IShopItemSettingModule;
  dragTemplate?: TemplateRef<any>;
  isLast?: boolean;
  onDeteleModule?: () => void;
  onCloneModule?: (shopItemIds: number[], upsert: boolean) => void;
}

export abstract class CommonModuleUtils {
  abstract patchForm(): void;
  abstract get control(): AbstractControl<any, any> | null | undefined;
}

@Component({
  selector: 'app-shop-item-module-base',
  standalone: true,
  imports: [
    CommonModule,
  
  ],
  template: '',
})
export abstract class ShopItemModuleBaseComponent extends CommonModuleUtils implements OnInit, OnDestroy {

  @Input() public data: ISiModuleInputData = { };

  public rootStore: StoreService = inject(StoreService);
  public store: ShopItemAdminStoreService = inject(ShopItemAdminStoreService);
  public state: IShopItemAdminState = this.store.state;
  private _shopItem: IShopItemAdminStoreType  | null = null;
  public get shopItem() { return this._shopItem; }
  public set shopItem(value: IShopItemAdminStoreType  | null) {
    this._shopItem = value;
  }

  public orgStore: OrganizationStoreService = inject(OrganizationStoreService);
  public orgState: IOrganizationState = this.orgStore.state;
  public org: IOrganization | null = null;

  public utilsService = inject(UtilsService);

  public additionalPatchFormSub: Subscription = Subscription.EMPTY;
  public allowAdditionalPatching = false;

  subs: Subscription[] = [];

  constructor() {
    super();
    
    this.subs.push(
      this.state.shopItem$.subscribe(shopItem => {
        this.shopItem = shopItem;
        // some components need functionality to be patched more than once
        if (shopItem && this.allowAdditionalPatching) {
          console.log('patching again');
          this.patchForm();
          this.allowAdditionalPatching = false; // this is after patchForm, because patchForm could trigger change listeners that would set this to true again
        }
      }),

      this.orgState.org$.subscribe(org => {
        this.org = org;
      })
    );
  }

  toggleCollapse() {
    if (!this.data.moduleDefinition) return;
    this.data.moduleDefinition.collapsed = !this.data.moduleDefinition.collapsed;
  }
  collapse() {
    if (!this.data.moduleDefinition) return;
    this.data.moduleDefinition.collapsed = true;
  }
  uncollapse() {
    if (!this.data.moduleDefinition) return;
    this.data.moduleDefinition.collapsed = false;
  }

  ngOnInit(): void {
    // schvalne se patchuje pouze jednou pri inicializaci
    // nesmi se to volat v subscribe na zmenach na shopitemu, protoze můžu mít rozdělané změny v formuláři,
    // někde se něčím updatne shopitem a změny se ztratí
    // tam kde se musí patchovat po změnách shopitemu (z modulu), musí se patchform zavolat nějak zvlášť... třeba viz modul kategorií
    // todo vylepseni - pri uložení modulů zavolat patchform na všechny moduly
    this.patchForm();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.additionalPatchFormSub.unsubscribe();
  }
  
}
