import { AfterViewInit, Component, ElementRef, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { observeResizedContainers } from 'src/app/shared/services/helpers/input-resize-helper.service';

@Component({
  template: ''
})
export abstract class SubtleInputBase implements OnInit, OnDestroy, AfterViewInit {

  @Input() autoFocus: boolean = false;
  @Input() variant: 'grey' | 'light' = 'grey';
  get variantClass() {
    if (this.variant === 'light') return 'input-subtle--light';
    return '';
  }

  elRef = inject(ElementRef);

  private inputAutosizeObservers: MutationObserver[] = [];

  ngOnInit(): void {
    observeResizedContainers('input-autosize', this.inputAutosizeObservers, { includeBorders: true, includePadding: true, includePlaceholder: false });
  }

  ngAfterViewInit(): void {
    console.log('autoFocus', this.autoFocus);
    if (this.autoFocus) {
      const input = this.elRef.nativeElement.querySelector('input');
      console.log('input', input, this.elRef.nativeElement);
      if (input) input.focus();
      else {
        const textarea = this.elRef.nativeElement.querySelector('textarea');
        if (textarea) textarea.focus();
      }
    }
  }

  ngOnDestroy(): void {
    this.inputAutosizeObservers.forEach(observer => observer.disconnect());
  }
}