
@if (reservation; as r) {
  @if (r.entityType === ReservationTableEntityType.RESERVATION) {
    @switch(currentReservationState) {
      <!-- poptavka -->
      @case(ReservationCurrentState.OFFER_CANCELLED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OfferState.'+OfferState.CANCELLED | translate,
          icon: 'pi pi-times',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_CANCELLED)
        }"></ng-container>
        <div class="table-second-row">
          {{ (r.offer?.cancelledAt | date:'d.M.YY H:mm') }}
        </div>
      }
      @case(ReservationCurrentState.OFFER_CONFIRMED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OfferState.'+OfferState.CONFIRMED | translate,
          icon: 'pi pi-check',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_CONFIRMED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.offer?.createdAt | date:'d.M.YY H:mm') }">
            {{ r.order?.completedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.OFFER_IN_PREPARATION) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OfferState.'+OfferState.IN_PREPARATION | translate,
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_IN_PREPARATION)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.offer?.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
        </div>
      }
      @case (ReservationCurrentState.OFFER_ISSUED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OfferState.'+OfferState.ISSUED | translate,
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_ISSUED)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.offer?.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
        </div>
      }
      @case (ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OrderState.'+OrderState.PENDING | translate,
          icon: 'pi pi-money-bill',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_ISSUED__ORDER_PENDING)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.offer?.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
          @if (orderExpired) {
            <span class="text-red-500">
              {{ 'admin.reservations.table.reservation-expired-on.label' | translate:{ date: r.order?.expiration | date:'d.M.YY H:mm' } }}
            </span>
          }
          @else {
            {{ 'admin.reservations.table.reservation-will-expire-on.label' | translate:{ date: r.order?.expiration | date:'d.M.YY H:mm' } }}
          }
        </div>
      }
      @case (ReservationCurrentState.OFFER_NEW) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OfferState.'+OfferState.NEW | translate,
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.OFFER_NEW)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.offer?.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
        </div>
      }
      <!-- /poptavka -->
    
    
      <!-- reservation with order -->
      @case (ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OrderState.'+OrderState.AWAITING_ON_SITE_PAYMENT | translate,
          icon: 'pi pi-check',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_AWAITING_ON_SITE_PAYMENT)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.order?.completedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.ORDER_CANCELLED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OrderState.'+OrderState.CANCELLED | translate,
          icon: 'pi pi-times',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_CANCELLED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.cancelledAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.ORDER_COMPLETED) {
        @if (free) {
          <ng-container *ngTemplateOutlet="tag, context:{
            value: 'OrderState.'+OrderState.COMPLETED+'.free' | translate,
            icon: 'pi pi-check',
            bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_COMPLETED)
          }"></ng-container>
        } @else {
          <ng-container *ngTemplateOutlet="tag, context:{
            value: 'OrderState.'+OrderState.COMPLETED | translate,
            icon: 'pi pi-check',
            bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_COMPLETED)
          }"></ng-container>
        }
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.order?.completedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.ORDER_PARTIALLY_COMPLETED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OrderState.'+OrderState.PARTIALLY_COMPLETED | translate,
          icon: 'pi pi-check',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_PARTIALLY_COMPLETED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.order?.completedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.ORDER_PENDING) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'OrderState.'+OrderState.PENDING | translate,
          icon: 'pi pi-money-bill',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.ORDER_PENDING)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
          @if (orderExpired) {
            <span class="text-red-500">
              {{ 'admin.reservations.table.reservation-expired-on.label' | translate:{ date: r.order?.expiration | date:'d.M.YY H:mm' } }}
            </span>
          }
          @else {
            {{ 'admin.reservations.table.reservation-will-expire-on.label' | translate:{ date: r.order?.expiration | date:'d.M.YY H:mm' } }}
          }
        </div>
      }
      <!-- /reservation with order -->
    
    
      <!-- reservation without order -->
      @case (ReservationCurrentState.RESERVATION_APPROVED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationState.'+r.state | translate,
          icon: 'pi pi-send',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_APPROVED)
        }"></ng-container>
        <div [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }" class="table-second-row">
          @if (reservationExpired) {
            <span class="text-red-500">{{ 'admin.reservations.table.reservation-expired-on.label' | translate:{ date: r.expiration | date:'d.M.YY H:mm' } }}</span>
          } @else {
            <span>{{ 'admin.reservations.table.reservation-will-expire-on.label' | translate:{ date: r.expiration | date:'d.M.YY H:mm' } }}</span>
          }
        </div>
      }
      @case (ReservationCurrentState.RESERVATION_CANCELLED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationState.'+r.state | translate,
          icon: 'pi pi-times',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_CANCELLED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.cancelledAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.RESERVATION_CONFIRMED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationState.'+r.state | translate,
          icon: 'pi pi-check',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_CONFIRMED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.cancelledAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.RESERVATION_SUBMITTED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationState.'+r.state | translate,
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_SUBMITTED)
        }"></ng-container>
        <div class="table-second-row">
          <span>
            {{ 'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') } }}
          </span>
        </div>
      }
      @case (ReservationCurrentState.RESERVATION_WAITING) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationState.'+r.state | translate,
          icon: 'pi pi-clock',
          bgColor: ReservationCurrentStateColorMap.get(ReservationCurrentState.RESERVATION_WAITING)
        }"></ng-container>
        <div class="table-second-row">
          <span>
            {{ 'admin.reservations.table.reservation-issued-on.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') } }}
          </span>
        </div>
      }
      <!-- /reservation without order -->
    
      @default {
        not_implemented
        {{ utilsService.logError('Missing switch case '+currentReservationState+' in TagCellComponent') }}
      }
    }

  } @else if (r.entityType === ReservationTableEntityType.INVITATION) {
    @switch (currentInvitationState) {
      @case (ReservationInvitationState.ACCEPTED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationInvitationState.'+r.state | translate,
          icon: 'pi pi-check',
          bgColor: InvitationStateColorMap.get(ReservationInvitationState.ACCEPTED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-invitation-created-at.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{  r.acceptedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationInvitationState.REJECTED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationInvitationState.'+r.state | translate,
          icon: 'pi pi-times',
          bgColor: InvitationStateColorMap.get(ReservationInvitationState.REJECTED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-invitation-created-at.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.rejectedAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationInvitationState.CREATED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationInvitationState.'+r.state | translate,
          icon: 'pi pi-envelope',
          bgColor: InvitationStateColorMap.get(ReservationInvitationState.CREATED)
        }"></ng-container>
        <div class="table-second-row">
          <span>
            {{ r.createdAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationInvitationState.EXPIRED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationInvitationState.'+r.state | translate,
          icon: 'pi pi-hourglass',
          bgColor: InvitationStateColorMap.get(ReservationInvitationState.EXPIRED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-invitation-created-at.label' | translate:{ date: (r.createdAt | date:'d.M.YY H:mm') }">
            {{ r.expireAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @case (ReservationInvitationState.CANCELLED) {
        <ng-container *ngTemplateOutlet="tag, context:{
          value: 'ReservationInvitationState.'+r.state | translate,
          icon: 'pi pi-times',
          bgColor: InvitationStateColorMap.get(ReservationInvitationState.CANCELLED)
        }"></ng-container>
        <div class="table-second-row">
          <span [ngbTooltip]="'admin.reservations.table.reservation-invitation-cancelled-at.label' | translate:{ date: (r.cancelledAt | date:'d.M.YY H:mm') }">
            {{ r.cancelledAt | date:'d.M.YY H:mm' }}
          </span>
        </div>
      }
      @default {
        not_implemented
        {{ utilsService.logError('Missing switch case '+currentInvitationState+' in TagCellComponent') }}
      }
    }
  }

}
  


<ng-template #tag let-value="value" let-icon="icon" let-bgColor="bgColor">

  <p-tag [value]="value" [icon]="icon" styleClass="white-space-nowrap" [style]="{ 'background': 'var('+bgColor+')' }"></p-tag>

</ng-template>

