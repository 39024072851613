import { Component, HostBinding, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IAdditionalUserDataMap, IReservationTableEntity, ReservationTableEntityType, OrgReservationsStoreService } from 'src/app/shared/services/store/org-reservations-store.service';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { ChooseExpirationModalComponent } from './choose-expiration-modal/choose-expiration-modal.component';
import { Subject, Subscription, take } from 'rxjs';
import { BasicModalMode, ModalService } from 'src/app/shared/services/modal.service';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { AdminCustomerCardModalComponent, AdminCustomerCardTab } from '../../components/modals/customer-card-modal/admin-customer-card-modal.component';
import { IOrder } from 'src/app/shared/models/order/order-model';
import { RowIndexCellComponent } from './row-cell-components/row-index-cell/row-index-cell.component';
import { ReserverCellComponent } from './row-cell-components/reserver-cell/reserver-cell.component';
import { BeneficiaryCellComponent } from './row-cell-components/beneficiary-cell/beneficiary-cell.component';
import { ShopItemCellComponent } from './row-cell-components/shop-item-cell/shop-item-cell.component';
import { TypeCellComponent } from './row-cell-components/type-cell/type-cell.component';
import { PriceCellComponent } from './row-cell-components/price-cell/price-cell.component';
import { TagCellComponent } from './row-cell-components/tag-cell/tag-cell.component';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { TableModule } from 'primeng/table';
import { RecordPaymentModalComponent } from '../../payments-tabs/payments/record-payment-modal/record-payment-modal.component';
import { ShopItemReservationState } from 'src/app/shared/enums/shop-item/shop-item-reservation-states.enum';
import { IUser } from 'src/app/shared/models/user/user.model';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { CancelReservationModalComponent } from 'src/app/shared/modals/cancel-reservation-modal/cancel-reservation-modal.component';
import { OffersService } from 'src/app/shared/services/entities/offers/offers.service';
import { ReservationDetailModalComponent } from '../../components/modals/reservation-detail-modal/reservation-detail-modal.component';
import { ChangeShopitemModalComponent } from './change-shopitem-modal/change-shopitem-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OrganizationStoreService } from 'src/app/shared/services/store/organization-store.service';
import { OrderCellComponent } from './row-cell-components/order-cell/order-cell.component';
import { OrderItemPriceModalComponent } from '../../components/modals/order-item-price-modal/order-item-price-modal.component';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';
import { BasicModalComponent } from 'src/app/shared/modals/basic-modal/basic-modal.component';
import { ICustomTableColumnItem } from '../reservation-table/custom-table-columns-modal/custom-table-columns-modal.component';
import { ReservationTableColumnItemType } from '../reservation-table/custom-table-columns-modal/custom-table-column-item/custom-table-column-item.component';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';
import { JoinPipe } from 'src/app/shared/pipes/join.pipe';
import { BoolPipe } from 'src/app/shared/pipes/bool.pipe';
import { CustomDataCellComponent } from './row-cell-components/custom-data-cell/custom-data-cell.component';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { InflectionPipe } from 'src/app/shared/pipes/inflection.pipe';
import { DataType } from 'src/app/shared/enums/collecting-data/data-type.enum';
import { IModifyInvoiceResult, InvoiceModifyModalComponent } from 'src/app/pages/customer-org-orders/invoice/invoice-modify-modal/invoice-modify-modal.component';
import { AdminNoteCellComponent } from './row-cell-components/admin-note-cell/admin-note-cell.component';
import { ChangeBeneficiaryModalComponent } from 'src/app/shared/modals/change-beneficiary-modal/change-beneficiary-modal.component';
import { IInvoice } from 'src/app/shared/models/invoice/invoice.model';

@Component({
  selector: 'tr[app-reservation-table-row]',
  standalone: true,
  imports: [
    CommonModule, FormsModule,
    MenuModule, ButtonModule, TableModule, CheckboxModule, TranslateModule, JoinPipe, BoolPipe,
    NgbTooltipModule, PricePipe, PercentPipe, InflectionPipe,
    // cells
    RowIndexCellComponent, ReserverCellComponent, BeneficiaryCellComponent, ShopItemCellComponent, TypeCellComponent,
    PriceCellComponent, TagCellComponent, OrderCellComponent, CustomDataCellComponent, AdminNoteCellComponent
  ],
  templateUrl: './reservation-table-row.component.html',
  styleUrls: ['./reservation-table-row.component.scss'],
})
export class ReservationTableRowComponent implements OnDestroy {
  ReservationState = ReservationState;
  ReservationTableColumnItemType = ReservationTableColumnItemType;
  DataType = DataType;
  ReservationTableEntityType = ReservationTableEntityType;

  _reservation: IReservationTableEntity | undefined;
  get reservation(): IReservationTableEntity | undefined {
    return this._reservation;
  };
  @Input({required: true}) set reservation(value: IReservationTableEntity | undefined) {
    this._reservation = value;
    this.setActionMenuItems();
  };
  @Input({required: true}) columnData: { [key: string]: any; } = {};
  @Input({required: true}) additionalUserDataMap: IAdditionalUserDataMap = {};
  @Input({required: true}) userIdsWithAnyCustomerNotesMap: { [key: number]: boolean } = {};
  @Input({required: true}) rowIndex: number | undefined;
  @Input({required: true}) columnItems: ICustomTableColumnItem[] = [];
  @Input() isSelected: boolean | null = null; // null if selection is no enabled

  @Input() showShopItemColumn: boolean = true;
  @Input() showTypeColumn: boolean = true;

  menuItems: MenuItem[] = [];

  @ViewChild('regCancelSendNotificationsTemplate', { static: true }) regCancelSendNotificationsTemplate!: TemplateRef<any>;

  customerClickListener = new Subject<IUser|undefined>();
  commentClickListener = new Subject<IUser|undefined>();

  @HostBinding('class.loading-box') @Input() updating: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private orgReservationsStore: OrgReservationsStoreService,
    private offerService: OffersService,
    private modalService: NgbModal,
    private modalsService: ModalService,
    private rootModalService: ModalService,
    private navService: NavigationService,
    private translateService: TranslateService,
    private orgStore: OrganizationStoreService,
    private userNameOrEmailPipe: UserNameOrEmailPipe,
  ) {
    this.subs.push(
      this.customerClickListener.subscribe(user => {
        if (!user) return;
        this.onCustomerClick(user, AdminCustomerCardTab.CUSTOMER);
      }),
      this.commentClickListener.subscribe(user => {
        if (!user) return;
        this.onCustomerClick(user, AdminCustomerCardTab.NOTES);
      })
    );
  }

  private cancelReservation() {
    if (!this.reservation) return;
    if (this.reservation.entityType !== ReservationTableEntityType.RESERVATION) return;

    const modalRes = this.rootModalService.openCancelReservationModal(CancelReservationModalComponent, this.reservation, this.reservation.order || null);
    modalRes.pipe(take(1)).subscribe((result) => {
      if (!this.reservation) return;
      if (this.reservation.entityType !== ReservationTableEntityType.RESERVATION) return;

      if (this.reservation.offerId) {
        this.orgReservationsStore.cancelOffer({
          sendNotifications: result.sendNotifications,
          stornoFee: result.stornoFee,
          offerId: this.reservation.offerId
        }, { id: this.reservation.id, entityType: ReservationTableEntityType.RESERVATION }).subscribe();
      } else {
        this.orgReservationsStore.cancel({
          data: [
            {
              reservationId: this.reservation.id,
              note: null,
              stornoFee: result.stornoFee,
              refundToCredit: result.refundToCredit,
              partner: result.partner
                ? {
                  note: null,
                  refundToCredit: result.partner.refundToCredit,
                  reservationId: result.partner.reservationId,
                  stornoFee: result.partner.stornoFee
                }
                : null
            }
          ],
          sendNotifications: result.sendNotifications,
          actionBy: ActionBy.ADMIN,
          partnerReservationId: this.reservation.partnerReservationId || undefined
        }).subscribe();
      }
    });
  }

  onOrder(order: IOrder) {
    this.rootModalService.openAdminCustomerCardModal({
      c: AdminCustomerCardModalComponent,
      userId: order.userId,
      fromOrderNumbers: [+`${order.id}${order.randomNumber}`],
      activeTab: AdminCustomerCardTab.ORDERS
    });
  }
  onWaitingForOrder(reservation: IReservation) {
    this.rootModalService.openAdminCustomerCardModal({
      c: AdminCustomerCardModalComponent,
      userId: reservation.beneficiaryUserId,
      activeTab: AdminCustomerCardTab.RESERVATIONS
    });
  }
  onCustomerClick(user: IUser, tab: AdminCustomerCardTab) {
    this.rootModalService.openAdminCustomerCardModal({
      c: AdminCustomerCardModalComponent,
      userId: user.id,
      activeTab: tab
    });
  }
  

  private showOffer() {
    const org = this.orgStore.state.org$.getValue();
    if (!org) return;
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

    this.navService.goToOffer({ offerUuid: this.reservation?.offer?.uuid!, newTab: true, orgCustomDomains: org.customDomainsVerified });
  }

  private approveWaiting() {
    if (!this.reservation) return;

    this.orgReservationsStore.approveWaiting([{
      reservationId: this.reservation.id,
      note: null
    }]).subscribe();
  }

  private approveSubmitted() {
    if (!this.reservation) return;

    this.orgReservationsStore.approveSubmitted([{
      reservationId: this.reservation.id,
      note: null
    }]).subscribe();
  }

  private setWaiting() {
    if (!this.reservation) return;

    this.orgReservationsStore.setWaiting([{
      reservationId: this.reservation.id,
    }]).subscribe();
  }

  private confirmAsGuest() {
    if (!this.reservation) return;

    this.orgReservationsStore.confirmAsGuest({
      reservationId: this.reservation.id,
    }).subscribe();
  }

  private setReservationExpiration(datetime: string) {
    if (!this.reservation) return;

    this.orgReservationsStore.setReservationExpiration([{
      reservationId: this.reservation.id,
      datetime
    }]).subscribe();
  }

  private changeBeneficiaryUser() {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
    if (!this.reservation?.shopItem || !this.reservation.beneficiaryUser) return;
    const modalRes = this.rootModalService.openChangeBeneficiaryModal(ChangeBeneficiaryModalComponent, this.reservation.shopItem, this.reservation.beneficiaryUser);
    modalRes.pipe(take(1)).subscribe((result) => {
      if (!this.reservation) return;
      this.orgReservationsStore.changeBeneficiary({
        beneficiaryUser: result.beneficiaryUser,
        beneficiaryUserId: result.beneficiaryUserId,
        reservationId: this.reservation.id
      }).subscribe();
    });
  }

  private cancelOrderItem() {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
    if (!this.reservation?.orderId) return;

    const modalRes = this.rootModalService.openCancelReservationModal(CancelReservationModalComponent, this.reservation, this.reservation.order || null);
    modalRes.pipe(take(1)).subscribe((result) => {
      if (!this.reservation) return;
      if (this.reservation.entityType !== ReservationTableEntityType.RESERVATION) return;
      
      this.orgReservationsStore.cancel({
        data: [
          {
            reservationId: this.reservation.id,
            note: null,
            stornoFee: result.stornoFee,
            refundToCredit: result.refundToCredit,
            partner: result.partner
              ? {
                note: null,
                refundToCredit: result.partner.refundToCredit,
                reservationId: result.partner.reservationId,
                stornoFee: result.partner.stornoFee
              }
              : null
          }
        ],
        actionBy: ActionBy.ADMIN,
        sendNotifications: result.sendNotifications,
        partnerReservationId: this.reservation.partnerReservationId || undefined
      }).subscribe();
    });
  }

  private cancelOrder() {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
    if (!this.reservation?.orderId) return;

    const modalRes = this.rootModalService.openConfirmDeleteModal({
      c: ConfirmDeleteModalComponent,
      title: this.translateService.instant('admin.reservations.cancel-order.modal.title', { orderNumber: `${this.reservation.order?.id}${this.reservation.order?.randomNumber}` }),
      text: this.translateService.instant('admin.reservations.cancel-order.modal.text', { orderNumber: `${this.reservation.order?.id}${this.reservation.order?.randomNumber}` }),
      deleteBtnLabel: 'admin.reservations.cancel-order.modal.confirm-btn.label',
      cancelBtnLabel: 'admin.reservations.cancel-order.modal.cancel-btn.label'
    });

    modalRes.pipe(take(1)).subscribe(v => {
      if (v) {
        if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
        if (!this.reservation?.orderId) return;

        this.orgReservationsStore.cancelOrders([{
          actionBy: ActionBy.ADMIN,
          orderId: this.reservation.orderId,
          note: null
        }]).subscribe();
      }
    });

  }

  private setOrderExpiration(datetime: string) {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

    if (!this.reservation?.orderId) return;

    this.orgReservationsStore.setOrderExpiration([{
      orderId: this.reservation.orderId,
      datetime
    }]).subscribe();
  }

  private restoreCancelledReservation() {
    if (!this.reservation) return;
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

    if (this.reservation.shopItem?.sessionGroup?.registrationType === SessionGroupRegistrationType.INDIVIDUALS_COUPLE && this.reservation.partnerReservationId) {
      this.modalsService.openBasicModal(BasicModalComponent, {
        buttonMode: BasicModalMode.TWO_BUTTON,
        title: 'admin.reservations.table.menu-item.reservation.restore-couple-reservation-modal.title',
        text: '',
        leftBtnLabel: 'admin.reservations.table.menu-item.reservation.restore-couple-reservation-modal.as-couple.label',
        leftBtnLabelData: {},
        leftBtnWrapClass: 'w-full',
        leftBtnClass: 'w-full',
        leftBtnAction: () => this.orgReservationsStore.restoreCancelledReservation(this.reservation!.id, false).subscribe(),
        rightBtnLabel: 'admin.reservations.table.menu-item.reservation.restore-couple-reservation-modal.as-individual.label',
        rightBtnLabelData: { userName: this.userNameOrEmailPipe.transform(this.reservation.beneficiaryUser?.orgUsers??[], this.reservation.organizationId, false) },
        rightBtnWrapClass: 'w-full',
        rightBtnClass: 'w-full',
        rightBtnAction: () => this.orgReservationsStore.restoreCancelledReservation(this.reservation!.id, true).subscribe(),
        template: null,
      });
    } else {
      this.orgReservationsStore.restoreCancelledReservation(this.reservation.id, false).subscribe();
    }
  }

  private restoreCancelledOrder() {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
    if (!this.reservation?.orderId) return;

    this.orgReservationsStore.restoreCancelledOrder({ id: this.reservation.id, entityType: ReservationTableEntityType.RESERVATION }, this.reservation.orderId).subscribe();
  }

  private changeReservationShopItem() {
    if (!this.reservation) return;
    this.rootModalService.openChangeReservationShopItemModal(ChangeShopitemModalComponent, this.reservation.id);
  }

  private changeOrderItemPrice() {
    if (!this.reservation) return;
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

    if (this.reservation.order?.state === OrderState.PENDING) {
      if (this.reservation.state === ReservationState.APPROVED && this.reservation.orderItem?.id) {
        this.modalsService.openOrderItemPriceModal(OrderItemPriceModalComponent, this.reservation.orderItem.id, this.reservation.id, this.reservation.orderItem.basePriceValue, this.reservation.orderItem.currency, this.orgReservationsStore.updateReservationPrice.bind(this.orgReservationsStore));
      }
    }
  }

  private issueInvoice() {
    if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;
    if (!this.reservation || !this.reservation?.order) return;

    this.orgReservationsStore.getInvoicePlaceholder({ id: this.reservation.id, entityType: ReservationTableEntityType.RESERVATION }, this.reservation.order.id).pipe(take(1)).subscribe((placeholderInvoice) => {
      if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

      const modified = this.modalsService.openInvoiceModifyModal(InvoiceModifyModalComponent, placeholderInvoice, this.reservation!.order!, false, false, true);
      modified.pipe(take(1)).subscribe((res: IModifyInvoiceResult) => {
        if (res.affectedReservationIds && res.affectedReservationIds.length) {
          this.orgReservationsStore.updateReservationsInState(res.affectedReservationIds.map(id => ({ id, entityType: ReservationTableEntityType.RESERVATION })));
        }
      });
    });
  }

  private openChooseExpirationModal(type: 'RESERVATION' | 'ORDER', currentDatetime: string | null) {
    const chosenDatetime = this.rootModalService.openChooseExpirationModal(ChooseExpirationModalComponent, currentDatetime);

    chosenDatetime.pipe(
      take(1)
    ).subscribe({
      next: (res: string) => {
        if (type === 'RESERVATION') this.setReservationExpiration(res);
        else if (type === 'ORDER') this.setOrderExpiration(res);
      }
    });
  }

  setActionMenuItems() {

    if (this.reservation?.entityType === ReservationTableEntityType.RESERVATION) {

      const detailItem: MenuItem = {
        label: this.translateService.instant('admin.reservations.table.menu-item.reservation.detail.label'),
        command: () => this.rootModalService.openReservationDetailModal(ReservationDetailModalComponent, this.reservation?.id!)
      }
  
      const items: MenuItem[] = [ detailItem ];
  
      if (!this.reservation) {
        this.menuItems = [];
        return;
      };
  
      // je v OFFER
      if (this.reservation.offerId) {
        /* SHOW OFFER */
        items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.offer.show.label'),
          command: () => this.showOffer()
        });
  
        /* CANCEL */
        // TODO zrusit co? rezervaci? offer?
        items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.offer.cancel.label'),
          command: () => this.cancelReservation()
        });
      }
      // neni v OFFER
      else {
        
        /* CANCEL RESERVATION */
        if (this.isReservationCancelable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.cancel.label'),
          command: () => this.cancelReservation()
        });
  
        /* APPROVE WAITING */
        if (this.isApproveableWaiting(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.approve-waiting.label'),
          command: () => this.approveWaiting()
        });
    
        /* APPROVE SUBMITTED */
        if (this.isApproveableSubmitted(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.approve-submitted.label'),
          command: () => this.approveSubmitted()
        });
    
        /* SET WAITING */
        if (this.isSetWaitingable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.set-waiting.label'),
          command: () => this.setWaiting()
        });
  
        /* CONFIRM AS GUEST */
        if (this.isConfirmAsGuestable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.confirm-as-guest.label'),
          command: () => this.confirmAsGuest()
        });
    
        /* SET RESERVATION EXPIRATION */
        if (this.isSetExpirationable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.change-reservation-expiration.label'),
          command: () => {
            if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

            this.openChooseExpirationModal('RESERVATION', this.reservation?.expiration ?? null)
          }
        });
    
        /* CANCEL ORDER ITEM */
        if (this.isOrderCancelable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.cancel-order-item.label'),
          command: () => this.cancelOrderItem()
        });
  
        /* CHANGE BENEFICIARY USER */
        if (this.isReservationBeneficiaryChangeable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.change-beneficiary.label'),
          disabled: this.isReservationBeneficiaryDisabled(this.reservation!),
          command: () => this.changeBeneficiaryUser()
        });
    
        /* CANCEL ORDER */
        // if (this.isOrderCancelable(this.reservation)) items.push({
        //   label: this.translateService.instant('admin.reservations.table.menu-item.reservation.cancel-order.label'),
        //   command: () => this.cancelOrder()
        // });
    
        /* SET ORDER EXPIRATION */
        if (this.isOrderSetExpirationable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.change-order-expiration.label'),
          command: () => {
            if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

            this.openChooseExpirationModal('ORDER', this.reservation?.order?.expiration ?? null)
          }
        });
  
        /* RECORD PAYMENT */
        if (this.reservation.orderId) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.record-payment.label'),
          disabled: this.reservation.order?.state === OrderState.CANCELLED,
          command: () => {
            if (this.reservation?.entityType !== ReservationTableEntityType.RESERVATION) return;

            const refreshReservation$ = new Subject<void>();

            this.subs.push(refreshReservation$.subscribe(() => {
              this.orgReservationsStore.updateReservationsInState([{ entityType: ReservationTableEntityType.RESERVATION, id: this.reservation!.id }]);
            }));

            this.rootModalService.openRecordPaymentModal(RecordPaymentModalComponent, {
              user: this.reservation?.reserverUser,
              orderId: this.reservation?.orderId,
              refreshReservation$: refreshReservation$
            });
          }
        });

        if (this.isReservationRestorable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.restore-reservation.label'),
          command: () => this.restoreCancelledReservation(),
          disabled: this.reservation.shopItem?.reservationState !== ShopItemReservationState.OPENED,
          tooltipOptions: {
            tooltipLabel: this.reservation.shopItem?.reservationState !== ShopItemReservationState.OPENED ? this.translateService.instant('admin.reservations.table.menu-item.reservation.restore-reservation.tooltip') : undefined
          }
        });
  
        if (this.isOrderRestorable(this.reservation)) items.push({
          disabled: true,
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.restore-order.label'),
          command: () => this.restoreCancelledOrder(),
        });
  
        if (this.isReservationShopItemChangeable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.change-shop-item.label'),
          command: () => this.changeReservationShopItem(),
        });
  
        if (this.isReservationPriceChangeable(this.reservation)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.change-price.label'),
          command: () => this.changeOrderItemPrice(),
        });
  
        if (isOrderInvoiceIssuable(this.reservation.order)) items.push({
          label: this.translateService.instant('admin.reservations.table.menu-item.reservation.issue-new-invoice.label'),
          command: () => this.issueInvoice()
        });
      }
  
  
      this.menuItems = items;

    }
    else if (this.reservation?.entityType === ReservationTableEntityType.INVITATION) {
      this.menuItems = [];
    }
  }

  private isReservationBeneficiaryChangeable(r: IReservation) {
    return r.state !== ReservationState.CANCELLED;
  }
  private isReservationBeneficiaryDisabled(r: IReservation) {
    return !!(
      r.shopItem?.sessionGroup?.registrationType === SessionGroupRegistrationType.PARENT_KIDS ||
      r.shopItem?.sessionGroup?.registrationType === SessionGroupRegistrationType.ADULT_CHILDREN_GROUP ||
      (r.shopItem?.sessionGroup?.registrationType === SessionGroupRegistrationType.PARENT_KIDS_INDIVIDUAL && r.parentId)
    );
  }

  private isOrderRestorable(r: IReservation): boolean {
    return r.order?.state === OrderState.CANCELLED;
  }

  private isReservationRestorable(r: IReservation): boolean {
    if (r.state !== ReservationState.CANCELLED) return false;
    return true;
  }

  private isReservationPriceChangeable(r: IReservation): boolean {
    if (r.order?.state === OrderState.PENDING) {
      if (r.state === ReservationState.APPROVED && r.orderItem?.id) {
        return true
      }
    }
    return false;
  }

  private isReservationCancelable(r: IReservation): boolean {
    if (r.orderId !== null) return false;

    const allowedStates = [
      ReservationState.APPROVED,
      ReservationState.SUBMITTED,
      ReservationState.WAITING,
      ReservationState.CONFIRMED
    ];
    if (!allowedStates.includes( r.state )) return false;

    return true;
  }

  private isReservationConfirmable(r: IReservation): boolean {
    if (r.orderId !== null) return false;

    const allowedStates = [
      ReservationState.APPROVED,
    ];
    if ( !allowedStates.includes( r.state ) ) return false;

    return true;
  }

  private isApproveableWaiting(r: IReservation): boolean {
    const allowedStates = [
      ReservationState.WAITING,
    ];
    if ( !allowedStates.includes( r.state ) ) return false;

    return true;
  }

  private isApproveableSubmitted(r: IReservation): boolean {
    const allowedStates = [
      ReservationState.SUBMITTED
    ];
    if ( !allowedStates.includes( r.state ) ) return false;

    return true;
  }

  private isSetWaitingable(r: IReservation): boolean {
    const allowedStates = [
      ReservationState.SUBMITTED
    ];
    if ( !allowedStates.includes( r.state ) ) return false;

    return true;
  }

  private isConfirmAsGuestable(r: IReservation): boolean {
    if (r.state === ReservationState.CANCELLED) return false;
    if (!r.order) return true;
    if (r.order.state !== OrderState.PENDING) return false;
    return true;
  }

  private isSetExpirationable(r: IReservation): boolean {
    const allowedStates = [
      ReservationState.APPROVED
    ];
    if ( !allowedStates.includes( r.state ) ) return false;
    if ( r.orderId !== null ) return false;

    return true;
  }

  private isOrderCancelable(r: IReservation): boolean {
    const allowedStates = [
      OrderState.PENDING,
      OrderState.PARTIALLY_COMPLETED,
      OrderState.COMPLETED,
      OrderState.PARTIALLY_COMPLETED,
      OrderState.AWAITING_ON_SITE_PAYMENT
    ];
    if ( !r.order ) return false;
    if ( !allowedStates.includes( r.order.state ) ) return false;
    if ( !r.orderId ) return false;

    return true;
  }

  private isOrderSetExpirationable(r: IReservation): boolean {
    const allowedStates = [
      OrderState.PENDING
    ];
    if ( !r.order ) return false;
    if ( !allowedStates.includes( r.order.state ) ) return false;

    return true;
  }

  private isReservationShopItemChangeable(r: IReservation): boolean {
    const allowedStates = [
      ReservationState.APPROVED,
      ReservationState.SUBMITTED,
      ReservationState.WAITING,
    ];
    if (r.orderId) return false;
    if (r.offerId) return false;
    if ((r.partnerReservationId || r.partnerId) && r.partnerReservation && !allowedStates.includes(r.partnerReservation.state)) return false;
    if ( !allowedStates.includes( r.state ) ) return false;

    return true;
  }

  get reservationCategories() {
    return this.reservation?.shopItem?.categories?.map((category) => category.title) || [];
  }

  get isReservationEntityType() {
    return this.reservation?.entityType === ReservationTableEntityType.RESERVATION;
  }
  get isInvitationEntityType() {
    return this.reservation?.entityType === ReservationTableEntityType.INVITATION;
  }
  

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export function isOrderInvoiceIssuable(order: { state: OrderState; invoices?: IInvoice[]; } | null | undefined): boolean {
  return !!(order?.invoices && order.state !== OrderState.CANCELLED && !order.invoices.length);
}
