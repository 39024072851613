import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormControlStatus } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Editor, EditorModule } from 'primeng/editor';
import { OverlayPanel } from 'primeng/overlaypanel';
import { EDITOR_OVERLAY_TRIGGERING_CHAR, EditorOverlayDirective } from '../../directives/editor-overlay.directive';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import 'quill-paste-smart'; // ensures that when pasting text, it will have only those formats, that are available in the editor - mainly added bcs when copy-pasting, it would add background-color to it

@Component({
  selector: 'app-editor-wrap-component',
  standalone: true,
  imports: [
    ReactiveFormsModule, TranslateModule,
    EditorModule,
    EditorOverlayDirective,
    NgbTooltipModule,
  ],
  templateUrl: './editor-wrap-component.component.html',
  styleUrl: './editor-wrap-component.component.scss'
})
export class EditorWrapComponent implements OnInit, OnDestroy {
  EDITOR_OVERLAY_TRIGGERING_CHAR = EDITOR_OVERLAY_TRIGGERING_CHAR;

  @Input({ required: true }) FC: FormControl | undefined;
  @Input() placeholder: string | null = null;
  @Input() overlay: OverlayPanel | undefined;

  @ViewChild('editor') editor: Editor | undefined;
  @ViewChild('editor', { read: EditorOverlayDirective }) editorOverlay: EditorOverlayDirective | undefined;

  subs: Subscription[] = [];

  ngOnInit(): void {
    this.subs.push(
      this.FC?.statusChanges.subscribe(status => {
        if (this.FC?.disabled) this.editor?.quill.disable();
        else this.editor?.quill.enable();
      }) ?? Subscription.EMPTY
    );
  
  }

  removeFormat() {
    const range = this.editor?.quill.getSelection();
    if (range) {
      this.editor?.quill.removeFormat(range.index, range.length, Quill.sources.USER);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
