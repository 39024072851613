import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IOrderFE, IOrderItemFE } from 'src/app/shared/models/order/order-model';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ItemTitlePipe } from 'src/app/shared/pipes/item-title.pipe';
import { PriceCountPipe } from 'src/app/shared/pipes/price-count.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { OrderItemPriceModalComponent } from '../../../order-item-price-modal/order-item-price-modal.component';
import { CustomerCardStoreService } from 'src/app/shared/services/store/customer-card-store.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OrderHelperService } from 'src/app/shared/services/helpers/order-helper.service';
import { ReservationUsersPipe } from 'src/app/shared/pipes/reservation-users.pipe';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';

@Component({
  selector: 'app-customer-card-order-order-item',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    MenuModule, ButtonModule, PricePipe, ItemTitlePipe, PriceCountPipe,
    UserNameOrEmailPipe, LocalizedDatePipe,
    NgbTooltipModule, ReservationUsersPipe
  ],
  templateUrl: './customer-card-order-order-item.component.html',
  styleUrls: ['./customer-card-order-order-item.component.scss']
})
export class CustomerCardOrderOrderItemComponent implements OnDestroy {
  OrderState = OrderState;
  ReservationState = ReservationState;

  _order: IOrderFE | undefined;
  get order() { return this._order; };
  @Input() set order(val: IOrderFE | undefined) { this._order = val; this.createMenuItems(); };
  _orderItem: IOrderItemFE | undefined;
  get orderItem() { return this._orderItem; };
  @Input() set orderItem(val: IOrderItemFE | undefined) { this._orderItem = val; this.createMenuItems(); };

  menuItems: MenuItem[] = [];

  subs: Subscription[] = [];

  constructor(
    private modalsService: ModalService,
    public siHelper: ShopItemHelperService,
    public orderHelper: OrderHelperService,
    private customerCardStore: CustomerCardStoreService,
    private translate: TranslateService,
  ) {
    this.subs.push(
      this.translate.onLangChange.subscribe(() => this.createMenuItems())
    )
  }

  createMenuItems() {
    const menuItems: MenuItem[] = [];

    if (!this.orderItem || !this.order) return;

    if (this.order?.state === OrderState.CANCELLED) return;
    if (this.order?.state === OrderState.PENDING) {
      if (this.orderItem?.reservation.state === ReservationState.APPROVED) {
        menuItems.push({
          label: this.translate.instant('admin.customer-card-order-order-item.menu.edit-price.label'),
          icon: 'pi pi-pencil',
          command: () => this.onChangeOrderItemPrice(),
        });
      }

    }

    this.menuItems = menuItems;
  }

  onChangeOrderItemPrice() {
    if (!this.orderItem || !this.order) return;
    this.modalsService.openOrderItemPriceModal(OrderItemPriceModalComponent, this.orderItem.id, this.orderItem.reservation.id, this.orderItem.basePriceValue, this.orderItem.currency, this.customerCardStore.updateOrderItemPrice.bind(this.customerCardStore));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
