import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { IOrderItemFE } from 'src/app/shared/models/order/order-model';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IOrderItemPriceUpdateData } from 'src/app/shared/services/entities/order-items/order-items.service';
import { Observable, finalize, take } from 'rxjs';
import { InputNumberModule } from 'primeng/inputnumber';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { TranslateModule } from '@ngx-translate/core';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';

@Component({
  selector: 'app-order-item-price-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    InputNumberModule, ButtonModule,
    ModalHeaderComponent, NumberInputDirective
    
  ],
  templateUrl: './order-item-price-modal.component.html',
  styleUrls: ['./order-item-price-modal.component.scss']
})
export class OrderItemPriceModalComponent extends Modal implements OnInit {

  @Input() orderItemId: number | null = null;
  @Input() reservationId: number | null = null;
  @Input() basePriceValue: number | null = null;
  @Input() currency: Currency | null = null;
  @Input() saveAction: ((d: IOrderItemPriceUpdateData) => Observable<any>) | undefined;

  form = new FormControl<number | null>(null, { validators: [ Validators.required, Validators.min(0) ]});

  sending = false;

  constructor(
    private utilsService: UtilsService,
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.form.patchValue(this.basePriceValue ?? 0);
  }

  onSubmit() {
    this.utilsService.markControlDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    if (!this.orderItemId || !this.saveAction) return;

    const data: IOrderItemPriceUpdateData = {
      orderItemId: this.orderItemId,
      basePriceValue: this.form.value!,
      reservationId: this.reservationId!
    };

    this.sending = true;
    this.saveAction(data).pipe(
      take(1),
      finalize(() => this.sending = false)
    ).subscribe({
      next: () => {
        this.close();
      }
    });

  }
}

