


<p-overlayPanel
  #overlay
  [baseZIndex]="zIndex"
  styleClass="p-0">

  <ng-template pTemplate="content">
    <div class="compensate-overlay-panel-padding">
      
      <app-short-codes-menu
        [menuItems]="menuItems">
      </app-short-codes-menu>

    </div>

  </ng-template>
  
</p-overlayPanel>