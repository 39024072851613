import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';
import { BlankInputComponent } from 'src/app/shared/components/blank-input/blank-input.component';
import { IUserPreferencesData_AdminEventShopItemsFilter } from 'src/app/shared/models/user-preference/user-preference.model';
import { CoordinatorsService } from 'src/app/shared/services/entities/users/coordinators/coordinators.service';
import { UserHelperService } from 'src/app/shared/services/helpers/user-helper.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { UserPreferencesStoreService } from 'src/app/shared/services/store/user-preferences-store.service';
import { EventShopItemFilterTogglerModalComponent } from '../event-shop-item-filter-toggler-modal/event-shop-item-filter-toggler-modal.component';
import { DeviceType, SystemService } from 'src/app/shared/services/system.service';
import { LocationsService } from 'src/app/shared/services/entities/locations/locations.service';
import { CalendarModule } from 'primeng/calendar';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { EventSiFilterItemCoordinatorComponent, IEventShopItemFilterItem_COORDINATOR } from './types/event-si-filter-item-coordinator/event-si-filter-item-coordinator.component';
import { EventSiFilterItemCategoryComponent, IEventShopItemFilterItem_CATEGORY } from './types/event-si-filter-item-category/event-si-filter-item-category.component';
import { EventSiFilterItemDateRangeComponent, IEventShopItemFilterItem_DATE_RANGE } from './types/event-si-filter-item-date-range/event-si-filter-item-date-range.component';
import { EventSiFilterItemLocationComponent, IEventShopItemFilterItem_LOCATION } from './types/event-si-filter-item-location/event-si-filter-item-location.component';
import { EventSiFilterItemPublicTagComponent, IEventShopItemFilterItem_PUBLIC_TAG } from './types/event-si-filter-item-public-tag/event-si-filter-item-public-tag.component';
import { AsyncPipe } from '@angular/common';
import { EventSiFilterItemInternalTagComponent, IEventShopItemFilterItem_INTERNAL_TAG } from './types/event-si-filter-item-internal-tag/event-si-filter-item-internal-tag.component';
import { EventShopItemOrderByType, EventSiFilterOrderByComponent, IEventShopItemFilterItem_ORDER_BY } from './types/event-si-filter-order-by/event-si-filter-order-by.component';
import { EventSiFilterItemStateComponent, IEventShopItemFilterItem_STATE } from './types/event-si-filter-item-state/event-si-filter-item-state.component';
import { EventSiFilterItemReservationStateComponent, IEventShopItemFilterItem_RESERVATION_STATE } from './types/event-si-filter-item-reservation-state/event-si-filter-item-reservation-state.component';
import { IEvent } from 'src/app/shared/models/event/event.model';

export enum EventShopItemFilterItemType {
  COORDINATOR = 'COORDINATOR',
  LOCATION = 'LOCATION',
  DATE_RANGE = 'DATE_RANGE',
  CATEGORY = 'CATEGORY',
  PUBLIC_TAG = 'PUBLIC_TAG',
  INTERNAL_TAG = 'INTERNAL_TAG',
  STATE = 'STATE',
  RESERVATION_STATE = 'RESERVATION_STATE',

  ORDER_BY = 'ORDER_BY',
}

export type IEventShopItemFilterItem = 
  IEventShopItemFilterItem_COORDINATOR | 
  IEventShopItemFilterItem_LOCATION |
  IEventShopItemFilterItem_DATE_RANGE |
  IEventShopItemFilterItem_CATEGORY |
  IEventShopItemFilterItem_PUBLIC_TAG |
  IEventShopItemFilterItem_INTERNAL_TAG |
  IEventShopItemFilterItem_ORDER_BY |
  IEventShopItemFilterItem_STATE |
  IEventShopItemFilterItem_RESERVATION_STATE;

@Component({
  selector: 'app-event-shop-items-filter',
  standalone: true,
  imports: [
    ReactiveFormsModule, TranslateModule, FormsModule, AsyncPipe,
    MultiSelectModule, ButtonModule, ChipModule, InputTextModule, CalendarModule,
    BlankInputComponent, LocalizedDatePipe,

    EventSiFilterItemCategoryComponent, EventSiFilterItemCoordinatorComponent, EventSiFilterItemDateRangeComponent, EventSiFilterItemLocationComponent, EventSiFilterItemPublicTagComponent, EventSiFilterItemInternalTagComponent, EventSiFilterOrderByComponent, EventSiFilterItemStateComponent, EventSiFilterItemReservationStateComponent
  ],
  templateUrl: './event-shop-items-filter.component.html',
  styleUrl: './event-shop-items-filter.component.scss'
})
export class EventShopItemsFilterComponent implements OnInit, OnDestroy {
  DeviceType = DeviceType;
  EventShopItemFilterItemType = EventShopItemFilterItemType;

  @Input({required: true}) event: IEvent | undefined;
  @Input({required: true}) searchFC: FormControl<string> | undefined;
  @Input() hideFilters: boolean = false;
  @Output() customOrderEnabled = new EventEmitter<boolean>();

  userFilterPreferences$ = new BehaviorSubject<IEventShopItemFilterItem[]>([]);
  changeTrigger$ = new Subject<IEventShopItemFilterItem>();
  
  userPreferencesState = this.userPreferencesStore.state;




  filterPreferences: IUserPreferencesData_AdminEventShopItemsFilter | undefined;

  subs: Subscription[] = [];
  formSubs: Subscription[] = [];

  constructor(
    private userPreferencesStore: UserPreferencesStoreService,
    private userHelper: UserHelperService,
    private modalService: ModalService,
    public sysService: SystemService,
    private locationsService: LocationsService,
    private translate: TranslateService
  ) {
    
  }

  ngOnInit(): void {
    this.subs.push(
      this.userPreferencesStore.state.preferences$.subscribe(p => {
        const filterPrefs = p.admin_event_shop_items_filter?.[this.event?.uuid ?? '-1'] ?? [];
        this.userFilterPreferences$.next(filterPrefs);

        const orderItem = filterPrefs?.find(x => x.type === EventShopItemFilterItemType.ORDER_BY) as IEventShopItemFilterItem_ORDER_BY | undefined;
        if (orderItem && (!orderItem.value?.value || orderItem?.value.value === EventShopItemOrderByType.BY_UI_INDEX)) {
          this.customOrderEnabled.emit(true);
        } else {
          this.customOrderEnabled.emit(false);
        }
      }),

      this.changeTrigger$.pipe(
        debounceTime(300)
      ).subscribe({
        next: x => {
          if (!this.event) return;
          const currentItems = this.userFilterPreferences$.value;
          const idx = currentItems.findIndex(y => y.type === x.type);
          if (idx === -1) return;

          currentItems[idx] = x;
          this.userPreferencesStore.upsert({
            admin_event_shop_items_filter: {
              ...this.userPreferencesStore.state.preferences$.value.admin_event_shop_items_filter,
              [this.event.uuid]: currentItems
            }
          })?.subscribe();
        }
      })
    );
  }

  onChange(item: IEventShopItemFilterItem) {
    this.changeTrigger$.next(item);
  }

  onToggleFilterEntities() {
    if (!this.event) return;
    this.modalService.openEventShopItemFilterTogglerModal(EventShopItemFilterTogglerModalComponent, this.userFilterPreferences$.value, this.event.uuid);
  }
 
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.formSubs.forEach(sub => sub.unsubscribe());
  }

}
