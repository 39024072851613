


<div (click)="dropdownEl.show()" class="filter-wrap relative">
  <p class="filter-title">
    {{ 'admin.event-shop-items-filter.order-by.label' | translate }}
  </p>

  <p-chip [label]="('EventShopOrderByType.' + FC.value) | translate"></p-chip>

  <!-- todo use virtualscroll - was bugged when writing... from 7 options showed only 3-->
  <p-dropdown
    #dropdownEl
    [formControl]="FC"
    [options]="options"
    [optionValue]="'value'"
    [optionLabel]="'label'"
    [overlayOptions]="{appendTo: 'body'}">
  </p-dropdown>
</div>