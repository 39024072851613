import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { TranslateModule } from '@ngx-translate/core';
import { ISiModuleInputData } from '../_shop-item-module-base/shop-item-module-base.component';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { IShopItemAdminState, ShopItemAdminStoreService } from 'src/app/shared/services/store/shop-item-admin-store.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { take } from 'rxjs';
import { FormControl } from '@angular/forms';
import { BlankInputComponent } from 'src/app/shared/components/blank-input/blank-input.component';
import { CloneShopItemSettingsModuleModalComponent } from 'src/app/shared/modals/clone-shop-item-settings-module-modal/clone-shop-item-settings-module-modal.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ShopItemsService } from 'src/app/shared/services/entities/shop-items/shop-items.service';

export type ICustomModuleTitleFC = FormControl<string | null>;

@Component({
  selector: 'app-shop-item-module-wrapper',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    CdkDragHandle, CdkDrag,
    BlankInputComponent, NgbTooltipModule
  ],
  templateUrl: './shop-item-module-wrapper.component.html',
  styleUrls: ['./shop-item-module-wrapper.component.scss'],
  animations: [ collapseAnimation ],
})
export class ShopItemModuleWrapperComponent implements OnInit {

  @Input({required: true}) data: ISiModuleInputData | undefined;
  @Input() customModuleTitleFC: ICustomModuleTitleFC | undefined;

  @Output() onModuleClick = new EventEmitter<void>();
  @Output() onActionsClick = new EventEmitter<void>();

  state: IShopItemAdminState = this.store.state;

  constructor(
    private store: ShopItemAdminStoreService,
    private shopItemService: ShopItemsService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
  }

  onModule() {
    this.onModuleClick.emit();
    if (!this.data?.moduleDefinition) return;

    if (this.data.moduleDefinition.collapsible) {
      this.data.moduleDefinition.collapsed = !this.data.moduleDefinition.collapsed;
    }
  }

  onActionsSection(e: Event) {
    e.stopPropagation();
    this.onActionsClick.emit();
  }

  onDeteleModule(e: Event) {
    e.stopPropagation();
    const res = this.modalService.openConfirmDeleteModal({
      c: ConfirmDeleteModalComponent,
      title: 'admin.shop-item-modules.wrapper.confirm-detele-modal.title',
      text: 'admin.shop-item-modules.wrapper.confirm-detele-modal.text',
    })
    res.pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        if (!this.data?.moduleDefinition) return;
        if (this.data.onDeteleModule) this.data.onDeteleModule();
      }
    })
  }

  onCloneModule(e: Event) {
    e.stopPropagation();
    const moduleType = this.data?.moduleData?.type;
    const shopItem = this.state.shopItem$.getValue();
    if (!moduleType || !shopItem) return;

    this.modalService.openCloneShopItemModuleModal({
      c: CloneShopItemSettingsModuleModalComponent,
      data: {
        moduleType,
        moduleUuid: this.data!.moduleData!.uuid,
        orgId: shopItem.organizationId,
        shopItemId: shopItem.id
      }
    }).pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        if (this.data?.onCloneModule) this.data.onCloneModule(res.shopItemIds, res.upsert);
      }
    });
  }
}
