import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_ORDER_BY = {
  type: EventShopItemFilterItemType.ORDER_BY
  value: SelectItem | null;
}

export enum EventShopItemOrderByType {
  FROM_NOW_TO_FUTURE = 'FROM_NOW_TO_FUTURE',
  BY_START_ASC = 'BY_START_ASC',
  BY_START_DESC = 'BY_START_DESC',
  BY_CREATED_ASC = 'BY_CREATED_ASC',
  BY_CREATED_DESC = 'BY_CREATED_DESC',
  BY_TITLE_ASC = 'BY_TITLE_ASC',
  BY_TITLE_DESC = 'BY_TITLE_DESC',
  BY_ITEM_NUMBER_ASC = 'BY_ITEM_NUMBER_ASC',
  BY_ITEM_NUMBER_DESC = 'BY_ITEM_NUMBER_DESC',
  BY_UI_INDEX = 'BY_UI_INDEX',
}

@Component({
  selector: 'app-event-si-filter-order-by',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    DropdownModule, ChipModule,
  ],
  templateUrl: './event-si-filter-order-by.component.html',
  styleUrl: './event-si-filter-order-by.component.scss'
})
export class EventSiFilterOrderByComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_ORDER_BY | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_ORDER_BY>();

  FC = new FormControl<EventShopItemOrderByType>(EventShopItemOrderByType.BY_UI_INDEX, { nonNullable: true });

  options: SelectItem[] = [];

  subs: Subscription[] = [];

  constructor(
    private translate: TranslateService
  ) {
    this.subs.push(
      this.translate.onLangChange.subscribe(() => this.createOptions()),


    )
  }

  ngOnInit(): void {
    if (this.item) this.FC.setValue(this.item.value?.value ?? EventShopItemOrderByType.BY_UI_INDEX);

    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: { label: this.translate.instant(`EventShopOrderByType.${v}`), value: v } });
      })
    );

    this.createOptions();
  }

  private createOptions() {
    this.options = [
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.FROM_NOW_TO_FUTURE}`),
        value: EventShopItemOrderByType.FROM_NOW_TO_FUTURE
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_START_ASC}`),
        value: EventShopItemOrderByType.BY_START_ASC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_START_DESC}`),
        value: EventShopItemOrderByType.BY_START_DESC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_CREATED_ASC}`),
        value: EventShopItemOrderByType.BY_CREATED_ASC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_CREATED_DESC}`),
        value: EventShopItemOrderByType.BY_CREATED_DESC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_TITLE_ASC}`),
        value: EventShopItemOrderByType.BY_TITLE_ASC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_TITLE_DESC}`),
        value: EventShopItemOrderByType.BY_TITLE_DESC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_ITEM_NUMBER_ASC}`),
        value: EventShopItemOrderByType.BY_ITEM_NUMBER_ASC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_ITEM_NUMBER_DESC}`),
        value: EventShopItemOrderByType.BY_ITEM_NUMBER_DESC
      },
      {
        label: this.translate.instant(`EventShopOrderByType.${EventShopItemOrderByType.BY_UI_INDEX}`),
        value: EventShopItemOrderByType.BY_UI_INDEX
      }
    ]
  }



  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }
  
}
