import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ICategory } from 'src/app/shared/models/category/category.model';
import { ICategoryGroup } from 'src/app/shared/models/category/category-group.model';

interface ICategoriesGetForSiCategoriesModalData {
  shopItemId: number;
}
interface ICategoriesGetForSiCategoriesModalDataResult {
  shopItem: IShopItem & { categories: ICategory[], sessionGroup: any };
  orgCategoriesWithNullGroup: (ICategory & { categoryGroup: null })[];
  orgCategoryGroups: (ICategoryGroup & { categories: ICategory[] })[];
}

export interface ICategoriesCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
  code: string | null;
  categoryGroupId: number | null;
  shopItemId: number | null;
};

export interface ICategoriesUpdateData {
  categoryId: number;

  title: string;
  shortDescription: string | null;
  code: string | null;
  categoryGroupId: number | null;
};

interface ICategoryGroupsGetAllByOrgData {
  orgId: number;
}

export interface ICategoryGroupCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
};
export interface ICategoryGroupUpdateData {
  categoryGroupId: number;
  
  title: string;
  shortDescription: string | null;
};

interface ICategoriesSetToShopItemData {
  shopItemId: number;

  categoryIds: number[];
};

interface ICategoriesArchiveData {
  categoryId: number;
};

interface ICallablesCategoriesGetForFilterItemData {
  orgId: number;
}
type ICallablesCategoriesGetForFilterItemResult = {
  id: number;
  title: string;
  categoryGroup: {
    id: number;
    title: string;
  } | null;
}[];

interface ICategoriesArchiveData {
  categoryId: number;
};

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private dbService: DbService
  ) { }

  public unarchive(data: ICategoriesArchiveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesUnarchive, data });
    return this.dbService.handleObs(obs);
  }

  public getForFilterItem(data: ICallablesCategoriesGetForFilterItemData): Observable<ICallablesCategoriesGetForFilterItemResult>{
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesGetForFilterItem, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
  
  public archiveCategory(data: ICategoriesArchiveData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesArchive, data });
    return this.dbService.handleObs(obs);
  }

  public setToShopItem(data: ICategoriesSetToShopItemData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesSetToShopItem, data });
    return this.dbService.handleObs(obs);
  }

  public updateCategoryGroup(data: ICategoryGroupUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoryGroupUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createCategoryGroup(data: ICategoryGroupCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoryGroupCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getAllOrgCategoryGroups(data: ICategoryGroupsGetAllByOrgData): Observable<ICategoryGroup[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoryGroupsGetAllByOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateCategory(data: ICategoriesUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createCategory(data: ICategoriesCreateData): Observable<ICategory> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getForSiCategoriesModal(data: ICategoriesGetForSiCategoriesModalData): Observable<ICategoriesGetForSiCategoriesModalDataResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCategoriesGetForSiCategoriesModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

}
