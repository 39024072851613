<app-modal-header
  [highlighted]="true"
  (close)="close()"
>
  <span slot="title">
    {{ 'admin.clone-settings-module-modal.title' | translate }}
  </span>
</app-modal-header>

<div class="modal-body flex flex-column row-gap-2">
  <span>{{ 'admin.clone-settings-module-modal.subtitle' | translate }}</span>
  <app-shop-items-picker
    [statesForm]="statesForm"
    [itemsForm]="itemsForm"
    [(selectedItems)]="selectedItems"
    [initValues]="initValues"
    [eventUuid]="null"
    [showLabels]="false"
    [getDisabledShopItemsMethod]="getDisabledShopItems"
    [includeOnDemand]="false"
    [onlyRepresentatives]="false"
  />

  <p-checkbox 
    [(ngModel)]="upsert" 
    [binary]="true" 
    inputId="binary"
    [label]="'admin.clone-settings-module-modal.upsert.label' | translate" />

  <span class="text-red-500">
    @if (selectedItems.length) {
      {{ 'admin.clone-settings-module-modal.attention.label' | translate:{ itemsCnt: selectedItems.length } }}
    } @else {
      {{ 'admin.clone-settings-module-modal.no-items-selected.label' | translate }}
    }
  </span>

</div>

<div class="modal-footer justify-content-between">

  <button (click)="close()" pButton label="{{'admin.clone-settings-module-modal.close-btn.label' | translate }}" class="p-button-text p-button-plain"></button>
  <button (click)="onSubmit()" [disabled]="!selectedItems.length" pButton label="{{'admin.clone-settings-module-modal.submit-btn.label' | translate }}" class="p-button-info"></button>

</div>
