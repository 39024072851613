



@if (reservation; as r) {
  @if (r.entityType === ReservationTableEntityType.RESERVATION) {
    <div class="row">
      @if (!r.order && r.state === ReservationState.APPROVED) {
        <span (click)="onWaitingForOrder.emit(r)" class="underline cursor-pointer">
          {{ 'admin.reservations.table.cell.order.waiting-for-order.label' | translate }}
        </span>
      }
      @if (r.order) {
        <span>
          {{ 'admin.reservations.table.cell.order.ordered.label' | translate }}
        </span>
      }
    </div>
  
    <div class="row">
      <span class="text-xs">
        @if (r.order) {
          @if (r.order.userNotes.length) {
            <i class="pi pi-comment text-yellow-500 mr-2" [ngbTooltip]="orgUserNotesEl"></i>
          }
          <span (click)="onOrder.emit(r.order)" class="underline cursor-pointer">#{{ r.order.id }}{{ r.order.randomNumber }}</span>
        }
      </span>
    </div>

    <ng-template #orgUserNotesEl>
      <div class="text-left">
        @for (note of r?.order?.userNotes; track note) {
          <p class="m-0 mb-2">{{ note.text }}</p>
        }
      </div>
    </ng-template>
  }
}
  
