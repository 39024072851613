import { Injectable } from '@angular/core';
import { IOrderFE } from '../../models/order/order-model';

@Injectable({
  providedIn: 'root'
})
export class InvoicesHelperService {

  constructor() { }

  getShowInvoiceBtn(o: IOrderFE | undefined | null): boolean {
    if (!o) return false;

    if (o.invoices.length) return true;

    return false;
  }

  /** Don't show IBAN if the invoice is from the same country as the payer and they are both Czech or Slovak */
  getShowIban(orgCountryCode: string, payerCountryCode: string | null): boolean {
    if (!payerCountryCode) return true;
    const bankAccountNumberCountries = ['CZ', 'SK'];
    return !(
      payerCountryCode === orgCountryCode
      && bankAccountNumberCountries.includes(payerCountryCode || '')
      && bankAccountNumberCountries.includes(orgCountryCode || '')
    )
  }

  getShowBankAccountNumber(orgCountryCode: string, payerCountryCode: string | null): boolean {
    if (!payerCountryCode) return true;
    const bankAccountNumberCountries = ['CZ', 'SK'];
    return (
      payerCountryCode === orgCountryCode
      && bankAccountNumberCountries.includes(payerCountryCode || '')
      && bankAccountNumberCountries.includes(orgCountryCode || '')
    );
  }

  getShowVariableSymbol(payerCountryCode: string) {
    const variableSymbolCountries = ['CZ', 'SK'];
    return variableSymbolCountries.includes(payerCountryCode);
  }
}
