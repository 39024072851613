import { InvoiceNumberSeriesFormat } from "../../enums/invoice/number-series-format.enum";
import { Currency } from "../../enums/price/currencies.enum";
import { IAddress } from "../../modals/address-form-group-modal/address-form-group-modal.component";

export interface IInvoiceProfileNumberSeries {
  numberSeriesPrefix: string;
  numberSeriesFormat: InvoiceNumberSeriesFormat;
  numberSeriesStartValue: string;  
}

export enum NumberSeriesType {
  INVOICE = 'INVOICE',
  PROFORMA = 'PROFORMA',
  TAX_RECEIPT = 'TAX_RECEIPT'
};

export type InvoiceProfileNumberSeriesMap = {
  [key in NumberSeriesType]: IInvoiceProfileNumberSeries;
}

export interface IInvoiceProfile {
  id: number;
  legalName: string;
  address: string;
  country: string;
  countryCode: string;
  addressStructured: IAddress;
  domesticCurrency: Currency;
  companyNumber: string; // IČO
  vatNumber: string | null; // DIČ
  vatPayer: boolean;
  invoiceFooterText: string | null;
  numberSeries: InvoiceProfileNumberSeriesMap;
  // Internal
  title: string;
  note: string | null;
}
