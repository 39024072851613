@if (form) {
  <app-modal-header
    [highlighted]="true"
    (close)="close()">
    <span slot="breadcrumb">
      {{ 'admin.extras-form-field-modal.breadcrumb' | translate }}
    </span>
    <span slot="title">
      <app-input-textarea-subtle [FC]="form.controls.label" [autoFocus]="true" [variant]="'light'" [placeholder]="'admin.extras-form-field-modal.breadcrumb' | translate"></app-input-textarea-subtle>
    </span>
  </app-modal-header>

  <div class="modal-body">
    <div class="col-12">
      <small class="text-bluegray-400">{{ 'admin.extras-form-field-modal.description.label' | translate }}</small>
      <app-editor-wrap-component
        #ed
        [FC]="form.controls.description"
        [placeholder]="'admin.extras-form-field-modal.description.placeholder' | translate"
        [overlay]="descriptionsShortCodesOverlay.overlay">

        <ng-container slot="formats">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>
          </span>
        </ng-container>

      </app-editor-wrap-component>
    </div>

    <div class="col-12">
      <small class="text-bluegray-400">{{ 'admin.extras-form-field-modal.left-side-description.label' | translate }}</small>
      <app-editor-wrap-component
        #led
        [FC]="form.controls.leftSideDescription"
        [placeholder]="'admin.extras-form-field-modal.left-side-description.placeholder' | translate"
        [overlay]="leftDescriptionsShortCodesOverlay.overlay">

        <ng-container slot="formats">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>
          </span>
        </ng-container>

      </app-editor-wrap-component>
    </div>

    <div class="col-12 flex flex-column">
      <small class="text-bluegray-400">{{ 'admin.extras-form-field-modal.extras-shopitem.label' | translate }}</small>
      <label (click)="onChangeShopItem()" class="flex surface-border border-1 p-3 m-0 border-round flex-1 cursor-pointer justify-content-between align-items-center">
        <div class="flex flex-column row-gap-2">
          @if (form.controls.info.value; as pickedShopItemInfo) {
            <h5 class="m-0">{{ pickedShopItemInfo.title }}</h5>
            <app-shop-item-details-info
              [locationUseInternalTitle]="true"
              [detailsInfo]="pickedShopItemInfo"
              [showParts]="[ShopItemDetailsPart.DATE, ShopItemDetailsPart.TIME, ShopItemDetailsPart.LOCATION, ShopItemDetailsPart.CUSTOM_NUMBER]">
            </app-shop-item-details-info>
          } @else {
            <h5 class="m-0">{{ 'admin.extras-form-field-modal.no-shop-item-selected.label' | translate }}</h5>
          }
        </div>
        <span>
          <i class="pi pi-arrow-right-arrow-left"></i>
        </span>
      </label>
    </div>

    <div class="col-12 px-0 flex align-items-center">
      <span>
        {{ 'admin.form-field-settings-modal.extras-required-count.part-1' | translate }}
      </span>
      <p-inputNumber
        class="ml-1"
        [min]="0"
        [style]="{ width: '6ch' }"
        [inputStyle]="{ width: '6ch' }"
        appNumberInput
        [formControl]="form.controls.minCount"
      />
      <span>
        {{ 'admin.form-field-settings-modal.extras-required-count.part-2' | translate }}
      </span>
      <p-inputNumber
        class="mx-1"
        [min]="1"
        [max]="form.controls.info.value?.maxReservationCount || undefined"
        [style]="{ width: '6ch' }"
        [inputStyle]="{ width: '6ch' }"
        appNumberInput
        [formControl]="form.controls.maxCount"
      />
      <span>
        {{ 'admin.form-field-settings-modal.extras-required-count.part-3' | translate }}
      </span>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button
      (click)="close()"
      pButton
      label="{{ 'admin.extras-form-field-modal.close-btn' | translate }}"
      class="p-button-sm p-button-text p-button-plain">
    </button>

    <button
      (click)="onSubmit()"
      pButton
      label="{{ 'admin.extras-form-field-modal.submit-btn' | translate }}"
      class="p-button-sm">
    </button>
  </div>
}

<app-short-codes-overlay
  #descriptionsShortCodesOverlay
  [zIndex]="10000"
  [menuItems]="descriptionShortCodeMenuItems">
</app-short-codes-overlay>

<app-short-codes-overlay
  #leftDescriptionsShortCodesOverlay
  [zIndex]="10000"
  [menuItems]="leftDescriptionShortCodeMenuItems">
</app-short-codes-overlay>
