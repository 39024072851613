import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChipModule } from 'primeng/chip';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';

export interface IFilterItemChip {
  key?: string;
  label: string | undefined;
  value: any;
}

@Component({
  selector: 'app-filter-item-chips',
  standalone: true,
  imports: [
    ChipModule, TranslateModule,

    TruncatePipe
  ],
  templateUrl: './filter-item-chips.component.html',
  styleUrl: './filter-item-chips.component.scss'
})
export class FilterItemChipsComponent {

  @Input({required: true}) chips: IFilterItemChip[] = [];
  @Input({required: true}) emptyMessage: string | null = null;
  @Output() remove = new EventEmitter<IFilterItemChip>();

  onRemove(chip: IFilterItemChip) {
    this.remove.emit(chip);
  }
}
