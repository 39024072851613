import { Component, Input, ViewChild } from '@angular/core';
import { IShortCodeMenuItem, ShortCodesMenuComponent } from '../short-codes-menu.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-short-codes-overlay',
  standalone: true,
  imports: [

    OverlayPanelModule,
    ShortCodesMenuComponent,
  ],
  templateUrl: './short-codes-overlay.component.html',
  styleUrl: './short-codes-overlay.component.scss'
})
export class ShortCodesOverlayComponent {

  @Input() menuItems: IShortCodeMenuItem[] = [];
  @Input() zIndex: number = 0;

  @ViewChild('overlay', { static: true }) public overlay: OverlayPanel | undefined;
}
