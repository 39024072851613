import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from '../modal';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { AbstractControl, Form, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Subscription, debounceTime, finalize, firstValueFrom, of, pairwise, startWith, switchMap, take, tap } from 'rxjs';
import { MultiSelectModule } from 'primeng/multiselect';
import { UtilsService } from '../../services/utils.service';
import { IInvoiceProfile, InvoiceProfileNumberSeriesMap, NumberSeriesType } from '../../models/invoice-profile/invoice-profile.model';
import { SelectItem } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InvoiceNumberSeriesFormat, InvoiceNumberSeriesFormatName } from '../../enums/invoice/number-series-format.enum';
import { Operation } from '../../enums/utils/operations.enum';
import { InvoiceProfileService } from '../../services/entities/invoice-profile/invoice-profile.service';
import { SelectedOrgService } from '../../services/selected-org.service';
import { CountryDropdownInputComponent } from '../../components/country-dropdown-input/country-dropdown-input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getAddressFG, IAddress, IAddressFG } from '../address-form-group-modal/address-form-group-modal.component';
import { CompanyInfoCountryCode, ICompanyInfoResult, OrganizationsService } from '../../services/entities/organizations/organizations.service';
import { Countries } from '../../enums/utils/countries';
import { CountryCode } from '../../enums/utils/country_codes.enum';

interface IInvoiceProfileFG {
  legalName: FormControl<string>;
  companyNumber: FormControl<string>;
  addressStructured: FormGroup<IAddressFG>;
  icoCountryCode: FormControl<string>;
  vatPayer: FormControl<boolean>;
  vatNumber: FormControl<string | null>;
  numberSeries: FormGroup<INumberSeriesFG>;
  invoiceFooterText: FormControl<string | null>;
  title: FormControl<string>;
  note: FormControl<string | null>;
}

@Component({
  selector: 'app-invoice-profile-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    ButtonModule, InputTextModule, InputNumberModule, DropdownModule, RadioButtonModule, MultiSelectModule, SelectButtonModule,
    ModalHeaderComponent, CountryDropdownInputComponent, CheckboxModule
  ],
  templateUrl: './invoice-profile-modal.component.html',
  styleUrls: ['./invoice-profile-modal.component.scss']
})
export class InvoiceModalModalComponent extends Modal implements OnInit, OnDestroy {
  InvoiceNumberSeriesFormat = InvoiceNumberSeriesFormat;
  NumberSeriesType = NumberSeriesType;

  @Input() invoiceProfile: IInvoiceProfile | null = null;
  @Input() operation: Operation = Operation.CREATE;
  @Output() result = new EventEmitter<{ invoiceProfile: IInvoiceProfile }>();

  subs: Subscription[] = [];

  form: FormGroup<IInvoiceProfileFG> = new FormGroup<IInvoiceProfileFG>({
    legalName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    companyNumber: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    addressStructured: getAddressFG({ address: { countryCode: 'CZ' }, required: true }),
    icoCountryCode: new FormControl<string>('CZ', { nonNullable: true }),
    vatPayer: new FormControl<boolean>(true, { nonNullable: true, validators: [Validators.required] }),
    vatNumber: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
    numberSeries: this.createNumberSeriesFG(),
    invoiceFooterText: new FormControl<string | null>(null, { nonNullable: true }),
    title: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    note: new FormControl<string | null>(null, { nonNullable: true }),
  });

  loading = false;
  saving = false;

  vatPayerOptions: SelectItem[] = [];
  numberSeriesFormatOptions: SelectItem[] = Array.from(InvoiceNumberSeriesFormatName).map(([key, value]) => ({
    value: key,
    label: value,
    disabled: key === InvoiceNumberSeriesFormat.ORDER_NUMBER
  }));

  numberSeriesTypeList = Object.keys(NumberSeriesType);

  icoCountryCodeOptions: SelectItem[] = [];

  icoCountryCodeStatus: 'DISABLED' | 'VALID' | 'INVALID' | 'PENDING' = 'VALID';

  checkingIco = false;
  icoCheck: ICompanyInfoResult | null = null;
  icoCheckSub: Subscription = Subscription.EMPTY;
  icoCountryCodeCheckSub: Subscription = Subscription.EMPTY;
  countryCodeSub: Subscription = Subscription.EMPTY;
  icoSub: Subscription = Subscription.EMPTY;

  constructor(
    private utilsService: UtilsService,
    private invoiceProfileService: InvoiceProfileService,
    private selectedOrgService: SelectedOrgService,
    private translate: TranslateService,
    private orgService: OrganizationsService
  ) {
    super();

    this.subs.push(
      this.translate.onLangChange.subscribe(() => { this.createVatPayerOptions(); })
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.createVatPayerOptions();

    this.subs.push(
      this.form.controls.vatPayer.valueChanges.subscribe((vatPayer) => {
        if (vatPayer) {
          this.form.controls.vatNumber.addValidators(Validators.required);
        } else {
          this.form.controls.vatNumber.removeValidators(Validators.required);
        }
        this.form.controls.vatNumber.updateValueAndValidity();
      })
    );

    if (this.invoiceProfile) {
      this.patchForm(this.invoiceProfile);
    } else {
      const organizationId = this.selectedOrgService.selectedOrgId$.getValue();
      if (organizationId) {
        this.loading = true;
        this.invoiceProfileService.getInvoiceProfile(organizationId).pipe(
          take(1),
          finalize(() => this.loading = false)
        ).subscribe({
          next: (invoiceProfile) => {
            if (invoiceProfile) {
              this.invoiceProfile = invoiceProfile;
              this.patchForm(invoiceProfile);
              this.result.emit({ invoiceProfile });
              this.operation = Operation.UPDATE;
            }
          }
        });
      }
    }

    this.watchIco();

    this.icoCheckSub = this.form.controls.companyNumber.valueChanges.pipe(
      debounceTime(300),
      switchMap(x => {
        this.checkingIco = true;
        if (this.form.controls.companyNumber.invalid) return of(null);
        const countryCode = this.form.controls.icoCountryCode.value;
        if (!countryCode) return of(null);
        if (!(countryCode in CompanyInfoCountryCode)) return of(null);

        return firstValueFrom(this.orgService.getCompanyInfo({ ico: x, countryCode: countryCode as CompanyInfoCountryCode }));
      })
    ).subscribe({
      next: async (resPromise) => {
        const res = await resPromise;
        if (res) {
          this.icoCheck = res;
          this.prefillFormFromIcoCheck();
        } else {
          this.icoCheck = null;
        }
        this.checkingIco = false;
      },
      error: (err) => {
        this.checkingIco = false;
      }
    });
    this.icoCountryCodeCheckSub = this.form.controls.icoCountryCode.valueChanges.pipe(startWith(CompanyInfoCountryCode.CZ), pairwise()).subscribe((countryCodes) => {
      const oldCountryCode = countryCodes[0];
      const newCountryCode = countryCodes[1];
      if (oldCountryCode !== newCountryCode) {
        this.form.controls.companyNumber.setValue(this.form.controls.companyNumber.value);
        this.form.controls.addressStructured.controls.countryCode.setValue(newCountryCode);
        this.form.controls.addressStructured.controls.country.setValue(this.translate.instant(`country.${newCountryCode.toLowerCase()}`));
      }
    });

    this.createIcoCountryCodeOptions();
  }

  private createIcoCountryCodeOptions() {
    this.icoCountryCodeOptions = Countries.map((country) => {
      if (!(country.code in CountryCode)) return null;
      return {
        value: country.code,
        icon: country.emoji,
        label: this.translate.instant(`country.${country.code.toLowerCase()}`)
      }
    }).filter((x) => x) as SelectItem[];
  }

  private watchIco() {
    this.subs.push(this.form.controls.companyNumber.statusChanges.subscribe((status) => {
      this.icoCountryCodeStatus = status;
    }) || Subscription.EMPTY);
  }

  private prefillFormFromIcoCheck(): void {
    if (this.icoCheck?.ok) {
      if (this.icoCheck.companyInfo?.name) this.form.controls.legalName.setValue(this.icoCheck.companyInfo?.name);
      if (this.icoCheck.companyInfo?.address) {
        if (this.icoCheck.companyInfo.address.buildingNumber) this.form.controls.addressStructured.controls.buildingNumber.setValue(this.icoCheck.companyInfo.address.buildingNumber);
        if (this.icoCheck.companyInfo.address.city) this.form.controls.addressStructured.controls.city.setValue(this.icoCheck.companyInfo.address.city);
        if (this.icoCheck.companyInfo.address.street) this.form.controls.addressStructured.controls.street.setValue(this.icoCheck.companyInfo.address.street);
        if (this.icoCheck.companyInfo.address.zip) this.form.controls.addressStructured.controls.zip.setValue(this.icoCheck.companyInfo.address.zip);
        if (this.icoCheck.companyInfo.address.countryCode) this.form.controls.addressStructured.controls.country.setValue(this.translate.instant(`country.${this.icoCheck.companyInfo.address.countryCode.toLowerCase()}`));
        if (this.icoCheck.companyInfo.address.countryCode) this.form.controls.addressStructured.controls.countryCode.setValue(this.icoCheck.companyInfo.address.countryCode);
      }
      if (this.icoCheck.companyInfo?.vatNumber) this.form.controls.vatNumber.setValue(this.icoCheck.companyInfo?.vatNumber);
      if (this.icoCheck.companyInfo?.vatPayer) this.form.controls.vatPayer.setValue(this.icoCheck.companyInfo?.vatPayer);
    }
  }

  onIcoCountryCodeChange(icoInput: HTMLInputElement) {
    setTimeout(() => icoInput.focus(), 1);
  }

  createNumberSeriesFG() {
    const defaultValuesMap = new Map<NumberSeriesType, string>([
      [NumberSeriesType.INVOICE, 'FA-'],
      [NumberSeriesType.PROFORMA, 'PF-'],
      [NumberSeriesType.TAX_RECEIPT, 'DDP-']
    ]);
    const FG = new FormGroup<INumberSeriesFG>({}, { validators: [prefixValidator] });
    for (const numberSeriesType of Object.keys(NumberSeriesType)) {
      const numberSeriesFormatFC = new FormControl({ value: InvoiceNumberSeriesFormat.YYYY, disabled: numberSeriesType !== NumberSeriesType.INVOICE }, { nonNullable: true, validators: [Validators.required] });
      if (numberSeriesType === NumberSeriesType.INVOICE) {
        this.subs.push(numberSeriesFormatFC.valueChanges.subscribe((numberSeriesFormat) => {
          Object.values(FG.controls).forEach((control) => {
            control.controls.numberSeriesFormat.setValue(numberSeriesFormat, { emitEvent: false });
          });
        }));
      }
      FG.addControl(numberSeriesType, new FormGroup<IInvoiceProfileNumberSeriesFG>({
        numberSeriesFormat: numberSeriesFormatFC,
        numberSeriesPrefix: new FormControl(defaultValuesMap.get(numberSeriesType as NumberSeriesType) || '', { nonNullable: true, validators: [Validators.required] }),
        numberSeriesStartValue: new FormControl({ value: 1, disabled: true }, { nonNullable: true, validators: [Validators.required] })
      }));
    };
    return FG;
  }

  patchForm(invoiceProfile: IInvoiceProfile) {
    this.form.patchValue({
      addressStructured: invoiceProfile.addressStructured,
      icoCountryCode: invoiceProfile.addressStructured.countryCode || 'CZ',
      companyNumber: invoiceProfile.companyNumber,
      invoiceFooterText: invoiceProfile.invoiceFooterText,
      legalName: invoiceProfile.legalName,
      note: invoiceProfile.note,
      title: invoiceProfile.title,
      vatNumber: invoiceProfile.vatNumber,
      vatPayer: invoiceProfile.vatPayer,
      numberSeries: JSON.parse(JSON.stringify(invoiceProfile.numberSeries))
      // numberSeriesStartValue: invoiceProfile.numberSeriesStartValue // only use default behaviour - '0001' (for now)
    });
  }

  watchForm() {
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      console.error('invalid');
      return;
    }

    const organizationId = this.selectedOrgService.selectedOrgId$.getValue();
    if (!organizationId) {
      console.error('organizationId not set!');
      return;
    }

    this.saving = true;
    const formVals = this.form.getRawValue();

    if (this.operation === Operation.CREATE) {
      this.invoiceProfileService.createInvoiceProfile({
        ...formVals,
        organizationId,
        numberSeries: JSON.parse(JSON.stringify(formVals.numberSeries)) as InvoiceProfileNumberSeriesMap,
        address: this.getAddressString(formVals.addressStructured),
        country: formVals.addressStructured.country,
        countryCode: formVals.addressStructured.countryCode
      }).pipe(
        finalize(() => this.saving = false)
      ).subscribe({
        next: (res) => {
          this.result.emit({ invoiceProfile: res });
          this.close();
        }
      });
    }
    if (this.operation === Operation.UPDATE) {
      this.invoiceProfileService.updateInvoiceProfile({
        ...formVals,
        organizationId,
        numberSeries: JSON.parse(JSON.stringify(formVals.numberSeries)) as InvoiceProfileNumberSeriesMap,
        address: this.getAddressString(formVals.addressStructured),
        country: formVals.addressStructured.country,
        countryCode: formVals.addressStructured.countryCode
      }).pipe(
        finalize(() => this.saving = false)
      ).subscribe({
        next: (res) => {
          this.result.emit({ invoiceProfile: res });
          this.close();
        }
      });
    }
  }

  private getAddressString(address: IAddress) {
    return [
      `${address.street || ''} ${address.buildingNumber || ''}`,
      `${address.zip || ''} ${address.city || ''}`
    ].map((x) => x.trim()).filter((x) => x).join(', ');
  }

  private createVatPayerOptions() {
    this.vatPayerOptions = [
      {
        value: true,
        label: this.translate.instant('admin.invoice-profile-modal.vat-payer'),
        styleClass: 'text-xs'
      },
      {
        value: false,
        label: this.translate.instant('admin.invoice-profile-modal.not-vat-payer'),
        styleClass: 'text-xs'
      }
    ];
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
    this.subs.forEach(s => s.unsubscribe());
  }
}

interface INumberSeriesFG {
  [key: string]: FormGroup<IInvoiceProfileNumberSeriesFG>;
}

interface IInvoiceProfileNumberSeriesFG {
  numberSeriesPrefix: FormControl<string>;
  numberSeriesFormat: FormControl<InvoiceNumberSeriesFormat>;
  numberSeriesStartValue: FormControl<number>;
}

export function prefixValidator(control: AbstractControl) {
  const FG = control as FormGroup<INumberSeriesFG>;
  const prefixMap = Object.keys(FG.controls).reduce((prev, curr) => ({ ...prev, [curr]: '' }), {}) as { [key: string]: string };
  Object.entries(FG.controls).forEach(([key, value]) => {
    prefixMap[key] = value.controls.numberSeriesPrefix.value;
  });
  const prefixes = Object.values(prefixMap);
  Object.entries(prefixMap).forEach(([key, value]) => {
    const cnt = prefixes.filter((prefix) => prefix === value).length;
    if (cnt > 1) {
      FG.controls[key].controls.numberSeriesPrefix.setErrors({ notUnique: true });
    } else {
      FG.controls[key].controls.numberSeriesPrefix.setErrors(null);
    }
  });

  return null;
}
