import { AfterViewInit, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Editor, EditorInitEvent, EditorSelectionChangeEvent, EditorTextChangeEvent } from 'primeng/editor';
import { OverlayPanel } from 'primeng/overlaypanel';

import Quill, { DeltaOperation } from 'quill';
import { Subscription, take } from 'rxjs';

const Inline = Quill.import('blots/inline');
class AppendToBlot extends Inline {
  static create(value: any) {
    let node = super.create();
    node.setAttribute('id', value.id);
    node.innerText = value.text;
    return node;
  }
  static formats(node: any) {
    return { id: node.getAttribute('id') };
  }
}
export const EDITOR_OVERLAY_APPEND_TO_BLOT_NAME = 'append-to-blot';
export const EDITOR_OVERLAY_APPEND_TO_TAG_NAME = 'atb';
AppendToBlot['blotName'] = EDITOR_OVERLAY_APPEND_TO_BLOT_NAME;
AppendToBlot['tagName'] = EDITOR_OVERLAY_APPEND_TO_TAG_NAME;
Quill.register(AppendToBlot);


export const EDITOR_OVERLAY_TRIGGERING_CHAR = '/';

@Directive({
  selector: '[appEditorOverlay]',
  standalone: true
})
export class EditorOverlayDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input({required: true, alias: 'appEditorOverlay'}) overlay: OverlayPanel | undefined;

  get appendTo() { return document.getElementById(this.APPEND_TO_ID) ?? undefined; };

  
  APPEND_TO_ID = 'lktr-editor-overlay-append-to';

  editor: Quill | null = null;
  fallbackIndex: number = 0;

  subs: Subscription[] = [];

  constructor(
    private host: Editor
  ) { }

  ngOnInit(): void {
    this.subs.push(
      this.host.onInit.subscribe((e: EditorInitEvent) => {
        this.editor = this.host.quill;
      }),
      this.host.onTextChange.subscribe((e: EditorTextChangeEvent) => {
        this.onEditorTextChange(e);
      }),
      this.host.onSelectionChange.subscribe((e: EditorSelectionChangeEvent) => {
        if (e.range === null && e.oldRange) {
          const oldRange = e.oldRange as unknown as { index: number, length: number };
          this.fallbackIndex = oldRange.index;
        }
      }),
    )
  }
  ngAfterViewInit(): void {
  }

  private getFallbackIndex() {
    this.editor?.setSelection(this.fallbackIndex, 0, 'user');
    return this.fallbackIndex
  }

  editorInsertText(text: string) {
    if (!this.editor ) return;

    let range = this.editor.getSelection();
    const index = range?.index ?? this.getFallbackIndex();
    const lastChar = this.editor.getText(index - 1, index) as string;

    if (lastChar.charCodeAt(0) === EDITOR_OVERLAY_TRIGGERING_CHAR.charCodeAt(0)) {
      this.editor.insertText(index, text, 'user');
      this.editor.deleteText(index -1, 1, 'user');
      setTimeout(() => {
        this.editor?.setSelection( index -1 + text.length - 1, 0 , 'user');
      });
    }
  }
  onEditorTextChange(e: EditorTextChangeEvent) {
    if (!this.editor) return;

    const delta = (e.delta as unknown as any).ops as DeltaOperation[]; // primeng ma spatne definovane delta :')
    if (delta.some(x => x.insert === EDITOR_OVERLAY_TRIGGERING_CHAR)) {

      const range = this.editor.getSelection();
      const index = range?.index ?? this.getFallbackIndex();

      this.editor.insertEmbed(index - 1, EDITOR_OVERLAY_APPEND_TO_BLOT_NAME, { id: this.APPEND_TO_ID, text: '\u200B' }, Quill.sources.SILENT);
      


      this.overlay?.show(new Event('click'), this.appendTo);
      if (this.overlay) this.overlay.hideTransitionOptions = '0ms';
      this.overlay?.onHide.pipe(take(1)).subscribe(() => {

        if (!this.editor) return;

        const contentsWithSpan = this.editor.getContents().ops;
        const contentsWithoutSpan = contentsWithSpan.filter((x: any) => x?.attributes?.[EDITOR_OVERLAY_APPEND_TO_BLOT_NAME]?.id !== this.APPEND_TO_ID);
        if (contentsWithoutSpan.length !== contentsWithSpan.length) {

          this.editor.setContents(contentsWithoutSpan as any, 'user');
          
          this.editor.setSelection(index, 0, 'user');
        }
      })
    } else {
      this.overlay?.hide();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

}
