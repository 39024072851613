

<div class="field">
  <ng-container *ngIf="!invoiceProfileFC?.value">
    <label
      class="surface-border border-1 p-3 flex align-items-center gap-3 cursor-pointer border-round-top border-round-bottom"
      style="background-color: var(--surface-0);"
      (click)="onChangeInvoiceProfile(Operation.CREATE)"
    >
      <div>
        <div class="font-bold mb-1">{{ 'admin.invoice-profile-input.no-invoice-profile.title' | translate }}</div>
        <span class="text-sm">
          {{ 'admin.invoice-profile-input.no-invoice-profile.text' | translate }}
        </span>
      </div>
      <div style="display: flex; justify-content: flex-end; flex: auto;">
        <div style="display: flex; align-items: center; flex-direction: column;">
          <i class="pi pi-pencil text-400"></i>
          <span class="text-sm">
            {{ 'admin.invoice-profile-input.no-invoice-profile.set-label' | translate }}
          </span>
        </div>
      </div>
    </label>
  </ng-container>
  <ng-container *ngIf="invoiceProfileFC?.value">
    <label
      class="surface-border border-1 p-3 flex align-items-center gap-3 cursor-pointer border-round-top border-round-bottom"
      style="background-color: var(--surface-0);"
      (click)="onChangeInvoiceProfile(Operation.UPDATE)"
    >
      <div>
        <div class="font-bold mb-1">{{invoiceProfileFC?.value?.legalName}}</div>
        <div class="flex align-items-start">
          <span class="details-block-wrap">
            <span class="detail-block">
              {{ 'admin.invoice-profile-input.company-id-label' | translate }}: {{invoiceProfileFC?.value?.companyNumber}}
            </span>
            <span class="detail-block">
              {{invoiceProfileFC?.value?.vatPayer ? ('admin.invoice-profile-input.vat-payer-label' | translate) : ('admin.invoice-profile-input.not-vat-payer-label' | translate)}}
            </span>
            <span class="detail-block" *ngIf="invoiceProfileFC?.value?.vatNumber">
              {{ 'admin.invoice-profile-input.vat-id-label' | translate }}: {{invoiceProfileFC?.value?.vatNumber}}
            </span>
          </span>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; flex: auto;">
        <div style="display: flex; align-items: center; flex-direction: column;">
          <i class="pi pi-sort-alt rotate-90 text-400"></i>
          <span class="text-sm">
            {{ 'admin.invoice-profile-input.edit-btn.label' | translate }}
          </span>
        </div>
      </div>
    </label>
  </ng-container>

</div>
