

<input
  #input
  *ngIf="FC"
  [formControl]="FC"
  [placeholder]="placeholder ? placeholder : ''"
  [ngStyle]="{'width.px':inputWidth}"
  [type]="type"
  class="blank-input {{ inputClass }}" />

  <span class="hidden-measure" #spanElement>{{input?.nativeElement.placeholder}}</span>