<div class="filter-chip-wrap">
  @for (chip of chips; track chip; let i = $index) {
    @if (i === 0) {
      <p-chip
        [label]="(chip.label ?? '') | translate | truncate:20"
        [removable]="true"
        (onRemove)="$event.stopPropagation();onRemove($any(chip))">
      </p-chip>
    }
    @if (i === 1) {
      <p-chip
        [label]="'+' + (chips.length - 1)"
        [removable]="false"
        class="ml-1">
      </p-chip>
    }
  }
  @empty {
    @if (emptyMessage) {
      <p-chip [label]="emptyMessage | translate" [removable]="false"></p-chip>
    }
  }
</div>