<app-modal-header
  (close)="close()">
  <span slot="title">
    {{'admin.invoice-profile-modal.title' | translate }}    
  </span>
</app-modal-header>


<div [formGroup]="form" class="modal-body" [class.loading-box]="saving || loading">

  <div class="grid">

    <div class="col-12 field">
      <span class="p-float-label">
        <input id="legalName" formControlName="legalName" type="text" pInputText class="w-full">
        <label for="legalName">{{'admin.invoice-profile-modal.legalentityname' | translate }}</label>
      </span>
    </div>
    <div class="col-12 field">
      <div class="flex justify-content-between input-group">
        <span class="p-float-label flex w-full">
          <p-dropdown
            id="companyNumber"
            [class.ng-dirty]="icoCountryCodeStatus === 'INVALID'"
            [class.ng-invalid]="icoCountryCodeStatus === 'INVALID'"
            class="p-fluid dc-dropdown"
            formControlName="icoCountryCode"
            [options]="icoCountryCodeOptions"
            [filter]="true"
            filterBy="label"
            optionLabel="label"
            optionValue="value"
            (onChange)="onIcoCountryCodeChange(icoInput)"
          >
            <ng-template pTemplate="selectedItem" let-selectedItem>
              {{ selectedItem.icon }}
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex align-items-center gap-2">
                <span>{{ country.icon }}</span>
                <div>{{ country.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <div class="flex w-full">
            <span class="flex p-input-icon-right w-full no-left-border">
              <ng-container *ngIf="checkingIco">
                <i class="pi pi-spinner pi-spin"></i>
              </ng-container>
              <ng-container *ngIf="!checkingIco">
                <i *ngIf="icoCheck?.ok" class="pi pi-check" style="color: var(--l-success);"></i>
                <!-- <i *ngIf="icoCheck?.ok === false" class="pi pi-times" style="color: var(--l-danger);"></i> -->
              </ng-container>
              <input
                #icoInput
                [class.ng-dirty]="icoCountryCodeStatus === 'INVALID'"
                [class.ng-invalid]="icoCountryCodeStatus === 'INVALID'"
                class="p-fluid w-full"
                inputClass="flex"
                pInputText
                [formControl]="form.controls.companyNumber"
              >
            </span>
          </div>
          <label for="companyNumber">{{'admin.invoice-profile-modal.companyid' | translate }}</label>
        </span>
      </div>
      @if (form.controls.companyNumber?.hasError('invalidIco')) {
        <small id="invalid-ico-error" class="p-error block">{{'shopping-cart.new-order.billing-info.ico-invalid-error.label' | translate }}</small>
      }
    </div>

    <div class="col-12 py-0">
      <label>{{ 'admin.invoice-profile-modal.address' | translate }}</label>
    </div>
    <div class="field col-8">
      <span class="p-float-label">
        <input type="text" id="street" pInputText [formControl]="form.controls.addressStructured.controls.street" class="w-full">
        <label for="street">{{"admin.address-form-group-modal.street" | translate }}</label>
      </span>
    </div>

    <div class="field col-4">
      <span class="p-float-label">
        <input type="text" id="buildingNumber" pInputText [formControl]="form.controls.addressStructured.controls.buildingNumber" class="w-full">
        <label for="buildingNumber">{{"admin.address-form-group-modal.house-number" | translate }}</label>
      </span>
    </div>

    <div class="field col-12">
      <span class="p-float-label">
        <input type="text" id="city" pInputText [formControl]="form.controls.addressStructured.controls.city" class="w-full">
        <label for="city">{{"admin.address-form-group-modal.city" | translate }}</label>
      </span>
    </div>

    <div class="field col-12">
      <span class="p-float-label">
        <input type="text" id="zip" pInputText [formControl]="form.controls.addressStructured.controls.zip" class="w-full">
        <label for="zip">{{"admin.address-form-group-modal.zip" | translate }}</label>
      </span>
    </div>

    <div class="col-12 field">
      <app-country-dropdown-input [countryFC]="form.controls.addressStructured.controls.country" [countryCodeFC]="form.controls.addressStructured.controls.countryCode"></app-country-dropdown-input>
    </div>
    <div class="col-6 field">
      <span class="p-float-label">
        <p-selectButton
          [options]="vatPayerOptions"
          formControlName="vatPayer"
          optionLabel="label"
          optionValue="value">
        </p-selectButton>
      </span>
    </div>
    <div class="col-6 field">
      <span class="p-float-label">
        <input id="vatNumber" formControlName="vatNumber" type="text" pInputText class="w-full">
        <label for="vatNumber">{{'admin.invoice-profile-modal.vattaxid' | translate }}</label>
      </span>
    </div>
    @for (numberSeriesType of numberSeriesTypeList; track numberSeriesType) {
      @if (form.controls.numberSeries.get(numberSeriesType); as numberSeriesFG) {
        <div class="col-12">
          <label>{{ 'NumberSeriesType.' + numberSeriesType | translate }}</label>
        </div>
        <div class="col-12 p-0 field flex" [formGroup]="$any(numberSeriesFG)">
          <div class="col-2 field">
            <span class="p-float-label">
              <input
                id="numberSeriesPrefix"
                aria-describedby="numberSeriesPrefix-help"
                formControlName="numberSeriesPrefix"
                type="text"
                pInputText
                class="w-full h-full"
                styleClass="w-full h-full"
                inputStyleClass="w-full h-full">
              <label for="numberSeriesPrefix">{{'admin.invoice-profile-modal.prefix' | translate }}</label>
              @if ($any(numberSeriesFG).controls.numberSeriesPrefix.hasError('notUnique')) {
                <small id="numberSeriesPrefix-help" class="p-error block">{{'admin.invoice-profile-modal.prefix.unique-error' | translate }}</small>
              }
            </span>
          </div>
          <div class="col-5 field">
            <span class="p-float-label">
              <p-dropdown
                id="numberSeriesFormat"
                formControlName="numberSeriesFormat"
                [options]="numberSeriesFormatOptions"
                optionLabel="label"
                optionValue="value"
                optionDisabled="disabled"
                class="w-full"
                styleClass="w-full"
                inputStyleClass="w-full"></p-dropdown>
            </span>
          </div>
          <div class="col-2 field" *ngIf="$any(numberSeriesFG).controls.numberSeriesFormat.value !== InvoiceNumberSeriesFormat.ORDER_NUMBER">
            <span class="p-float-label">
              <p-inputNumber
                id="numberSeriesStartValue"
                formControlName="numberSeriesStartValue"
                prefix="000"
                class="w-full h-full"
                styleClass="w-full"
                inputStyleClass="w-full" />
            </span>
          </div>
        </div>
      }
    }
    <div class="col-12 field">
      <span class="p-float-label">
        <input id="invoiceFooterText" formControlName="invoiceFooterText" type="text" pInputText class="w-full">
        <label for="invoiceFooterText">{{'admin.invoice-profile-modal.invoiceFooterText' | translate }}</label>
      </span>
    </div>
    <label>{{'admin.invoice-profile-modal.profile.settings' | translate }}</label>
    <div class="col-12 field">
      <span class="p-float-label">
        <input id="title" formControlName="title" type="text" pInputText class="w-full">
        <label for="title">{{'admin.invoice-profile-modal.profile.title' | translate }}</label>
      </span>
    </div>
    <div class="col-12 field">
      <span class="p-float-label">
        <input id="note" formControlName="note" type="text" pInputText class="w-full">
        <label for="note">{{'admin.invoice-profile-modal.note' | translate }}</label>
      </span>
    </div>
  </div>
</div>


<div class="modal-footer">
  <button
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{'admin.invoice-profile-modal.save' | translate }}">
  </button>

</div>
