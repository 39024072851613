

<div style="white-space: pre;">
  @if (value !== undefined && ((value !== null) || (dataType === DataType.CONSENT))) {
    @switch(dataType) {
      @case (DataType.CHECKBOX) {
        {{ value | bool | translate }}
      }
      @case (DataType.CONSENT) {
        @switch(value) {
          @case (true) {
            @if (customParams?.dataType === DataType.CONSENT) {
              @if ($any(customParams).valid) {
                <div class="flex flex-column">
                  <span>{{ 'admin.reservation-table.custom-data.consent.given.label' | translate }}</span>
                  @if ($any(customParams).validUntil) {
                    <span class="table-second-row" style="color: var(--l-success) !important;">{{ $any(customParams).validUntil | localizedDate:'d.M.yyyy' }}</span>
                  }
                </div>
              } @else {
                <div class="flex flex-column">
                  <span>{{ 'admin.reservation-table.custom-data.consent.given-expired.label' | translate }}</span>
                  @if ($any(customParams).validUntil) {
                    <span class="table-second-row" style="color: var(--l-danger) !important;">{{ $any(customParams).validUntil | localizedDate:'d.M.yyyy' }}</span>
                  }
                </div>
              }
            }
          }
          @case (false) {
            <div class="flex flex-column">
              <span>{{ 'admin.reservation-table.custom-data.consent.not-given.label' | translate }}</span>
              <span class="table-second-row" style="color: var(--l-danger) !important;">{{ collectedAt | localizedDate:'d.M.yyyy' }}</span>
            </div>
          }
          @case (null) {
            <div class="flex flex-column">
              <span>{{ 'admin.reservation-table.custom-data.consent.not-available.label' | translate }}</span>
              <span class="table-second-row">-</span>
            </div>
          }
        }
      }
      @case (DataType.SCROLLING_TEXTAREA) {
        {{ value | bool | translate }}
      }
      @case (DataType.TRI_STATE_CHECKBOX) {
        {{ value | bool | translate }}
      }
      @case (DataType.DATE) {
        {{ value | localizedDate:'d.M.yyyy' }}
      }
      @case (DataType.BIRTHDATE) {
        <div class="flex flex-column">
          <span class="font-bold">{{ value | localizedDate:'d.M.yyyy' }}</span>
          @if (customParams?.dataType === DataType.BIRTHDATE) {
            <span class="table-second-row">{{ $any(customParams).ageInYears }} {{ $any(customParams).ageInYears | inflection:'utils.years-string' | translate }}</span>
          }
        </div>
      }
      @case (DataType.STRING) {
        {{ value }}
      }
      @case (DataType.NUMBER) {
        {{ value }}
      }
      @case (DataType.PHONE_NUMBER) {
        {{ value }}
      }
      @case (DataType.MULTISELECT) {
        {{ value }}
      }
      @case (DataType.BILLING_INFO) {
        <ng-template #htmlContent>
          <p-table [value]="[{}]" (mouseenter)="tableTooltipEnter(t)" (mouseleave)="tableTooltipLeave(t)" styleClass="p-datatable-gridlines" class="table-gridline">
            <ng-template pTemplate="body" let-item>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.name.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.name }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.company-number.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.ico }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.vat-number.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.vatNumber }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.vat-payer.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.vatPayer | bool | translate }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.street.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.street }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.building-number.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.buildingNumber }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.city.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.city }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.zip.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.zip }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.country-code.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.countryCode }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
        <div
          container="body"
          #t="ngbTooltip"
          [ngbTooltip]="htmlContent"
          [autoClose]="false"
          triggers="manual"
          (mouseenter)="tableTooltipEnter(t)"
          (mouseleave)="tableTooltipLeave(t)"
          class="flex flex-column"
          tooltipClass="transparent-tooltip"
        >
          <span>{{ value.name }}</span>
          <span class="table-second-row">{{ 'admin.reservation-table.billing-info.company-number.label' | translate:{ companyNumber: value.ico } }}</span>
        </div>
      }
      @case (DataType.CONTACT_ADDRESS) {
        <ng-template #htmlContent>
          <p-table [value]="[{}]" (mouseenter)="tableTooltipEnter(t)" (mouseleave)="tableTooltipLeave(t)" styleClass="p-datatable-gridlines" class="table-gridline">
            <ng-template pTemplate="body" let-item>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.name.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.name }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.street.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.street }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.building-number.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.buildingNumber }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.city.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.city }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.zip.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.zip }}
                </td>
              </tr>
              <tr>
                <td class="table-col-min-width left-cell">
                  {{ 'admin.reservation-table.billing-info.table.address.country-code.label' | translate }}
                </td>
                <td class="table-col-min-width">
                  {{ value.address.countryCode }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
        <div
          container="body"
          #t="ngbTooltip"
          [ngbTooltip]="htmlContent"
          [autoClose]="false"
          triggers="manual"
          (mouseenter)="tableTooltipEnter(t)"
          (mouseleave)="tableTooltipLeave(t)"
          class="flex flex-column"
          tooltipClass="transparent-tooltip"
        >
          <span>{{ [value.address.street, value.address.buildingNumber] | join:', ' }}</span>
          <span class="table-second-row">{{ [value.address.zip, value.address.city, value.address.countryCode] | join:', ' }}</span>
        </div>
      }
      @case (DataType.CUSTOM_TAGS) {
        {{ value }}
      }
      @case (DataType.TEXT) {
        {{ value }}
      }
      @case (DataType.HTML_TEXT) {
        <div [innerHTML]="value">
        </div>
      }
      @case (DataType.BANK_ACCOUNT) {
        {{ value }}
      }
      @case (DataType.LINK) {
        {{ value }}
      }
      @case (DataType.SPECIALIZATION) {
        {{ value }}
      }
      @case (DataType.TITLES) {
        {{ value.titlesBefore }} {{ value.titlesAfter }}
      }
      @default {
        {{ value }}
      }
    }
  } @else {
  }
</div>
