


<section *ngIf="formSection" class="section-wrap">

  <div (click)="onFormSection()" class="flex justify-content-between align-items-center" [class.cursor-pointer]="formSection.collapsible !== false">
    <div class="flex-1">
      <h5 class="m-0">
        {{ formSection.title | translate }}
      </h5>

      <div class="text-bluegray-400">
        <ng-content select="[slot=subTitleContent]"></ng-content>
      </div>
    </div>

    <div (click)="onActionsSection($event)" class="flex gap-2 align-items-center actions">

      <ng-content select="[slot=moreActions]"></ng-content>

      <ng-container *ngIf="formSection.control">
        <button
          *ngIf="formSection.unset && formSection.collapsed && !formSection.control.dirty"
          (click)="onFormSection()"
          pButton
          label="{{ 'admin.collapsible-form-section.set-btn.label' | translate }}"
          class="p-button-outlined p-button-rounded">
        </button>

        <button
          *ngIf="formSection.control.dirty && !formSection.saving"
          (click)="onSaveFormSection($event)"
          pButton
          label="{{ 'admin.collapsible-form-section.save-btn.label' | translate }}"
          class="p-button-rounded">
        </button>

        <div *ngIf="formSection.saving" class="p-2 flex align-items-center justify-content-center border-circle bg-primary">
          <i class="pi pi-spin pi-spinner text-white"></i>
        </div>

        <div *ngIf="(formSection.saved || !formSection.unset) && !formSection.warning && !formSection.control.dirty" class="p-2 flex align-items-center justify-content-center border-circle" style="background: var(--l-success);">
          <i class="pi pi-check text-white"></i>
        </div>

      </ng-container>


      <div *ngIf="formSection.warning" class="p-2 flex align-items-center justify-content-center border-circle" style="background: var(--l-warn);">
        <i class="pi pi-exclamation-triangle text-white"></i>
      </div>

    </div>

  </div>

  <div
    [@collapse]="formSection.collapsed"
    [class.overflow-hidden]="formSection.collapsed"><!-- ten overflow je proste blbej no .. bez nej je to blby a s nim taky .. kvuli tomu pak se nezobrazuje dropdown .... chtelo by to tu classu prirazovat pouze pokud je animace hotova -->

    <div class="pt-3">
      <ng-content select="[slot=body]">

      </ng-content>
    </div>

  </div>

</section>
