

<div class="flex justify-content-between column-gap-1 flex-column md:flex-row">

  @if (searchFC) {
    <span (click)="searchEl.input?.nativeElement?.focus()" class="filter-wrap flex align-items-center column-gap-2 text-bluegray-300">
      <i class="pi pi-search text-xl"></i>
      <app-blank-input #searchEl [FC]="searchFC" [autofocus]="sysService.deviceType$.value === DeviceType.DESKTOP" [placeholder]="'admin.event-shop-items-filter.search-input.placeholder' | translate"></app-blank-input>
    </span>
  }

  

  <div class="flex flex-1 align-items-center justify-content-between column-gap-2">

    
    @if (!hideFilters) {

      <div class="flex align-items-center flex-wrap row-gap-1 column-gap-1">

        <!-- important to track by index bcs if its tracked by filterItem, each time userFilterPreferences$ is updated, the contents are re-rendered (every time ngOnInit ) which causes overlay from for example multiselect to automatically close -->
        @for (filterItem of userFilterPreferences$ | async; track $index) {
          @switch (filterItem.type) {
            @case (EventShopItemFilterItemType.CATEGORY) {
              <app-event-si-filter-item-category
                [orgId]="event?.organizationId"
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-category>
            }
        
            @case (EventShopItemFilterItemType.COORDINATOR) {
              <app-event-si-filter-item-coordinator
                [orgId]="event?.organizationId"
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-coordinator>
            }
        
            @case (EventShopItemFilterItemType.DATE_RANGE) {
              <app-event-si-filter-item-date-range
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-date-range>
            }
        
            @case (EventShopItemFilterItemType.LOCATION) {
              <app-event-si-filter-item-location
                [orgId]="event?.organizationId"
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-location>
            }

            @case (EventShopItemFilterItemType.PUBLIC_TAG) {
              <app-event-si-filter-item-public-tag
                [orgId]="event?.organizationId"
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-public-tag>
            }

            @case (EventShopItemFilterItemType.INTERNAL_TAG) {
              <app-event-si-filter-item-internal-tag
                [orgId]="event?.organizationId"
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-internal-tag>
            }

            @case (EventShopItemFilterItemType.ORDER_BY) {
              <app-event-si-filter-order-by
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-order-by>
            }

            @case (EventShopItemFilterItemType.RESERVATION_STATE) {
              <app-event-si-filter-item-reservation-state
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-reservation-state>
            }

            @case (EventShopItemFilterItemType.STATE) {
              <app-event-si-filter-item-state
                [item]="filterItem"
                (change)="onChange($event)">
              </app-event-si-filter-item-state>
            }
          }
        }
      
      </div>
  
  
  
      <button
        pButton
        (click)="onToggleFilterEntities()"
        icon="pi pi-pencil"
        class="p-button-sm p-button-text p-button-plain p-button-rounded">
      </button>
  
    }
  </div>


</div>