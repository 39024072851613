<app-modal-header
  (close)="close()">
  <span slot="breadcrumb">
    {{'admin.form-settings-modal.title' | translate }}
  </span>
</app-modal-header>


<div [formGroup]="form" class="modal-body ">
  <div class="grid align-items-center">

    <div class="flex w-full flex-row align-items-center justify-content-between">
      <div class="flex-1 mr-2">
        <span class="p-float-label field">
          <input id="newTitle" formControlName="newTitle" type="text" pInputText class="w-full">
          <label for="newTitle">{{'admin.form-settings-modal.new.title' | translate }}</label>
          <small *ngIf="form.controls.newTitle.hasError('title')" class="pl-2 -mt-2 p-error">{{'admin.form-field-settings-modal.alreadyexists' | translate }}</small>
        </span>
      </div>
      <button
        pButton
        (click)="onCreate()"
        label="{{'admin.form-settings-modal.new.create' | translate }}"
        class="bg-bluegray-500 border-bluegray-500"
        icon="pi pi-plus">
      </button>
    </div>
  
    <div *ngIf="loading || selectItems.length > 0">
      <p-divider></p-divider>
      <div class="col-12">
        Vybrat položku ze zákaznických dat:
      </div>
    
      <div class="col-12" style="border-color: #CED4DA;" [class.loading-box]="loading">
        @for (item of selectItems; track item.title; let i = $index) {
          <label
            for="col-{{item.title}}"
            class="surface-border border-1 p-3 mb-0 surface-0 flex align-items-center gap-3 cursor-pointer"
            [class.border-round-top]="$first"
            [class.border-round-bottom]="$last"
            [class.border-top-none]="!$first"
            (mouseenter)="onSelectItemMouseHover(i, 'enter')"
            (mouseleave)="onSelectItemMouseHover(i, 'leave')"
          >
            <div class="flex flex-row column-gap-2">
              <p-checkbox inputId="col-{{item.title}}" name="userDataGroup" [binary]="true" [formControl]="form.controls.selectedUserData.controls[i]"></p-checkbox>
              <div class="flex flex-column">
                <span>{{ item.label }}</span>
                <small class="text-bluegray-400"><i class="pi pi-arrow-right text-xs"></i> {{ item.title }}</small>
              </div>
            </div>
            <span *ngIf="visibleEditButton[i]" class="flex flex-auto justify-content-end gap-3">
              <i class="pi pi-pencil cursor-pointer" style="font-size: 1.5rem; color: var(--text-color-secondary)" (click)="onEditUserData(i)"></i>
              <i class="pi pi-trash cursor-pointer" style="font-size: 1.5rem; color: var(--text-color-secondary)" (click)="onRemoveFromUserData(i)"></i>
            </span>
          </label>
        }
      </div>
    </div>

  </div>
</div>


<div class="modal-footer" *ngIf="selectItems.length > 0">
  <button
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{'admin.form-settings-modal.add-selected-items' | translate }}">
  </button>

</div>
