import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-choose-expiration-modal',
  standalone: true,
  imports: [
    CommonModule, FormsModule,
    CalendarModule, ButtonModule, TranslateModule,
    LocalizedDatePipe, ModalHeaderComponent
  ],
  templateUrl: './choose-expiration-modal.component.html',
  styleUrls: ['./choose-expiration-modal.component.scss']
})
export class ChooseExpirationModalComponent extends Modal implements OnInit {

  datetime: Date | null = null;
  minDateValue: Date = new Date();


  @Input() currentDatetime: string | null = null;

  @Output() chosenDatetime = new EventEmitter<string>();

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.datetime = new Date(this.currentDatetime ?? new Date());
  }

  onSubmit() {
    this.chosenDatetime.emit( this.datetime!.toString() );
    this.close();
  }

}
