import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { BehaviorSubject, debounceTime, finalize, Observable, Subject, Subscription, take } from 'rxjs';
import { IEvent } from 'src/app/shared/models/event/event.model';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ShopItemsService } from 'src/app/shared/services/entities/shop-items/shop-items.service';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxChangeEvent, ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { MultiSelectModule } from 'primeng/multiselect';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';


export interface IItemStatesFilterForm {
  showShowPublishedPublic: FormControl<boolean>;
  showShowPublishedPrivate: FormControl<boolean>;
  showWithOpenedReservations: FormControl<boolean>;
  showFinished: FormControl<boolean>;
  showUnpublished: FormControl<boolean>;
  showDraft: FormControl<boolean>;
}
export interface IItemsForm {
  shopItemIds: FormControl<number[]>;
}

enum SortItemsBy {
  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
  CHRONOLOGICAL_ASC = 'CHRONOLOGICAL_ASC',
  CHRONOLOGICAL_DESC = 'CHRONOLOGICAL_DESC',
}

export interface IShopItemCombinedStates {
  showShowPublished?: boolean; // tmp - because of old data
  showShowPublishedPublic: boolean;
  showShowPublishedPrivate: boolean;
  showWithOpenedReservations: boolean;
  showFinished: boolean;
  showUnpublished: boolean;
  showDraft: boolean;
};

export interface IShopItemsPickerAdditionalFilters {
  sessionGroupRegistrationTypes?: (SessionGroupRegistrationType | null)[] | undefined;
  excludeShopItemIds?: number[];
}

// ^^ same as items in the interface ^^
export enum ShopItemCombinedStates {
  showShowPublishedPublic = 'showShowPublishedPublic',
  showShowPublishedPrivate = 'showShowPublishedPrivate',
  showWithOpenedReservations = 'showWithOpenedReservations',
  showFinished = 'showFinished',
  showUnpublished = 'showUnpublished',
  showDraft = 'showDraft'
};

interface IItemSelectGroup extends SelectItemGroup {
  event: IEvent;
  shopItems: IShopItem[];
  items: IShopItemPickerItemSelect[];
}
export interface IShopItemPickerItemSelect extends SelectItem {
  shopItem: IShopItem;
  disabled: boolean;
  tooltip: string | null;
}


export interface IItemsFilterModalResult {
  states: IShopItemCombinedStates;
  shopItemOptions: SelectItem[];
}

export interface IDisabledShopItemsMap {
  [shopItemId: number]: {
    disabled: boolean;
    tooltip: string | null;
  } | undefined;
}

@Component({
  selector: 'app-shop-items-picker',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule, FormsModule,
    CheckboxModule, ProgressSpinnerModule, ListboxModule, InputTextModule,
    NgbTooltipModule, MultiSelectModule
  ],
  animations: [
    collapseAnimation
  ],
  templateUrl: './shop-items-picker.component.html',
  styleUrl: './shop-items-picker.component.scss'
})
export class ShopItemsPickerComponent implements OnInit, OnDestroy {
  SortItemsBy = SortItemsBy;

  itemsForListbox: IItemSelectGroup[] = []// IListBoxItem[] = [];
  filteredItemsForListbox: IItemSelectGroup[] = [];
  _selectedItems: IShopItemPickerItemSelect[] = [];
  get selectedItems() { return this._selectedItems; };
  collapsedEventIds: number[] = [];
  itemsListboxFilter: string = '';
  sortItemsBy: SortItemsBy = SortItemsBy.CHRONOLOGICAL_DESC;

  stateMultiSelectItems: SelectItem[] = [];
  stateMultiSelectFC: FormControl<string[]> = new FormControl([], { nonNullable: true });

  inited = false;
  items$ = new BehaviorSubject<{
    items: {
      event: IEvent;
      shopItems: IShopItem[];
    }[];
    disabledShopItemsMap: IDisabledShopItemsMap;
  }>({
    items: [],
    disabledShopItemsMap: {}
  });
  fetchingItems$ = new BehaviorSubject<boolean>(false);
  fetchNewItems$ = new Subject<void>();

  subs: Subscription[] = [];

  @Input({ required: true }) itemsForm!: FormGroup<IItemsForm>;
  @Input({ required: true }) statesForm!: FormGroup<IItemStatesFilterForm>;
  @Input({ required: true }) set selectedItems(val: IShopItemPickerItemSelect[]) {
    this._selectedItems = val;
    this.selectedItemsChange.emit(val);
    this.itemsForm.controls.shopItemIds.setValue(val.map(x => x.shopItem.id));
  };
  @Input({required: true}) includeOnDemand: boolean = true;
  @Input({required: true}) onlyRepresentatives: boolean = false;
  @Input() additionalFilters: IShopItemsPickerAdditionalFilters | null = null;
  @Input() initValues: IItemsFilterModalResult | null = null;
  @Input() eventUuid: string | null = null;
  @Input() showLabels: boolean = true;
  @Input() hideEventCheckbox: boolean = false;
  @Input() maxSelectedItemsCount: number | null = null; // doesn't work if hideEventCheckbox is false
  @Input() getDisabledShopItemsMethod?: (shopItemIds: number[]) => Observable<IDisabledShopItemsMap>;
  @Input() excludeShopItemIds: number[] = [];
  @Output() selectedItemsChange = new EventEmitter<IShopItemPickerItemSelect[]>();

  constructor(
    public siHelper: ShopItemHelperService,
    private utilsService: UtilsService,
    private shopItemsService: ShopItemsService,
    private selectedOrgService: SelectedOrgService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.itemsForm.controls.shopItemIds.setValue(this.selectedItems.map(x => x.shopItem.id));
    this.initSelectItems();

    this.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.initSelectItems();
      }),
      this.stateMultiSelectFC.valueChanges.subscribe((selectedStates) => {
        const states: IShopItemCombinedStates = {
          showFinished: selectedStates.includes('showFinished'),
          showShowPublishedPrivate: selectedStates.includes('showShowPublishedPrivate') || selectedStates.includes('showShowPublished'),
          showShowPublishedPublic: selectedStates.includes('showShowPublishedPublic') || selectedStates.includes('showShowPublished'),
          showUnpublished: selectedStates.includes('showUnpublished'),
          showWithOpenedReservations: selectedStates.includes('showWithOpenedReservations'),
          showDraft: selectedStates.includes('showDraft')
        };
        this.statesForm.setValue({
          showDraft: states.showDraft,
          showFinished: states.showFinished,
          showShowPublishedPrivate: states.showShowPublishedPrivate,
          showShowPublishedPublic: states.showShowPublishedPublic,
          showUnpublished: states.showUnpublished,
          showWithOpenedReservations: states.showWithOpenedReservations
        });
      }),
      this.items$.subscribe(items => {
        const itemsForListbox: IItemSelectGroup[] = [];
        for (let item of items.items) {
          itemsForListbox.push({
            event: item.event,
            label: item.event.title,
            value: item.event.id,
            shopItems: item.shopItems,
            items: item.shopItems.map(x => ({
              shopItem: x,
              value: undefined, // whole option is value
              label: x.title,
              disabled: items.disabledShopItemsMap[x.id]?.disabled ?? false,
              tooltip: items.disabledShopItemsMap[x.id]?.tooltip || null
            }))
          })
        };
        this.itemsForListbox = itemsForListbox;
        this.filteredItemsForListbox = this.itemsForListbox;
        this.selectedItems = this.itemsForListbox.map(x => x.items).flat().filter(x => this.itemsForm.controls.shopItemIds.value.includes(x.shopItem.id));
        this.collapsedEventIds = this.itemsForListbox.map(x => x.event.id);
        this.sortItems();
      }),

      this.statesForm.valueChanges.subscribe(() => {
        this.fetchNewItems$.next();
      }),

      this.fetchNewItems$.pipe(
        debounceTime(500)
      ).subscribe(() => { this.fetchItems() })
    );

    // init stateMultiSelectFC
    this.initStateMultiselectItems();

    if (!this.inited) this.fetchNewItems$.next();
  }

  private initStateMultiselectItems() {
    const selectedStates: string[] = [];
    const states = this.initValues
      ? this.initValues.states
      : this.statesForm.getRawValue();
    Object.entries(states).forEach(([key, val]) => {
      if (val && Object.keys(ShopItemCombinedStates).includes(key)) selectedStates.push(key);
    });
    this.stateMultiSelectFC.setValue(selectedStates, { emitEvent: true });
  }

  private initSelectItems() {
    this.stateMultiSelectItems = Object.keys(ShopItemCombinedStates).map((state) => ({
      value: state,
      label: this.translateService.instant(`ShopItemCombinedStates.${state}`)
    }));
  }

  private fetchItems() {
    const orgId = this.selectedOrgService.getCurrentValue() ?? -1;
    this.fetchingItems$.next(true);

    const formVals = this.statesForm.getRawValue();



    this.shopItemsService.getShopItemsForReservationsTableItemFilter({
      orgId: orgId,
      eventUuid: this.eventUuid,
      finished: formVals.showFinished,
      opened: formVals.showWithOpenedReservations,
      publishedPublic: formVals.showShowPublishedPublic,
      publishedPrivate: formVals.showShowPublishedPrivate,
      unpublished: formVals.showUnpublished,
      draft: formVals.showDraft,

      onDemand: this.includeOnDemand,
      onlyRepresentatives: this.onlyRepresentatives,
      sessionGroupRegistrationTypes: this.additionalFilters?.sessionGroupRegistrationTypes,
      excludeShopItemIds: this.additionalFilters?.excludeShopItemIds
    }).pipe(
      take(1),
      finalize(() => {
        if (!this.getDisabledShopItemsMethod) this.fetchingItems$.next(false);
      }),
    ).subscribe({
      next: (items) => {
        this.itemsForm.reset();

        const filteredItems = items.map((i) => ({ event: i.event, shopItems: i.shopItems.filter(x => !this.excludeShopItemIds.includes(x.id)) }));

        if (this.getDisabledShopItemsMethod) {
          const shopItemIds = filteredItems.map((i) => i.shopItems.map((s) => s.id)).flat();
          this.getDisabledShopItemsMethod(shopItemIds).pipe(
            take(1),
            finalize(() => this.fetchingItems$.next(false))
          ).subscribe((res) => {
            this.items$.next({
              items: filteredItems,
              disabledShopItemsMap: res
            });
          });
        } else {
          this.items$.next({
            items: filteredItems,
            disabledShopItemsMap: {}
          });
        }

        if (!this.inited) {
          if (this.initValues) {
            this.statesForm.patchValue(this.initValues.states, { emitEvent: false });
            this.itemsForm.controls.shopItemIds.setValue(this.initValues.shopItemOptions.map(x => x.value), { emitEvent: false });
            this.selectedItems = this.itemsForListbox.map(x => x.items).flat().filter(x => this.itemsForm.controls.shopItemIds.value.includes(x.shopItem.id));
          }
        }
        this.inited = true;
      }
    });
  }


  onEventCollapse(eventId: number) {
    if (this.collapsedEventIds.includes(eventId)) {
      this.collapsedEventIds = this.collapsedEventIds.filter(x => x !== eventId);
    } else {
      this.collapsedEventIds = [...this.collapsedEventIds, eventId];
    }
  }
  areAllEventShopItemsSelected(eventShopItems: IShopItem[]) {
    return eventShopItems.every(x => this.itemsForm.controls.shopItemIds.value.includes(x.id));
  }
  onShopItemsFilterEventClick(group: IItemSelectGroup, e: Event) {
    e.stopPropagation();
    const itemValues = group.items;
    // check if all items are already selected
    const allAlreadySelected = itemValues.every(x => this.selectedItems.map(i => i.shopItem.id).includes(x.shopItem.id));
    if (allAlreadySelected) {
      // remove all itemValues from shopItemsFilterValue
      this.selectedItems = this.selectedItems.filter(x => !itemValues.includes(x));
    } else {
      // add all items
      this.selectedItems = [...this.selectedItems, ...itemValues];
    }
  }
  onItemsListboxFilter(value: string) {
    this.itemsListboxFilter = value;
    const filteredItemsForListbox: IItemSelectGroup[] = [];
    for (let group of this.itemsForListbox) {
      const itemIdsThatMatch = group.shopItems.filter(x => x.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      const eventMatch = group.event.title.toLocaleLowerCase().includes(value.toLocaleLowerCase());

      if (eventMatch) {
        filteredItemsForListbox.push(group);
      } else if (itemIdsThatMatch.length) {
        filteredItemsForListbox.push({
          ...group,
          items: group.items.filter(x => itemIdsThatMatch.map(i => i.id).includes(x.shopItem.id)),
        })
      } else {
        // no match
      }
    }
    this.filteredItemsForListbox = filteredItemsForListbox;
  }
  onListboxChange(e: ListboxChangeEvent) {
    if (this.maxSelectedItemsCount) {
      const value = e.value as IShopItemPickerItemSelect[];
      this.selectedItems = value.map((x) => x as IShopItemPickerItemSelect).slice(this.selectedItems.length - this.maxSelectedItemsCount);
    }
  }

  onResetItems() {
    this.selectedItems = [];
  }

  sortItems(by?: SortItemsBy) {
    if (by) this.sortItemsBy = by;

    switch (this.sortItemsBy) {
      case SortItemsBy.CHRONOLOGICAL_DESC:
        // sort events by createdAt
        this.itemsForListbox.sort((a, b) => {
          return new Date(b.event.createdAt ?? 0).getTime() - new Date(a.event.createdAt ?? 0).getTime();
        });
        // sort event items by first session startAt
        for (let group of this.itemsForListbox) {
          if (group.items[0]?.shopItem.sessionGroup?.sessions?.[0]?.startAt !== undefined) {
            group.items.sort((a, b) => {
              const startAtA = new Date(a.shopItem.sessionGroup?.sessions?.[0]?.startAt || 0).getTime();
              const startAtB = new Date(b.shopItem.sessionGroup?.sessions?.[0]?.startAt || 0).getTime();
              return startAtA - startAtB;
            });
          }
        }
        break;
      case SortItemsBy.CHRONOLOGICAL_ASC:
        // sort events by createdAt
        this.itemsForListbox.sort((a, b) => {
          return new Date(a.event.createdAt ?? 0).getTime() - new Date(b.event.createdAt ?? 0).getTime();
        });
        // sort event items by first session startAt
        for (let group of this.itemsForListbox) {
          if (group.items[0]?.shopItem.sessionGroup?.sessions?.[0]?.startAt !== undefined) {
            group.items.sort((a, b) => {
              const startAtA = new Date(a.shopItem.sessionGroup?.sessions?.[0]?.startAt || 0).getTime();
              const startAtB = new Date(b.shopItem.sessionGroup?.sessions?.[0]?.startAt || 0).getTime();
              return startAtB - startAtA;
            });
          }
        }
        break;
      case SortItemsBy.ALPHABETICAL_DESC:
        this.itemsForListbox.sort((a, b) => b.event.title.localeCompare(a.event.title));
        for (let group of this.itemsForListbox) {
          group.items.sort((a, b) => b.shopItem.title.localeCompare(a.shopItem.title));
        }
        break;
      case SortItemsBy.ALPHABETICAL_ASC:
        this.itemsForListbox.sort((a, b) => a.event.title.localeCompare(b.event.title));
        for (let group of this.itemsForListbox) {
          group.items.sort((a, b) => a.shopItem.title.localeCompare(b.shopItem.title));
        }
        break;
      default:
        this.utilsService.logError(`Missing case for sortItemsBy: ${this.sortItemsBy}`);
        break;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}







export function getShopItemPickerItemStatesFilterForm(opt?: {allStates?: boolean}) {
  return new FormGroup<IItemStatesFilterForm>({
    showShowPublishedPublic: new FormControl(opt?.allStates ? true : true, { nonNullable: true }),
    showShowPublishedPrivate: new FormControl(opt?.allStates ? true : true, { nonNullable: true }),
    showWithOpenedReservations: new FormControl(opt?.allStates ? true : true, { nonNullable: true }),
    showFinished: new FormControl(opt?.allStates ? true : false, { nonNullable: true }),
    showUnpublished: new FormControl(opt?.allStates ? true : false, { nonNullable: true }),
    showDraft: new FormControl(opt?.allStates ? true : false, { nonNullable: true }),
  });
}

export function getShopItemPickerItemsForm() {
  return new FormGroup<IItemsForm>({
    shopItemIds: new FormControl([], { nonNullable: true }),
  });
}
