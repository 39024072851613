import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { CallableNames, DbService } from '../../db.service';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { ITransferFE } from 'src/app/pages/admin/org-admin/payments-tabs/payments/payments.component';
import { TransferProvider } from 'src/app/shared/enums/transfer/transfer-providers.enum';
import { TransferPaymentMethod } from 'src/app/shared/enums/transfer/transfer-payment-methods';


export interface IPaymentGetData {
  ids?: number;
  organizationId?: number;

  include?: {

  }
};

export interface ITransfersGetUserOrgUnpaidData {
  userId: number;
  organizationId: number;
  orderId?: number;
  includePaid?: boolean;
};

export interface ICallablesTransfersRefreshPaymentStatusData {
  transferId: number;
}

export interface IRecordPaymentData {
  unpaidTransferId: number;
  paidAmount: number;
  adminNote: string | null;
  paidAt: string;
  paymentMethod: TransferPaymentMethod;
};

export interface ICreateCustomerCreditCallableData {
  userId: number;
  organizationId: number;
  amount: number;
  currency: Currency;
  adminNote: string | null;
};

export interface ITransferGetData {
  actionBy: ActionBy;

  PG_CODE?: string;

  magicCodeUuid?: string;
  include?: {
    order?: boolean;
  }
};

export interface ITransfersPair {
  unpaidTransferId?: number;
  unpairedIncomeTransferId: number;
  adminNote: string | null;
  toCredit?: boolean;
  userId: number;
};

interface ITransferGetCompletedPaymentsByOrderData {
  orderUuid: string;
};


@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  constructor(
    private dbService: DbService,
  ) { }

  public getOrderCompletedPayments(data: ITransferGetCompletedPaymentsByOrderData): Observable<ITransfer[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersGetOrderCompletedPayments, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getSingleTransfer(data: ITransferGetData): Observable<ITransfer> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersGet, data });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public getManyPayments(data: IPaymentGetData): Observable<ITransferFE[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersGetPayments, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getSinglePayment(data: IPaymentGetData): Observable<ITransferFE> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersGetPayments, data });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public getUserOrgUnpaid(data: ITransfersGetUserOrgUnpaidData): Observable<{
    unpaidTransfers: (ITransfer & { unpaid: IPrice; })[];
    allTransfers: (ITransfer & { unpaid: IPrice | null; })[];
  }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersGetUserOrgUnpaid, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public cancel(data: { transferId: number; }): Observable<ITransfer> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersCancel, data });
    return this.dbService.handleObs(obs);
  }

  public getPaymentStatus(data: ICallablesTransfersRefreshPaymentStatusData): Observable<ITransfer> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersRefreshPaymentStatus, data });
    return this.dbService.handleObs(obs);
  }

  public recordPayments(data: IRecordPaymentData[], silentError?: boolean): Observable<number[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersRecordPayments, data });
    return this.dbService.handleObs(obs, { errSilent: silentError });
  }

  public createCustomerCredit(data: ICreateCustomerCreditCallableData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersRecordCustomerCredit, data });
    return this.dbService.handleObs(obs);
  }

  public removeUnpaired(transferId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersRemoveUnpaired, data: { transferId } });
    return this.dbService.handleObs(obs);
  }

  public pair(data: ITransfersPair) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesTransfersPair, data });
    return this.dbService.handleObs(obs);
  }
}
