import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IShopItemDetailInfo } from 'src/app/shared/models/shop-item/shop-item.model';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';

export enum ShopItemDetailsPart {
  DATE = 'DATE',
  TIME = 'TIME',
  DATE_TIME_RANGE = 'DATE_TIME_RANGE',
  LOCATION = 'LOCATION',
  CUSTOM_NUMBER = 'CUSTOM_NUMBER'
}

@Component({
  selector: 'app-shop-item-details-info',
  standalone: true,
  imports: [
    TranslateModule,
    NgbTooltipModule,
  ],
  templateUrl: './shop-item-details-info.component.html',
  styleUrl: './shop-item-details-info.component.scss'
})
export class ShopItemDetailsInfoComponent implements OnInit, OnChanges {
  ShopItemDetailsPart = ShopItemDetailsPart;

  @Input({required: true}) detailsInfo: IShopItemDetailInfo | undefined;
  @Input({required: true}) locationUseInternalTitle: boolean = false;
  @Input() showParts: ShopItemDetailsPart[] = [ShopItemDetailsPart.DATE, ShopItemDetailsPart.TIME, ShopItemDetailsPart.LOCATION];

  partsMap: { [part in ShopItemDetailsPart]?: boolean } = {};

  constructor (
    public siHelper: ShopItemHelperService,
  ) {}

  ngOnInit(): void {
    this.initParts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showParts']) {
      this.initParts();
    }
  }

  private initParts() {
    this.partsMap = this.showParts.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
  }


}
