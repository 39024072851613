import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { CommonModule, CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IInvoice, InvoiceItemType, InvoiceTypeDB } from 'src/app/shared/models/invoice/invoice.model';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { IcoService } from 'src/app/shared/services/ico.service';
import { Subscription, finalize, take } from 'rxjs';
import { DateInputComponent, dateStringToDate, dateToDateString } from 'src/app/shared/components/date-input/date-input.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IInvoicesModifyData, InvoicesService } from 'src/app/shared/services/entities/invoices/invoices.service';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { AddressFormGroupModalComponent, IAddress, IAddressFG, getAddressFG } from 'src/app/shared/modals/address-form-group-modal/address-form-group-modal.component';
import { BasicModalMode, ModalService } from 'src/app/shared/services/modal.service';
import { AddressPipe } from 'src/app/shared/pipes/address.pipe';
import { SelectItem } from 'primeng/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ApiType } from 'src/app/shared/enums/api/api-type.enum';
import { TransferPaymentMethod } from 'src/app/shared/enums/transfer/transfer-payment-methods';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { OrdersService } from 'src/app/shared/services/entities/orders/orders.service';
import { OrganizationStoreService } from 'src/app/shared/services/store/organization-store.service';
import { OrderNumberPipe } from 'src/app/shared/pipes/order-number.pipe';
import { BasicModalComponent } from 'src/app/shared/modals/basic-modal/basic-modal.component';

interface IInvoiceModifyForm {
  // payee
  legalName: FormControl<string>;
  address: FormControl<string>;
  addressStructured: FormGroup<IAddressFG>;
  companyNumber: FormControl<string>;
  vatNumber: FormControl<string | null>;
  vatPayer: FormControl<boolean>;
  apiType: FormControl<ApiType>;

  // payer
  payerName: FormControl<string>;
  payerCompanyBillingInfo: FormGroup<{
    name: FormControl<string | null>,
    vatNumber: FormControl<string | null>,
    ico: FormControl<string | null>,
    address: FormGroup<IAddressFG>
  }>;

  // invoice
  invoiceNumber: FormControl<string>;
  invoiceFooterText: FormControl<string | null>;
  text: FormControl<string | null>;
  taxableTransactionDate: FormControl<string | null>;
  createdAt: FormControl<string>;
  maturity: FormControl<string>;
  paidAt?: FormControl<string>;

  // invoiceItems
  invoiceItems: FormArray<FormGroup<IInvoiceItemFG>>;

  changeMaturityInOrder: FormControl<boolean>;
  changeMaturityOtherInvoices: FormControl<boolean>;
}
interface IInvoiceItemFG {
  id: FormControl<number>;
  title: FormControl<string>;
  count: FormControl<number>;
  vat: FormControl<number>;
  unitPriceWithVat: FormControl<number>;
  unitPriceWithoutVat: FormControl<number>;
};

export interface IModifyInvoiceResult {
  affectedReservationIds?: number[];
}

interface IPayerInfo {
  name: string | null;
  ico: string | null;
  vatNumber: string | null;
  address?: IAddress;
}

@Component({
  selector: 'app-invoice-modify-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, InputTextModule, CheckboxModule, InputTextareaModule, DividerModule, OrderNumberPipe,
    ModalHeaderComponent, DateInputComponent, AddressPipe, SelectButtonModule, TranslateModule, InputNumberModule
  ],
  templateUrl: './invoice-modify-modal.component.html',
  styleUrls: ['./invoice-modify-modal.component.scss']
})
export class InvoiceModifyModalComponent extends Modal implements OnInit, OnDestroy {
  InvoiceTypeDB = InvoiceTypeDB;
  OrderState = OrderState;

  @Input() invoice: IInvoice | null = null;
  @Input() creditNoteExists: boolean = false;
  @Input() order: IOrderFE | null = null;
  @Input() proForma: boolean | undefined;
  @Input() issueNewInvoice: boolean = false;
  
  @Output() modified = new EventEmitter<IModifyInvoiceResult>();

  apiTypeOptions: SelectItem[] = [
    { value: ApiType.BANK_ACCOUNT, label: `ApiType.${ApiType.BANK_ACCOUNT}` },
    { value: ApiType.PAYMENT_GATE, label: `ApiType.${ApiType.PAYMENT_GATE}` }
  ];

  form = new FormGroup<IInvoiceModifyForm>({
    // payee
    legalName: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ]}),
    address: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ]}),
    addressStructured: getAddressFG({ required: true }),
    companyNumber: new FormControl<string>('', { nonNullable: true, validators: [ this.icoService.icoValidator.bind(this.icoService),  Validators.required ]}),
    vatNumber: new FormControl<string | null>(null),
    vatPayer: new FormControl<boolean>(true, { nonNullable: true, validators: [ Validators.required ]}),
    apiType: new FormControl<ApiType>(ApiType.BANK_ACCOUNT, { nonNullable: true }),
    
    // payer
    payerName: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required ]}),
    payerCompanyBillingInfo: new FormGroup<{
      name: FormControl<string | null>;
      ico: FormControl<string | null>;
      vatNumber: FormControl<string | null>;
      address: FormGroup<IAddressFG>;
    }>({
      name: new FormControl(null, { nonNullable: true, validators: [  ]}),
      ico: new FormControl(null, { nonNullable: true, validators: [ this.icoService.icoValidator.bind(this.icoService) ]}),
      vatNumber: new FormControl(null, { nonNullable: true }),
      address: getAddressFG({})
    }),
    
    // invoice
    invoiceNumber: new FormControl('', { nonNullable: true, validators: [ Validators.required ]}),
    invoiceFooterText: new FormControl(null),
    text: new FormControl(null),
    taxableTransactionDate: new FormControl(null, { nonNullable: true, validators: [ ]}),
    createdAt: new FormControl('', { nonNullable: true, validators: [ Validators.required ]}),
    maturity: new FormControl('', { nonNullable: true, validators: [ Validators.required ]}),

    // invoiceItems
    invoiceItems: new FormArray<FormGroup<IInvoiceItemFG>>([ ]),

    // admin prompts
    changeMaturityInOrder: new FormControl(false, { nonNullable: true }),
    changeMaturityOtherInvoices: new FormControl(false, { nonNullable: true })
  });
  minMaxIndexMap: { [index: number]: { min?: number; max?: number; } } = {};

  maturityValueChanged = false;

  private subs: Subscription[] = [];

  currency: string = 'Kč';

  sending = false;
  saving = false;

  fetchingFinalInvoice = false;
  finalInvoice: IInvoice | null = null;

  oldPayerInfo: IPayerInfo | null = null;

  constructor(
    private icoService: IcoService,
    private utilsService: UtilsService,
    private invoicesService: InvoicesService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
    private navigationService: NavigationService,
    private orderService: OrdersService,
    private orgStore: OrganizationStoreService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.invoice?.type === InvoiceTypeDB.PROFORMA) {
      this.fetchFinalInvoice();
    }

    this.createForm();

    this.patchForm();

    this.watchForm();
  }

  private fetchFinalInvoice() {
    if (this.invoice?.orderId) {
      this.fetchingFinalInvoice = true;
      this.orderService.getFinalInvoice({ orderId: this.invoice?.orderId }).pipe(
        take(1),
        finalize(() => this.fetchingFinalInvoice = false)
      ).subscribe((res) => {
        this.finalInvoice = res;
      });
    }
  }

  onSubmit(template: TemplateRef<any>) {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    const data = this.getModifyInvoice();

    const modifyInvoice = (data: IInvoicesModifyData) => {
      this.sending = true;
      this.invoicesService.modify(data).pipe(
        take(1),
        finalize(() => this.sending = false)
      ).subscribe({
        next: (invoice) => {
          if (invoice.externalSyncStatus && invoice.updatedAt in invoice.externalSyncStatus) {
            const error = invoice.externalSyncStatus[invoice.updatedAt];
            this.modalService.openBasicModal(BasicModalComponent, {
              btnAction: () => {},
              btnClass: '',
              btnLabel: 'admin.modify-invoice-external-sync-fail-modal.submit-btn.label',
              btnLabelData: {},
              btnWrapClass: '',
              buttonMode: BasicModalMode.ONE_BUTTON,
              template: null,
              text: 'admin.modify-invoice-external-sync-fail-modal.text',
              textTranslationData: {
                apiProvider: this.translateService.instant(`ApiProvider.${error.apiProvider}`),
                errorReason: this.translateService.instant(`ExternalInvoiceSyncErrorType.${error.errorType}`)
              },
              title: 'admin.modify-invoice-external-sync-fail-modal.title',
              titleTranslationData: {
                apiProvider: this.translateService.instant(`ApiProvider.${error.apiProvider}`)
              },
              titleClass: 'text-red-500'
            }, 'centered-modal');
          }
          this.modified.emit();
          this.close();
        }
      });
    };

    if (!this.comparePayerInfo(data.payerCompanyBillingInfo)) {
      this.modalService.openBasicModal(BasicModalComponent, {
        rightBtnAction: () => modifyInvoice(data),
        rightBtnClass: 'w-full p-button-sm',
        rightBtnLabel: 'admin.modify-invoice-payer-info-change-modal.submit-btn.label',
        rightBtnLabelData: {},
        rightBtnWrapClass: 'flex-1',
        leftBtnAction: () => {},
        leftBtnClass: 'w-full p-button-sm p-button-text p-button-plain',
        leftBtnLabel: 'admin.modify-invoice-payer-info-change-modal.cancel-btn.label',
        leftBtnLabelData: {},
        leftBtnWrapClass: 'flex-1',
        buttonMode: BasicModalMode.TWO_BUTTON,
        template,
        text: '',
        textTranslationData: {},
        title: 'admin.modify-invoice-payer-info-change-modal.title',
        titleTranslationData: {},
        titleClass: ''
      }, 'centered-modal');
    } else {
      modifyInvoice(data);
    }
  }

  onShowFinalInvoice() {
    const org = this.orgStore.state.org$.value;
    if (!org || !this.order) return;
    this.navigationService.goToOrderInvoice({ orderUuid: this.order?.orderUuid, newTab: true, orgCustomDomains: org.customDomainsVerified });
  }

  private getModifyInvoice() {
    const formsVal = this.form.getRawValue();
    const data: IInvoicesModifyData = {
      invoiceId: this.invoice!.id,

      // payee
      legalName: formsVal.legalName,
      address: formsVal.address,
      addressStructured: formsVal.addressStructured,
      companyNumber: formsVal.companyNumber,
      vatNumber: formsVal.vatNumber,
      vatPayer: formsVal.vatPayer,
      apiType: formsVal.apiType,
      
      // payer
      payerName: formsVal.payerName,
      payerCompanyBillingInfo: {
        address: formsVal.payerCompanyBillingInfo.address,
        ico: formsVal.payerCompanyBillingInfo.ico,
        vatNumber: formsVal.payerCompanyBillingInfo.vatNumber,
        name: formsVal.payerCompanyBillingInfo.name || formsVal.payerName
      },
      
      invoiceNumber: formsVal.invoiceNumber,
      invoiceFooterText: formsVal.invoiceFooterText,
      text: formsVal.text,
      taxableTransactionDate: formsVal.taxableTransactionDate ? dateStringToDate(formsVal.taxableTransactionDate)!.toISOString() : null,
      createdAt: dateStringToDate(formsVal.createdAt)!.toISOString(),
      maturity: dateStringToDate(formsVal.maturity)!.toISOString(),
      paidAt: formsVal.paidAt ? dateStringToDate(formsVal.paidAt)!.toISOString() : null,

      invoiceItems: formsVal.invoiceItems.map((invoiceItem) => ({
        ...invoiceItem,
        vat: invoiceItem.vat / 100
      })),

      changeMaturityInOrder: formsVal.changeMaturityInOrder,
      changeMaturityOtherInvoices: formsVal.changeMaturityOtherInvoices
    }
    return data;
  }

  onIssueNewInvoice(invoiceType: InvoiceTypeDB) {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    if (!this.invoice?.orderId) {
      console.error('Missing orderId!');
      return;
    };

    this.sending = true;
    this.invoicesService.issueNewInvoice({
      orderId: this.invoice.orderId,
      modifyData: this.getModifyInvoice(),
      invoiceType
    }).pipe(
      take(1),
      finalize(() => this.saving = false)
    ).subscribe({
      next: (res) => {
        this.modified.emit({
          affectedReservationIds: res.affectedReservationIds
        });
        this.navigationService.goToOrderInvoice({
          orderUuid: res.orderUuid,
          newTab: true,
          orgCustomDomains: res.orgCustomDomains
        });
        this.close();
      }
    });
  }

  private comparePayerInfo(payerInfo: IPayerInfo) {
    return this.oldPayerInfo?.name === payerInfo.name &&
      ((!this.oldPayerInfo?.ico && !payerInfo.ico) || this.oldPayerInfo?.ico === payerInfo.ico) &&
      ((!this.oldPayerInfo?.vatNumber && !payerInfo.vatNumber) || this.oldPayerInfo?.vatNumber === payerInfo.vatNumber) &&
      ((!this.oldPayerInfo?.address?.street && !payerInfo.address?.street) || this.oldPayerInfo?.address?.street === payerInfo.address?.street) &&
      ((!this.oldPayerInfo?.address?.city && !payerInfo.address?.city) || this.oldPayerInfo?.address?.city === payerInfo.address?.city) &&
      ((!this.oldPayerInfo?.address?.zip && !payerInfo.address?.zip) || this.oldPayerInfo?.address?.zip === payerInfo.address?.zip) &&
      ((!this.oldPayerInfo?.address?.countryCode && !payerInfo.address?.countryCode) || this.oldPayerInfo?.address?.countryCode === payerInfo.address?.countryCode);
  }

  onSaveAsInvoice() {
    if (!this.invoice || (this.invoice.type === InvoiceTypeDB.FINAL)) return;
    this.saving = true;
    this.invoicesService.create(this.invoice.id, true).pipe(
      take(1),
      finalize(() => this.saving = false)
    ).subscribe({
      next: () => {
        this.modified.emit();

        this.close();
      }
    });
  }

  onOrgAddressChange() {
    this.modalService.openAddressFormGroupModal(AddressFormGroupModalComponent, this.form.controls.addressStructured);
  }

  onPayerAddressChange() {
    this.modalService.openAddressFormGroupModal(AddressFormGroupModalComponent, this.form.controls.payerCompanyBillingInfo.controls.address);
  }

  private createForm() {
    const invoiceItems = this.invoice?.items ?? [];

    invoiceItems.forEach((item, index) => {
      this.minMaxIndexMap[index] = {
        max: item.type === InvoiceItemType.DISCOUNT || item.type === InvoiceItemType.PAID_ADVANCE
          ? 0
          : undefined,
        min: item.type !== InvoiceItemType.DISCOUNT && item.type !== InvoiceItemType.PAID_ADVANCE
          ? 0
          : undefined
      };
      const priceValidators = [Validators.required];
      if (this.minMaxIndexMap[index].max !== undefined) {
        priceValidators.push(Validators.max(0));
      }
      if (this.minMaxIndexMap[index].min !== undefined) {
        priceValidators.push(Validators.min(0));
      }
      const priceDisabled = (item.type === InvoiceItemType.CREDIT) || (item.type === InvoiceItemType.PAID_ADVANCE) || this.creditNoteExists || !!item.cancelled;
      const titleDisabled = (item.type === InvoiceItemType.CREDIT) || (item.type === InvoiceItemType.PAID_ADVANCE);
      const invoiceItemFG = new FormGroup<IInvoiceItemFG>({
        id: new FormControl(item.id, { nonNullable: true, validators: [ Validators.required ]}),
        title: new FormControl({ value: this.translateService.instant(item.title, item.titleTranslationData), disabled: titleDisabled }, { nonNullable: true, validators: [ Validators.required ]}),
        count: new FormControl({ value: item.count, disabled: true }, { validators: [Validators.required], nonNullable: true }),
        unitPriceWithVat: new FormControl({ value: item.unitPriceWithVat, disabled: priceDisabled }, { validators: priceValidators, nonNullable: true }),
        unitPriceWithoutVat: new FormControl({ value: item.unitPriceWithoutVat, disabled: priceDisabled }, { validators: priceValidators, nonNullable: true }),
        vat: new FormControl({ value: item.vat * 100, disabled: priceDisabled }, { validators: [Validators.required, Validators.min(0)], nonNullable: true })
      });
      this.subs.push(
        invoiceItemFG.controls.vat.valueChanges.subscribe((vat) => {
          const unitPriceWithoutVat = invoiceItemFG.controls.unitPriceWithoutVat.value;
          invoiceItemFG.controls.unitPriceWithVat.setValue(unitPriceWithoutVat * (1 + (vat / 100)), { emitEvent: false });
        }),
        invoiceItemFG.controls.unitPriceWithVat.valueChanges.subscribe((unitPriceWithVat) => {
          const vat = invoiceItemFG.controls.vat.value / 100;
          invoiceItemFG.controls.unitPriceWithoutVat.setValue(unitPriceWithVat / (1 + vat), { emitEvent: false });
        }),
        invoiceItemFG.controls.unitPriceWithoutVat.valueChanges.subscribe((unitPriceWithoutVat) => {
          const vat = invoiceItemFG.controls.vat.value / 100;
          invoiceItemFG.controls.unitPriceWithVat.setValue(unitPriceWithoutVat * (1 + vat), { emitEvent: false });
        })
      );
      this.form.controls.invoiceItems.push(invoiceItemFG);
    });

    if (this.issueNewInvoice) this.form.controls.invoiceNumber.disable();

    if (this.order?.state === OrderState.COMPLETED) {
      this.form.addControl('paidAt', new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }));
    }
  }

  private watchForm() {
    this.subs.push(
      this.form.controls.vatPayer.valueChanges.subscribe(val => {
        if (!val) {
          this.form.controls.vatNumber.setValue(null);
        }
      }),
      this.form.controls.maturity.valueChanges.subscribe(() => {
        if (!this.maturityValueChanged) this.maturityValueChanged = true;
      })
    );
  }

  private patchForm() {
    if (!this.invoice) return;
    const currency = this.invoice.items.at(0)?.currency;
    if (currency) {
      const currencyParsed = this.currencyPipe.transform(
        0,
        currency,
        'symbol',
        `1.${0}-2`,
        'cs-CZ'
      )?.toString().replace('0', '').trim() || this.currency;
      this.currency = currencyParsed;
    }
    const apiType = this.invoice.paymentInstructions.method === TransferPaymentMethod.BANK_TRANSFER
      ? ApiType.BANK_ACCOUNT
      : this.invoice.paymentInstructions.method === TransferPaymentMethod.PAYMENT_GATE
        ? ApiType.PAYMENT_GATE
        : undefined;
    this.form.patchValue({
      legalName: this.invoice.legalName,
      address: this.invoice.address,
      addressStructured: this.invoice.addressStructured || undefined,
      companyNumber: this.invoice.companyNumber,
      vatNumber: this.invoice.vatNumber,
      vatPayer: this.invoice.vatPayer,
      invoiceFooterText: this.invoice.invoiceFooterText,
      payerName: this.invoice.payerName,
      payerCompanyBillingInfo: {
        name: this.invoice.payerCompanyBillingInfo?.name ?? null,
        ico: this.invoice.payerCompanyBillingInfo?.ico ?? null,
        vatNumber: this.invoice.payerCompanyBillingInfo?.vatNumber ?? null,
        address: this.invoice.payerCompanyBillingInfo?.address,
      },
      text: this.invoice.text,
      invoiceNumber: this.invoice.invoiceNumber,
      taxableTransactionDate: this.invoice.taxableTransactionDate ? dateToDateString(new Date(this.invoice.taxableTransactionDate)) : null,
      createdAt: dateToDateString(new Date(this.invoice.createdAt))!,
      maturity: dateToDateString(new Date(this.invoice.maturity))!,
      paidAt: this.order?.state === OrderState.COMPLETED ? dateToDateString(new Date(this.order.completedAt!))! : ''
    });

    this.oldPayerInfo = {
      name: this.invoice.payerCompanyBillingInfo?.name || this.invoice.payerName || null,
      ico: this.invoice.payerCompanyBillingInfo?.ico ?? null,
      vatNumber: this.invoice.payerCompanyBillingInfo?.vatNumber ?? null,
      address: this.invoice.payerCompanyBillingInfo?.address,
    };

    if (apiType) {
      this.form.controls.apiType.patchValue(apiType);
    }
  }

  onOrderNumberOpen() {
    if (!this.order?.orderUuid) return;
    this.navigationService.goToCustomerSingleOrder({ orderUuid: this.order.orderUuid, orgCustomDomains: this.order.organization.customDomainsVerified, newTab: true });
  }

  get paidAtFC() {
    return this.form.controls.paidAt;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
    this.subs.forEach(s => s.unsubscribe());
  }
}
