import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, take } from 'rxjs';
import { RoleType } from '../enums/user/role-types.enum';
import { OrgFillMissingDataModalComponent } from '../modals/org-fill-missing-data-modal/org-fill-missing-data-modal.component';
import { IOrganization } from '../models/organization/organization.model';
import { IUser } from '../models/user/user.model';
import { OrganizationsService } from './entities/organizations/organizations.service';
import { UsersService } from './entities/users/users.service';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { UserStoreService } from './store/user-store.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SelectedOrgService implements OnDestroy {

  public selectedOrgId$ = new BehaviorSubject<number | null>(null);
  public selectedOrgIdChanged$ = new Subject<number | null>();
  public selectedOrg$ = new BehaviorSubject<IOrganization | null>(null);

  private userSub: Subscription = Subscription.EMPTY;

  constructor(
    private userStore: UserStoreService,
    private usersService: UsersService,
    private orgsService: OrganizationsService,
    private modalService: ModalService,
    private navService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.userSub = this.userStore.state.user$.subscribe(user => {
      // if (user) {
      //   this.setOrgId( user.roles.find(x => x.organizationId)?.organizationId ?? null, false );
      // } else {
      //   this.setOrgId(null, false);
      // }

      this.initSelectedOrgId(user);
    });
  }

  public async setOrgId(orgId: number | null, manual: boolean) {
    localStorage.setItem('LEKTORY_selectedOrgId', JSON.stringify(orgId));
    this.selectedOrgId$.next(orgId);
    this.selectedOrgIdChanged$.next(orgId);
    // console.log('Selected orgId:', this.selectedOrgId$.getValue());

    // show modal to fill missing org data ( only for owner of the org )
    // if (orgId && this.usersService.isRoleOfOrg(this.userStore.state.user$.getValue()?.roles ?? [], RoleType.ORG_OWNER, orgId)) this.checkIfMissingOrgData(orgId);

    if (manual) {
      await this.navService.reloadRoute();
    }

    if (orgId) {
      this.orgsService.getOrgById(orgId).pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          this.selectedOrg$.next(res);
        }
      });
    } else {
      this.selectedOrg$.next(null);
    }
  }

  private initSelectedOrgId(user: IUser | null) {
    if (!user) return;


    // get orgId from url query
    const queryParams = new URLSearchParams(window.location.search);
    const orgIdFromQueryStr = queryParams.get('orgId');
    const orgIdFromQuery = orgIdFromQueryStr ? parseInt(orgIdFromQueryStr) : null;

    const selectedOrgIdInStorage = JSON.parse(localStorage.getItem('LEKTORY_selectedOrgId') ?? 'null');
    const userInOrgIds = this.getUserInOrganizations(user);

    let orgId = orgIdFromQuery || selectedOrgIdInStorage;
    // previous selected orgId is in localStorage
    if (orgId) {
      // user still has this orgId in his roles, or is SYS ADMIN
      if (userInOrgIds.includes(orgId) || this.usersService.isRoleOfOrg(user.roles, RoleType.SYSTEM_ADMIN, null)) {
        this.setOrgId(orgId, false);
      }
      // user does not have this orgId in his roles anymore
      else {
        this.setOrgId(userInOrgIds[0] ?? null, false);
      }
    }
    // there is no previous selected orgId in localStorage
    else {
      this.setOrgId(userInOrgIds[0] ?? null, false);
    }
  }

  private getUserInOrganizations(user: IUser | null) {
    if (!user) return [];
    let orgIds = user.roles.map(x => x.organizationId).filter(x => x !== null);
    orgIds = [...new Set(orgIds)];
    return orgIds;
  }



  private checkIfMissingOrgData(orgId: number) {

    this.orgsService.getOrgById(orgId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        const missingValues = this.findMissingValues(res);
        if (missingValues.length) {
          // TODO only admin!
          this.modalService.openOrgFillMissingDataModal(OrgFillMissingDataModalComponent, orgId, missingValues);
        }
      }
    });
  }


  public getCurrentValue() {
    return this.selectedOrgId$.getValue();
  }

  public findMissingValues(org: IOrganization) {
    const keys: (keyof IOrganization)[] = [];
    if (!org.legalName) keys.push('legalName');
    // if (!org.ico) keys.push('ico');

    return keys;
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }
}
