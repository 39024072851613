import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/shared/services/entities/categories/categories.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';

export type IEventShopItemFilterItem_CATEGORY = {
  type: EventShopItemFilterItemType.CATEGORY
  value: SelectItem[] | null;
}

@Component({
  selector: 'app-event-si-filter-item-category',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    MultiSelectModule,
    FilterItemChipsComponent,
  ],
  templateUrl: './event-si-filter-item-category.component.html',
  styleUrl: './event-si-filter-item-category.component.scss'
})
export class EventSiFilterItemCategoryComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_CATEGORY | undefined;
  @Input({required: true}) orgId: number | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_CATEGORY>();

  FC = new FormControl<number[] | null>(null);

  options: SelectItemGroup[] = [];
  fetching: boolean = false;
  fetched: boolean = false;
  chips: IFilterItemChip[] = [];

  subs: Subscription[] = [];

  constructor(
    private categoriesService: CategoriesService,
    private translate: TranslateService
  ) {
    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedOptions });
        this.updateChips();
      })
    )
  }

  ngOnInit(): void {
    this.options = [ {
      items: this.item?.value ?? [],
      label: ''
    } ];
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? null, { emitEvent: false });
    this.updateChips();
  }

  fetch() {
    if (!this.orgId) return;
    if (this.fetched) return;
    
    this.fetching = true;
    this.categoriesService.getForFilterItem({
      orgId: this.orgId,
    }).subscribe({
      next: res => {
        this.fetching = false;
        this.fetched = true;

         const groups: SelectItemGroup[] = [];
         res.forEach(category => {
          const group = groups.find(x => x.value === category.categoryGroup?.id);
          if (group) {
            group.items.push({ value: category.id, label: category.title });
          } else {
            groups.push({
              label: category.categoryGroup?.title ?? this.translate.instant('admin.si-category-group-item.general'),
              items: [{ value: category.id, label: category.title }],
              value: category.categoryGroup?.id
            });
          }
        });
        this.options = groups;
      }
    })
  }

  onRemoveChip(chip: IFilterItemChip) {
    this.FC.setValue(this.FC.value?.filter(x => x !== chip.value) ?? []);
  }

  get selectedOptions() {
    return this.options.flatMap(x => x.items).filter(x => this.FC.value?.includes(x.value));
  }

  private updateChips() {
    this.chips = this.selectedOptions.map(x => {
      const chip: IFilterItemChip = {
        label: x.label,
        value: x.value
      };
      return chip;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }
}
