import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Modal } from '../modal';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { getShopItemPickerItemsForm, getShopItemPickerItemStatesFilterForm, IItemsFilterModalResult, IShopItemPickerItemSelect, ShopItemsPickerComponent } from 'src/app/pages/admin/org-admin/reservations/reservation-table/items-filter-modal/shop-items-picker/shop-items-picker.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ShopItemsService } from '../../services/entities/shop-items/shop-items.service';
import { ShopItemSettingModuleType } from '../../models/shop-item/shop-item-setting-module.model';

export interface ICloneShopItemSettingsModuleModalResult {
  shopItemIds: number[];
  upsert: boolean;
}

export interface ICloneShopItemSettingsModuleModalInput {
  moduleType: ShopItemSettingModuleType;
  moduleUuid: string;
  shopItemId: number;

  orgId: number;
}

@Component({
  selector: 'app-clone-shop-item-settings-module-modal',
  standalone: true,
  imports: [
    CommonModule, ModalHeaderComponent,
    TranslateModule, ButtonModule,
    ShopItemsPickerComponent, CheckboxModule, FormsModule
  ],
  templateUrl: './clone-shop-item-settings-module-modal.component.html',
  styleUrl: './clone-shop-item-settings-module-modal.component.scss'
})
export class CloneShopItemSettingsModuleModalComponent extends Modal {
  statesForm = getShopItemPickerItemStatesFilterForm();
  itemsForm = getShopItemPickerItemsForm();
  selectedItems: IShopItemPickerItemSelect[] = [];
  initValues: IItemsFilterModalResult = {
    shopItemOptions: [],
    states: {
      showFinished: false,
      showShowPublishedPublic: true,
      showShowPublishedPrivate: true,
      showUnpublished: false,
      showWithOpenedReservations: true,
      showDraft: true
    }
  };

  @Input({ required: true }) data!: ICloneShopItemSettingsModuleModalInput;

  upsert: boolean = true;

  @Output() result = new EventEmitter<ICloneShopItemSettingsModuleModalResult>();

  constructor(
    private shopItemService: ShopItemsService
  ) {
    super();
  }

  getDisabledShopItems = (shopItemIds: number[]) => {
    return this.shopItemService.canCloneSettingModule({
      moduleType: this.data.moduleType,
      moduleUuid: this.data.moduleUuid,
      originShopItemId: this.data.shopItemId,
      orgId: this.data.orgId,
      shopItemIds
    });
  };
  

  onSubmit() {
    if (!this.selectedItems.length) return;
    this.result.emit({
      shopItemIds: this.selectedItems.map((s) => s.shopItem.id),
      upsert: this.upsert
    });
    this.close();
  }
}
