import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { finalize, Subscription, take } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

export interface ICollapsibleFormSection {
  title: string;
  collapsed: boolean;
  collapsible?: boolean;
  control?: FormControl | FormArray | FormGroup;
  saving?: boolean;
  saved?: boolean;
  warning: boolean;
  unset: boolean;
  save?: () => any;
};

@Component({
  selector: 'app-collapsible-form-section',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
  ],
  templateUrl: './collapsible-form-section.component.html',
  styleUrls: ['./collapsible-form-section.component.scss'],
  animations: [ collapseAnimation ]
})
export class CollapsibleFormSectionComponent implements OnInit, OnDestroy {

  @Input() formSection: ICollapsibleFormSection | undefined;

  @Output() collapseToggled = new EventEmitter<boolean>();

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.formSection?.control) {
      this.subs.push(
        // mark as not saved when value changed
        this.formSection.control.valueChanges.subscribe(v => {
          if (this.formSection?.saved) this.formSection.saved = false;
        })
      );
    }
  }

  onFormSection() {
    if (!this.formSection) return;
    if (this.formSection.collapsible === false) return;
    this.formSection.collapsed = !this.formSection.collapsed;
    this.collapseToggled.emit(this.formSection.collapsed);
  }

  onActionsSection(e: Event) {
    e.stopPropagation();
  }

  onSaveFormSection(e: Event) {
    e.stopPropagation();

    if (!this.formSection?.control || !this.formSection.save) return;

    this.utilsService.markUnknownControlDirty(this.formSection.control);
    if (this.formSection.control.invalid || this.formSection.saving) {
      console.error('invalid', this.formSection.control);
      return;
    }

    this.formSection.saving = true;
    this.formSection.save()?.pipe(
      take(1),
      finalize(() => this.formSection!.saving = false)
    )?.subscribe({
      next: (res: any) => {
        this.formSection!.saved = true;
        this.formSection!.control!.markAsPristine();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
