import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ShopItemReservationType } from 'src/app/shared/enums/shop-item/shop-item-reservation-types.enum';
import { IEvent } from 'src/app/shared/models/event/event.model';
import { DeviceSize, SystemService } from 'src/app/shared/services/system.service';
import { IEventNotPublishableReasons } from './event-publish-missing-info-modal/event-publish-missing-info-modal.component';
import { IEventAdminState } from 'src/app/shared/services/store/event-admin-store.service';
import { IScheduleTaskData } from 'src/app/pages/admin/org-admin/components/task-scheduler-input/task-scheduler-input.component';

@Injectable({
  providedIn: 'root'
})
export class EventStateChangerModalService {

  constructor(
    private modalService: NgbModal,
    private sysService: SystemService
  ) { }

  public openEventStateChangerModal(c: any, eventState: IEventAdminState) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right modal-full-right--lg',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.eventState = eventState;
  }

  public openEventPublishMissingInfoModal(c: any, missingData: IEventNotPublishableReasons) {
    const ref = this.modalService.open(c);
    ref.componentInstance.ref = ref;
    ref.componentInstance.reasons = missingData;

    return (ref.componentInstance.continue as Subject<boolean>).toPromise();
  }

  public openEventPublishModal(c: any, event: IEvent) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.event = event;

    return (ref.componentInstance.continue as Subject<number[] | boolean>).toPromise();
  }

  public openEventOpenReservationsModal(c: any, event: IEvent) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.event = event;

    return (ref.componentInstance.continue as Subject<{
      reservationType: ShopItemReservationType;
      shopItemIds: number[];
      scheduledClose: IScheduleTaskData;
    } | null>).toPromise();
  }

  public openEventPauseReservationsModal(c: any, event: IEvent) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;
    ref.componentInstance.event = event;

    return (ref.componentInstance.continue as Subject<{
      shopItemIds: number[];
    } | null>).toPromise();
  }

  public openEventUnpublishModal(c: any) {
    const ref = this.modalService.open(c, {
      windowClass: this.sysService.deviceSize$.getValue() === DeviceSize.SM ? 'modal-down' : 'modal-full-right',
    });
    ref.componentInstance.ref = ref;

    return (ref.componentInstance.continue as Subject<boolean>).toPromise();
  }
}
