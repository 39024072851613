import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';
import { InternalTagsService } from 'src/app/shared/services/entities/internal-tags/internal-tags.service';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_INTERNAL_TAG = {
  type: EventShopItemFilterItemType.INTERNAL_TAG
  value: SelectItem[] | null;
}

@Component({
  selector: 'app-event-si-filter-item-internal-tag',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    MultiSelectModule,
    FilterItemChipsComponent,
  ],
  templateUrl: './event-si-filter-item-internal-tag.component.html',
  styleUrl: './event-si-filter-item-internal-tag.component.scss'
})
export class EventSiFilterItemInternalTagComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_INTERNAL_TAG | undefined;
  @Input({required: true}) orgId: number | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_INTERNAL_TAG>();

  FC = new FormControl<number[]>([], { nonNullable: true });

  tagsOptions: SelectItemGroup[] = [];
  fetchingTags: boolean = false;
  tagsFetched: boolean = false;
  chips: IFilterItemChip[] = [];

  subs: Subscription[] = [];

  constructor(
    private internalTagsService: InternalTagsService,
    private translate: TranslateService
  ) {
    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedOptions });
        this.updateChips();
      })
    )
  }

  ngOnInit(): void {
    this.tagsOptions = [ {
      items: this.item?.value ?? [],
      label: ''
    } ];
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? [], { emitEvent: false });
    this.updateChips();
  }

  fetchTags() {
    if (!this.orgId) return;
    if (this.tagsFetched) return;
    
    this.fetchingTags = true;
    this.internalTagsService.getForFilterItem({
      orgId: this.orgId,
    }).subscribe({
      next: res => {
        this.fetchingTags = false;
        this.tagsFetched = true;

         const groups: SelectItemGroup[] = [];
         res.forEach(tag => {
          const group = groups.find(x => x.value === tag.internalTagGroup?.id);
          if (group) {
            group.items.push({ value: tag.id, label: tag.title });
          } else {
            groups.push({
              label: tag.internalTagGroup?.title ?? this.translate.instant('admin.si-internal-tag-group-item.general'),
              items: [{ value: tag.id, label: tag.title }],
              value: tag.internalTagGroup?.id
            });
          }
        });
        this.tagsOptions = groups;
      }
    })
  }

  onRemoveChip(chip: IFilterItemChip) {
    this.FC.setValue(this.FC.value?.filter(x => x !== chip.value) ?? []);
  }

  get selectedOptions() {
    return this.tagsOptions.flatMap(x => x.items).filter(x => this.FC.value?.includes(x.value));
  }

  private updateChips() {
    this.chips = this.selectedOptions.map(x => {
      const chip: IFilterItemChip = {
        label: x.label,
        value: x.value
      };
      return chip;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }
}
