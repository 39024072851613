import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';

export interface INextBestOfferCreateData {
  visibilityForCustomer: boolean;
  nextBestOfferTemplateId: number;
  shopItemId: number;
}

export interface INextBestOfferDeleteData {
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class NextBestOffersService {

  constructor(
    private dbService: DbService,
  ) { }

  public create(data: INextBestOfferCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesNextBestOffersCreate, data });
    return this.dbService.handleObs(obs);
  }

  public delete(data: INextBestOfferDeleteData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesNextBestOffersDelete, data });
    return this.dbService.handleObs(obs);
  }
}
