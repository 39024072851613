import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { LocationsService } from 'src/app/shared/services/entities/locations/locations.service';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_LOCATION = {
  type: EventShopItemFilterItemType.LOCATION
  value: SelectItem[] | null;
}

@Component({
  selector: 'app-event-si-filter-item-location',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    MultiSelectModule,
    FilterItemChipsComponent,
  ],
  templateUrl: './event-si-filter-item-location.component.html',
  styleUrl: './event-si-filter-item-location.component.scss'
})
export class EventSiFilterItemLocationComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_LOCATION | undefined;
  @Input({required: true}) orgId: number | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_LOCATION>();

  chips: IFilterItemChip[] = [];

  FC = new FormControl<number[]>([], { nonNullable: true });

  subs: Subscription[] = [];

  constructor(
    private locationsService: LocationsService,
    private translate: TranslateService
  ) {
    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedOptions });
        this.updateChips();
      })
    )
  }

  
  locationOptions: SelectItem[] = [];
  fetchingLocations: boolean = false;
  locationsFetched: boolean = false;

  ngOnInit(): void {
    this.locationOptions = this.mergeOptions([ this.onlineOption() ], this.item?.value ?? []);
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? [], { emitEvent: false });
    this.updateChips();
  }

  fetchLocations() {
    if (!this.orgId) return;
    if (this.locationsFetched) return;

    this.fetchingLocations = true;
    this.locationsService.getLocationsForEventFilter({
      orgId: this.orgId,
    }).subscribe({
      next: res => {
        this.fetchingLocations = false;
        this.locationsFetched = true;

        this.locationOptions = this.mergeOptions(this.locationOptions, res.map(x => ({ value: x.id, label: x.internalTitle || x.title })));
      }
    })
  }

  private mergeOptions(first: SelectItem[], second: SelectItem[]) {
    return [ ...first, ...second.filter(x => !first.find(y => y.value === x.value)) ];
  }

  private onlineOption() {
    return { value: -1, label: this.translate.instant('admin.event-shop-items-filter.locations.online-label') };
  }

  onRemoveChip(chip: IFilterItemChip) {
    this.FC.setValue(this.FC.value?.filter(x => x !== chip.value) ?? []);
  }

  get selectedOptions() {
    return this.locationOptions.filter(x => this.FC.value?.includes(x.value));
  }

  private updateChips() {
    this.chips = this.selectedOptions.map(x => {
      const chip: IFilterItemChip = {
        label: x.label,
        value: x.value
      };
      return chip;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
