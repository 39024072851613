import { Component, Input, OnDestroy, OnInit, Pipe } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { Modal } from 'src/app/shared/modals/modal';
import { IUserPreferencesData_AdminEventShopItemsFilter } from 'src/app/shared/models/user-preference/user-preference.model';
import { UserPreferencesStoreService } from 'src/app/shared/services/store/user-preferences-store.service';
import { EventShopItemFilterItemType, IEventShopItemFilterItem } from '../event-shop-items-filter/event-shop-items-filter.component';
import { EventSiFilterTypeItemComponent } from './event-si-filter-type-item/event-si-filter-type-item.component';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { InputTextModule } from 'primeng/inputtext';

@Pipe({
  name: 'searchFilter',
  standalone: true
})
class SearchFilterPipe {
  constructor(
    private translate: TranslateService
  ) {}
  transform(value: EventShopItemFilterItemType[], search: string) {
    if (!search) return value;
    const searchLower = search.toLowerCase();
    return value.filter(x => this.translate.instant(`EventShopItemFilterItemType.${x}`).toLowerCase().includes(searchLower));
  }
}


@Component({
  selector: 'app-event-shop-item-filter-toggler-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule, TranslateModule,
    ButtonModule, CheckboxModule, InputTextModule,
    ModalHeaderComponent, EventSiFilterTypeItemComponent,
    CdkDragHandle, CdkDrag, CdkDropList, SearchFilterPipe
  ],
  templateUrl: './event-shop-item-filter-toggler-modal.component.html',
  styleUrl: './event-shop-item-filter-toggler-modal.component.scss'
})
export class EventShopItemFilterTogglerModalComponent extends Modal implements OnInit, OnDestroy {

  @Input({required: true}) selectedFilterItems: IEventShopItemFilterItem[] = [];
  @Input({required: true}) eventUuid: string | undefined;

  searchFC = new FormControl<string>('', { nonNullable: true });

  userPreferencesState = this.userPreferencesStore.state;
  selectedFilterTypes: EventShopItemFilterItemType[] = [];

  saving: boolean = false;
  subs: Subscription[] = [];

  constructor(
    private userPreferencesStore: UserPreferencesStoreService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initSelectedFilterTypes();
  }

  private initSelectedFilterTypes() {
    this.selectedFilterTypes = this.selectedFilterItems.map(x => x.type);
  }


  onSubmit() {
    if (this.saving) return;
    if (!this.eventUuid) return;

    const currUserPreferences = this.userPreferencesStore.state.preferences$.getValue();

    const dataToUpsert: IUserPreferencesData_AdminEventShopItemsFilter = this.selectedFilterTypes.map(x => {
      const existingItem = currUserPreferences.admin_event_shop_items_filter?.[this.eventUuid!]?.find(y => y.type === x);
      const newItem: IEventShopItemFilterItem = {
        value: null,
        type: x,
      }
      return existingItem ?? newItem;
    });

    this.saving = true;
    this.userPreferencesStore.upsert({
      admin_event_shop_items_filter: {
        ...currUserPreferences.admin_event_shop_items_filter,
        [this.eventUuid]: dataToUpsert
      }
    })?.subscribe({
      next: () => {
        this.saving = false;
        this.close();
      },
      error: () => {
        this.saving = false;
      }
    });
  }

  onSelectionChange(event: { e: CheckboxChangeEvent, type: EventShopItemFilterItemType }) {
    if (event.e.checked) {
      if (!this.selectedFilterTypes.includes(event.type)) {
        this.selectedFilterTypes.push(event.type);
      }
    } else {
      this.selectedFilterTypes = this.selectedFilterTypes.filter(x => x !== event.type);
    }
  }

  get unselectedFilterTypes () {
    return Object.values(EventShopItemFilterItemType).filter(x => !this.selectedFilterTypes.includes(x));
  }

  onFilterDropListDrop(e: CdkDragDrop<string[]>) {
    if (e.currentIndex === e.previousIndex) return;
    moveItemInArray(this.selectedFilterTypes, e.previousIndex, e.currentIndex);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
