

<ng-container *ngIf="parentForm">
  <div [formGroup]="parentForm" class="field">
    <input
      pInputText
      type="text"
      formControlName="legalName"
      [placeholder]="placeholder | translate"
      class="w-full">
  </div>
</ng-container>
