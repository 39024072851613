import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-org-terms-conditions-inputs',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    EditorModule, InputTextModule
  ],
  templateUrl: './org-terms-conditions-inputs.component.html',
  styleUrls: ['./org-terms-conditions-inputs.component.scss']
})
export class OrgTermsConditionsInputsComponent {

  @Input() parentForm: FormGroup | undefined;

}
