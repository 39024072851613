import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Modal } from '../../../modal';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../../../components/modal-header/modal-header.component';
import { IExtrasShopItemInfo } from '../extras-form-field-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { getShopItemPickerItemsForm, getShopItemPickerItemStatesFilterForm, IItemsFilterModalResult, IShopItemPickerItemSelect, IShopItemsPickerAdditionalFilters, ShopItemsPickerComponent } from 'src/app/pages/admin/org-admin/reservations/reservation-table/items-filter-modal/shop-items-picker/shop-items-picker.component';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';

@Component({
  selector: 'app-extras-shop-item-picker-modal',
  standalone: true,
  imports: [
    ButtonModule, ModalHeaderComponent, TranslateModule, ShopItemsPickerComponent
  ],
  templateUrl: './extras-shop-item-picker-modal.component.html',
  styleUrl: './extras-shop-item-picker-modal.component.scss'
})
export class ExtrasShopItemPickerModalComponent extends Modal implements OnInit, OnDestroy {
  @Input() excludeShopItemIds: number[] = [];
  @Input() sessionGroupRegistrationType: SessionGroupRegistrationType | null = null;
  @Input() singleSelect = false;

  @Output() result = new EventEmitter<IExtrasShopItemInfo[]>();

  statesForm = getShopItemPickerItemStatesFilterForm();
  itemsForm = getShopItemPickerItemsForm();
  selectedItems: IShopItemPickerItemSelect[] = [];
  initValues: IItemsFilterModalResult | null = null;
  additionalFilters: IShopItemsPickerAdditionalFilters = {
    sessionGroupRegistrationTypes: [
      null,
      SessionGroupRegistrationType.INDIVIDUAL,
      SessionGroupRegistrationType.INDIVIDUAL_SINGLE,
      SessionGroupRegistrationType.INDIVIDUAL_COLLEAGUES,
      SessionGroupRegistrationType.PARENT_KIDS_INDIVIDUAL
    ]
  };

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.additionalFilters.excludeShopItemIds = this.excludeShopItemIds;
    switch (this.sessionGroupRegistrationType) {
      case SessionGroupRegistrationType.COUPLE:
        this.additionalFilters.sessionGroupRegistrationTypes?.push(
          SessionGroupRegistrationType.COUPLE,
          SessionGroupRegistrationType.INDIVIDUALS_COUPLE
        );
        break;
      case SessionGroupRegistrationType.INDIVIDUALS_COUPLE:
        this.additionalFilters.sessionGroupRegistrationTypes?.push(
          SessionGroupRegistrationType.INDIVIDUALS_COUPLE
        );
        break;
      default:
        break;
    }
    this.initValues = {
      shopItemOptions: [],
      states: {
        showFinished: false,
        showShowPublishedPublic: true,
        showShowPublishedPrivate: true,
        showUnpublished: false,
        showWithOpenedReservations: true,
        showDraft: true
      }
    };
  }

  onSubmit() {
    if (!this.selectedItems.length) return;
    const res: IExtrasShopItemInfo[] = [];
    this.selectedItems.forEach((si) => {
      const sessions = (si.shopItem.sessionGroup?.sessions || []).sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
      res.push({
        customItemNumber: si.shopItem.customItemNumber,
        registrationType: si.shopItem.sessionGroup?.registrationType || null,
        id: si.shopItem.id,
        sessions: {
          count: sessions.length,
          first: sessions.at(0) ? {
            endAt: sessions[0].endAt,
            format: sessions[0].format,
            id: sessions[0].id,
            startAt: sessions[0].startAt,
            location: sessions[0].location || null
          } : null,
          last: sessions.at(-1) ? {
            endAt: sessions.at(-1)!.endAt,
            format: sessions.at(-1)!.format,
            id: sessions.at(-1)!.id,
            startAt: sessions.at(-1)!.startAt,
            location: sessions.at(-1)!.location || null
          } : null
        },
        title: si.shopItem.title,
        maxReservationCount: si.shopItem.maxReservationCount
      });
    });
    this.result.emit(res);
    this.close();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
  }
}
