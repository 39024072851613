

@if (FC) {
  <p-editor
    #editor
    [formControl]="FC"
    [placeholder]="placeholder ?? undefined"
    [readonly]="FC.disabled"
    [appEditorOverlay]="overlay"
    [modules]="{clipboard:{matchVisual:false}}"
    class="w-full"
    >
    <ng-template pTemplate="header">
      @if (overlay) {
        <div class="overlay-indicator">
          <span [ngbTooltip]="'admin.wysiwyg.overlay-tooltip-available.tooltip' | translate:{char: EDITOR_OVERLAY_TRIGGERING_CHAR}">
            {{ EDITOR_OVERLAY_TRIGGERING_CHAR }}
          </span>
        </div>
      }
      <ng-content select="[slot=formats]">

      </ng-content>

      <span class="ql-formats">
        <button (click)="removeFormat()" class="cust-clear" title="{{ 'admin.wysiwyg.clearFormating' | translate }}"><i class="pi pi-eraser text-lg font-medium text-color-secondary" style="margin-top: 0.102rem;"></i></button>
      </span>
      
    </ng-template>
    
  </p-editor>
}