export enum InvoiceNumberSeriesFormat {
  YYYY = 'YYYY',
  YY = 'YY',
  ORDER_NUMBER = 'ORDER_NUMBER',
  EVENT_CODE_YY = 'EVENT_CODE_YY'
}

export const InvoiceNumberSeriesFormatName = new Map<InvoiceNumberSeriesFormat, string>([
  [ InvoiceNumberSeriesFormat.YYYY, 'YYYY' ],
  [ InvoiceNumberSeriesFormat.YY, 'YY' ],
  [ InvoiceNumberSeriesFormat.ORDER_NUMBER, 'Číslo objednávky' ],
  [ InvoiceNumberSeriesFormat.EVENT_CODE_YY, 'Kód události-YY' ]
]);
