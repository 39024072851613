import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { EventStateChangeResult } from 'src/app/pages/admin/org-admin/events/admin-event/components/event-state-changer/event-state-changer.component';
import { ShopItemReservationType } from 'src/app/shared/enums/shop-item/shop-item-reservation-types.enum';
import { IEventUpdateSectionData, IOrgPrivacyPolicyData, IOrgTermsConditionsData } from 'src/app/shared/models/event/event-update-data.model';
import { IEvent, IEventStatistics } from 'src/app/shared/models/event/event.model';
import { environment } from 'src/environments/environment';
import { IEventPostData } from '../../../models/event/event-post-data.model';
import { CallableNames, DbService } from '../../db.service';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ISessionGroup } from 'src/app/shared/models/session-group/session-group.model';
import { ISession } from 'src/app/shared/models/session-group/session.model';
import { ILocation } from 'src/app/shared/models/location/location.model';
import { ISessionGroupLecturer } from 'src/app/shared/models/session-group/session-group-lecturer.model';
import { ILecturer } from 'src/app/shared/models/user/lecturer/lecturer.model';
import { IUser } from 'src/app/shared/models/user/user.model';
import { ILocket } from 'src/app/shared/models/locket/locket.model';
import { SessionFormat } from 'src/app/shared/enums/session-group/session-formats.enum';
import { SessionSpacingType } from 'src/app/pages/admin/org-admin/events/admin-event-item/modals/event-duplicate-modal/event-duplicate-modal.component';
import { IScheduleTaskData } from 'src/app/pages/admin/org-admin/components/task-scheduler-input/task-scheduler-input.component';
import { IApi } from 'src/app/shared/models/api/api.model';

export enum EventPostResultType {
  EVENT_ADDED_TO_SPECIFIED_ORGID = 'EVENT_ADDED_TO_SPECIFIED_ORGID',
  EVENT_ADDED_TO_NEW_ORGANIZATION = 'EVENT_ADDED_TO_NEW_ORGANIZATION',
};
interface IEventPostResult {
  resultType: EventPostResultType,
  newOrganizationId?: number;
};

export interface IEventGetData {
  id?: number;
  urlId?: string;
  uuid?: string;
  organizationId?: number;

  include?: {
    organization?: boolean | { include?: { invoiceProfile?: boolean } };
    brandProfile?: boolean;
  }
};

export interface IEventStateUpdateData {
  eventId: number;
  state: EventStateChangeResult;
  sendNotifications?: boolean;
  config?: {
    shopItemIds?: number[];
    shopItemReservationType?: ShopItemReservationType;
    scheduledClose: IScheduleTaskData | null;
  };
};

export interface IEventPublishableMissingInfoSetData {
  eventId: number;

  orgTos?: IOrgTermsConditionsData;
  orgPrivacyPolicy?: IOrgPrivacyPolicyData;
  ico?: string;
  legalName?: string;
};

export interface IEventDuplicateData {
  eventId: number;
  newFields: {
    title: string;
  };

  shopItems: {
    id: number;
    quantity: number;
    sessionGroup: {
      firstSession: {
        startAt: string;
        endAt: string;
        locationId: number | null;
        format: SessionFormat;
      };
      sessionSpacingType: SessionSpacingType;
      sessionCount: number;
      sessionSpecificStartDates: string[];
      sessionOnceInDays: number;
    } | null;
  }[];
}

export interface IEventGeStatisticsData {
  eventId: number;

}

interface IEventsGetForDuplicationData {
  eventId: number;
}
export interface IEventGetForDuplicationResult {
  event: IEvent & {
    shopItems: IEventGetForDuplicationResult_ShopItems;
  }
  locations: ILocation[];
}
export type IEventGetForDuplicationResult_ShopItems = (IShopItem & {
  sessionGroup: ISessionGroup & {
    sessions: (ISession & {
      
    } )[];
    sessionGroupLecturers: (ISessionGroupLecturer & {
      lecturer: ILecturer & { user: IUser & { lockets: ILocket[] } }
    })[];
  } | null;
})[];

interface IEventGetForDiscountTemplateDropdownData {
  orgId: number;

  eventIdWildcard: number | null;
}

interface ICallablesEventsGetShowPreviewData {
  eventUrlId: string; 
}

interface IEventsGetForAdminEventListData {
  organizationId: number;
}

interface IEventsGetForAdminEventOfferListData {
  organizationId: number;
}

interface IEventGetForChooseForInstanceModalData {
  orgId: number
}

interface ICallablesEventsGetForItemFilterData {
  orgId: number;
  excludeEventUuids: string[];
  showDraft: boolean;
  showPublished: boolean;
  showUnpublished: boolean;
  showFinished: boolean;
}

export interface IShopItemsGetPaymentApisResult {
  apis: IApi[];
  selectedApiIds: number[];
}

export type IEventSwitchUiIndexData = {[eventId: number]: number};

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private dbService: DbService,
  ) { }

  public updateUiIndex(data: IEventSwitchUiIndexData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsUpdateUiIndex, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForChooseForInstanceModal(data: IEventGetForChooseForInstanceModalData): Observable<IEvent[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForChooseForInstanceModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getEventOffersForAdminList(data: IEventsGetForAdminEventOfferListData): Observable<IEvent[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForAdminEventOfferList, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForAdminList(data: IEventsGetForAdminEventListData): Observable<IEvent[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForAdminEventList, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getShowPreview(data: ICallablesEventsGetShowPreviewData): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetShowPreview, data });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

  public getForDiscountTemplateEventDropdown(data: IEventGetForDiscountTemplateDropdownData): Observable<{id: number, title: string}[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForDiscountTemplateDropdown, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForDuplication(data: IEventsGetForDuplicationData): Observable<IEventGetForDuplicationResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForDuplication, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getStatistics(data: IEventGeStatisticsData): Observable<IEventStatistics> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventGetStatistics, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public postEvent(data: IEventPostData): Observable<IEventPostResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsPost, data });
    return this.dbService.handleObs(obs);
  }

  public duplicateEvent(data: IEventDuplicateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsDuplicate, data });
    return this.dbService.handleObs(obs);
  }

  public getSingle(data: IEventGetData): Observable<IEvent | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGet, data });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public updateState(data: IEventStateUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsUpdateState, data });
    return this.dbService.handleObs(obs.pipe(catchError(e => {
      if (e.details?.eventMissingData) {
        return of({ data: { ...e.details }, noToast: true });
      }
      throw e;
    })));
  }

  public updateSection(data: IEventUpdateSectionData, succSilent: boolean = false) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsUpdateSection, data });
    return this.dbService.handleObs(obs, { succSilent });
  }


  public getUniqueUrlId(title: string) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetUniqueUrlId, data: title });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public archiveEvent(id: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsArchive, data: id });
    return this.dbService.handleObs(obs);
  }
  public unarchiveEvent(id: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsUnarchive, data: id });
    return this.dbService.handleObs(obs);
  }

  public sendEventReservationConfirmTestEmail(eventId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsSendTestReservationConfirmEmail, data: { eventId } });
    return this.dbService.handleObs(obs);
  }

  public getPaymentApis(eventId: number): Observable<IShopItemsGetPaymentApisResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetPaymentApis, data: { eventId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public setEventPublishableMissingInfo(data: IEventPublishableMissingInfoSetData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsSetPublishableMissingInfo, data });
    return this.dbService.handleObs(obs);
  }

  public getForItemFilter(data: ICallablesEventsGetForItemFilterData): Observable<IEvent[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsGetForItemFilter, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public setApis(data: { apiIds: number[]; eventId: number; }): Observable<true> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesEventsConnectApis, data });
    return this.dbService.handleObs(obs);
  }


  public getEventUrl(urlId: string) {
    return `${environment.baseUrl}/${urlId}`;
  }
}
