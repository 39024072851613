import { Component, Input } from '@angular/core';
import { IReservationTableEntity, ReservationTableEntityType } from 'src/app/shared/services/store/org-reservations-store.service';
import { PriceCellReservationEntityComponent } from './price-cell-reservation-entity/price-cell-reservation-entity.component';
import { PriceCellInvitationEntityComponent } from './price-cell-invitation-entity/price-cell-invitation-entity.component';

@Component({
  selector: 'td[app-price-cell]',
  standalone: true,
  imports: [
    PriceCellReservationEntityComponent, PriceCellInvitationEntityComponent
  ],
  templateUrl: './price-cell.component.html',
  styleUrls: ['./price-cell.component.scss'],
})
export class PriceCellComponent {
  ReservationTableEntityType = ReservationTableEntityType;

  _reservation: IReservationTableEntity | undefined;
  get reservation() { return this._reservation; }
  @Input() set reservation(val: IReservationTableEntity | undefined) {
    this._reservation = val;
  }

  constructor(
  ) {
  }

  
}
