


<p-listbox
  #listbox
  [filter]="menuItems.length > 5"
  [filterFields]="['label', 'shortCode']"
  [options]="menuItems"
  (onClick)="onMenuItemClick($event)"
  styleClass="w-17rem">

  <ng-template pTemplate="item" let-item>
    <div class="flex flex-column">
      <p class="m-0 line-height-1">
        {{ item.label }}
      </p>
      <p class="m-0 text-xs text-bluegray-200">
        {{ item.shortCodeValue }}
      </p>
      @if (item.exampleValue !== null) {
        <p class="m-0 text-xs text-primary">
          @if (item.exampleIsHtml) {
            <span [innerHTML]="item.exampleValue"></span>
          }
          @else if (isAsyncaable(item.exampleValue)) {
            {{ item.exampleValue | async }}
          } @else {
            {{ item.exampleValue }}
          }
        </p>
      }
    </div>
  </ng-template>

</p-listbox>