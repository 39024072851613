import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { UsersService } from 'src/app/shared/services/entities/users/users.service';
import { finalize, Subject, Subscription, take } from 'rxjs';
import { AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUser } from 'src/app/shared/models/user/user.model';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { Currency } from 'src/app/shared/enums/price/currencies.enum';
import { ICreateCustomerCreditCallableData, IRecordPaymentData, ITransfersPair, TransfersService } from 'src/app/shared/services/entities/transfers/transfers.service';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { ListboxModule } from 'primeng/listbox';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { TagModule } from 'primeng/tag';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { OrgPaymentsStoreService } from 'src/app/shared/services/store/org-payments-store.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SelectItem } from 'primeng/api';
import { UserStoreService, IUserState } from 'src/app/shared/services/store/user-store.service';
import { RecordPaymentModalType } from 'src/app/shared/services/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { TransferState } from 'src/app/shared/enums/transfer/transfer-states.enum';
import { TransferProvider } from 'src/app/shared/enums/transfer/transfer-providers.enum';
import { TransferPaymentMethod } from 'src/app/shared/enums/transfer/transfer-payment-methods';
import { AppMessageService } from 'src/app/shared/services/app-message.service';

interface IRecordPaymentForm {
  selectedUser: FormControl<IUser | null>;
  usersQuery: FormControl<{ label: string }>;
  addCredit: FormControl<boolean | null>;
  note: FormControl<string | null>;
  amount?: FormControl<number | null>;
  currency?: FormControl<Currency | null>;
  paidAt?: FormControl<Date>;
  selectedUnpaidTransfer: FormControl<ITransfer | null>;
  transferPaymentMethod: FormControl<TransferPaymentMethod | null>;
};

@Component({
  selector: 'app-record-payment-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    ButtonModule, AutoCompleteModule, InputNumberModule, DropdownModule, ProgressSpinnerModule, ListboxModule, TagModule, InputTextareaModule, InputMaskModule, SelectButtonModule,
    ModalHeaderComponent, PricePipe, LocalizedDatePipe, TranslateModule, NumberInputDirective, NgbTooltipModule, CalendarModule
  ],
  providers: [
    PricePipe,
  ],
  templateUrl: './record-payment-modal.component.html',
  styleUrls: ['./record-payment-modal.component.scss']
})
export class RecordPaymentModalComponent extends Modal implements OnInit, OnDestroy, AfterViewInit {
  RecordPaymentModalType = RecordPaymentModalType;
  TransferState = TransferState;

  @Input() user?: IUser;
  @Input() orderId?: number | null;
  @Input() userName?: string;
  @Input() modalType?: RecordPaymentModalType;
  @Input() unpairedIncomeTransfer?: ITransfer;
  @Input() refreshReservation$?: Subject<void>;

  userSuggestions: IUser[] = [];
  unpaidTransfers: (ITransfer & { unpaid: IPrice; disabled: boolean; })[] = [];
  transfers: (ITransfer & { unpaid: IPrice | null; })[] = [];

  transferPaymentMethodOptions: SelectItem[] = [];

  refreshingTransfersMap: { [transferId: number]: boolean } = {};
  cancellingTransfersMap: { [transferId: number]: boolean } = {};

  currencyOptions: SelectItem[] = Object.values(Currency).map((currency) => ({ value: currency }));
  addCreditOptions: SelectItem[] = [
    {
      value: false,
      label: 'admin.payments.record-payment-modal.credit-option.pay-order.label'
    },
    {
      value: true,
      label: 'admin.payments.record-payment-modal.credit-option.transfer-to-credit.label'
    }
  ];

  form = new FormGroup<IRecordPaymentForm>({
    selectedUser: new FormControl(null),
    usersQuery: new FormControl({ label: '' }, { nonNullable: true }),
    addCredit: new FormControl(null, { validators: [Validators.required] }),
    note: new FormControl(null),
    selectedUnpaidTransfer: new FormControl(null, { validators: [ Validators.required ] }),
    transferPaymentMethod: new FormControl<TransferPaymentMethod | null>(null, { nonNullable: true })
  });

  maxPaidAtDate = new Date().addDays(1);

  userState: IUserState | undefined;
  sending: boolean = false;
  fetchingTransfers: boolean = false;
  subs: Subscription[] = [];

  @ViewChild('queryInput') queryInput?: AutoComplete;

  constructor(
    private usersService: UsersService,
    private selectedOrgService: SelectedOrgService,
    private transfersService: TransfersService,
    private utilsService: UtilsService,
    private orgPaymentsStore: OrgPaymentsStoreService,
    private userStore: UserStoreService,
    private userNameOrEmailPipe: UserNameOrEmailPipe,
    private translate: TranslateService,
    private appMessageService: AppMessageService,
    private pricePipe: PricePipe
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;

    if (this.modalType === RecordPaymentModalType.RECORD_PAYMENT) {
      this.form.addControl('amount', new FormControl(null, { validators: [ Validators.required ] }));
      this.form.addControl('currency', new FormControl({ value: this.currencyOptions[0].value, disabled: true }, { nonNullable: true, validators: [Validators.required] }));
      this.form.addControl('paidAt', new FormControl(new Date(), { nonNullable: true, validators: [Validators.required] }));
      this.form.controls.transferPaymentMethod.addValidators(Validators.required);
    }
    this.userState = this.userStore.state;
    this.userState.user$.pipe(take(1)).subscribe((user) => {
      this.noteFC.patchValue(`${this.userNameOrEmailPipe.transform(user?.orgUsers??[], orgId, true)}`);
    });
    if (this.orderId && this.user) {
      this.form.controls.usersQuery.setValue({ label: `${this.userNameOrEmailPipe.transform(this.user.orgUsers, orgId, false)}` });
      this.form.controls.selectedUser.setValue(this.user);
      this.form.controls.addCredit.setValue(false);
      this.fetchingTransfers = true;
      this.transfersService.getUserOrgUnpaid({
        organizationId: orgId, userId: this.user.id, orderId: this.orderId, includePaid: true
      }).pipe(
        take(1),
        finalize(() => this.fetchingTransfers = false)
      ).subscribe({
        next: (res) => {
          this.transfers = res.allTransfers;
          this.unpaidTransfers = res.unpaidTransfers.map((r) => ({
            ...r,
            disabled: this.unpairedIncomeTransfer
              ? (this.unpairedIncomeTransfer.currency !== r.currency)
              : false
          }));
          if (res.unpaidTransfers.length > 0) {
            this.form.controls.selectedUnpaidTransfer.setValue(res.unpaidTransfers[0]);
          }
        }
      });
    }
    this.watchForm();
    if (this.userName) {
      this.form.controls.usersQuery.setValue({ label: this.userName }, { onlySelf: true, emitEvent: true });
    }

    this.getPaymentMethodOptions();
    this.subs.push(
      this.translate.onLangChange.subscribe(() => {
        this.getPaymentMethodOptions();
      })
    )
  }

  private getPaymentMethodOptions() {
    this.transferPaymentMethodOptions = [
      {
        value: TransferPaymentMethod.CASH,
        label: this.translate.instant('admin.payments.record-payment-modal.payment-method.CASH')
      },
      {
        value: TransferPaymentMethod.BANK_TRANSFER,
        label: this.translate.instant('admin.payments.record-payment-modal.payment-method.BANK_TRANSFER')
      },
      {
        value: TransferPaymentMethod.INTERNAL_CUSTOMER_CREDIT,
        label: this.translate.instant('admin.payments.record-payment-modal.payment-method.INTERNAL_CUSTOMER_CREDIT')
      },
    ]
  }

  ngAfterViewInit(): void {
    if (this.queryInput) {
      if (!this.user) this.queryInput.inputEL?.nativeElement.focus();
    }
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();
    if ((!formVals.selectedUnpaidTransfer && !formVals.addCredit) || (this.modalType === RecordPaymentModalType.RECORD_PAYMENT && (!formVals.amount || !formVals.currency || !formVals.transferPaymentMethod))) {
      console.error('invalid');
      return;
    }

    switch (this.modalType) {
      case RecordPaymentModalType.PAIR_PAYMENT:
        if (!this.unpairedIncomeTransfer) {
          console.error('Missing unpairedIncomeTransferId!');
          return;
        }
        let pairTransferData: ITransfersPair;
        if (formVals.addCredit) {
          pairTransferData = {
            adminNote: formVals.note,
            unpairedIncomeTransferId: this.unpairedIncomeTransfer.id,
            userId: formVals.selectedUser!.id,
            toCredit: true
          };
        } else {
          pairTransferData = {
            adminNote: formVals.note,
            unpairedIncomeTransferId: this.unpairedIncomeTransfer.id,
            userId: formVals.selectedUser!.id,
            unpaidTransferId: formVals.selectedUnpaidTransfer!.id
          };
        }
        this.sending = true;
        this.orgPaymentsStore.pairTransfer(pairTransferData).pipe(
          take(1),
          finalize(() => this.sending = false)
        ).subscribe({
          next: () => {
            this.refreshReservation$?.next();
            this.close();
          }
        });
        break;
      case RecordPaymentModalType.RECORD_PAYMENT:
        if (formVals.addCredit) {
          const organizationId = this.selectedOrgService.getCurrentValue();
          if (!organizationId) {
            console.error('missing selected organization');
            return;
          };
          const data: ICreateCustomerCreditCallableData = {
            adminNote: formVals.note,
            amount: formVals.amount!,
            currency: formVals.currency!,
            userId: formVals.selectedUser!.id,
            organizationId: organizationId
          };
          this.sending = true;
          this.orgPaymentsStore.createCustomerCredit(data).pipe(
            take(1),
            finalize(() => this.sending = false)
          ).subscribe({
            next: (res) => {
              this.refreshReservation$?.next();
              this.close();
            }
          });
        } else {
          const data: IRecordPaymentData = {
            unpaidTransferId: formVals.selectedUnpaidTransfer!.id,
            paidAmount: formVals.amount!,
            adminNote: formVals.note,
            paidAt: formVals.paidAt!.toISOString(),
            paymentMethod: formVals.transferPaymentMethod!
          };
          this.sending = true;
          this.orgPaymentsStore.recordPayments([data], true).pipe(
            take(1),
            finalize(() => this.sending = false)
          ).subscribe({
            next: (res) => {
              this.refreshReservation$?.next();
              this.close();
            },
            error: (err) => {
              if (err?.details?.internalCreditBalance) {
                const errorTitle = 'be.callables.transfers.record-payment.insufficient-internal-credit.title';
                this.appMessageService.errorMessage(err, errorTitle, { detail: { price: this.pricePipe.createPriceString(err.details.internalCreditBalance) } }, { life: 7000 });
              } else {
                this.appMessageService.errorMessage(err);
              }
            }
          });
        }
        break;
      default:
        break;
    }
  }

  private watchForm() {
    this.subs.push(
      this.usersQueryFC.valueChanges.subscribe(v => {
        this.selectedUserFC.setValue(null);
        this.unpaidTransfers = [];
      }),

      this.selectedUserFC.valueChanges.subscribe(u => {
        if (u) {
          const orgId = this.selectedOrgService.getCurrentValue();
          if (!orgId) return;
          // get unpaid transfers
          this.fetchingTransfers = true;
          this.transfersService.getUserOrgUnpaid({
            organizationId: orgId, userId: u.id
          }).pipe(
            take(1),
            finalize(() => this.fetchingTransfers = false)
          ).subscribe({
            next: (res) => {
              this.unpaidTransfers = res.unpaidTransfers.map((r) => ({
                ...r,
                disabled: this.unpairedIncomeTransfer
                  ? (this.unpairedIncomeTransfer.currency !== r.currency)
                  : false
              }));
            }
          });
        }
      }),

      this.selectedUnpaidTransferFC.valueChanges.subscribe((transfer) => {
        if (transfer) {
          this.currencyFC?.patchValue(transfer.currency);
        }
      }),

      this.addCreditFC.valueChanges.subscribe((addCredit) => {
        if (addCredit) {
          this.currencyFC?.enable();
          this.selectedUnpaidTransferFC.removeValidators(Validators.required);
          this.selectedUnpaidTransferFC.updateValueAndValidity();
        } else {
          this.currencyFC?.disable();
          if (this.selectedUnpaidTransferFC.value) {
            this.currencyFC?.patchValue(this.selectedUnpaidTransferFC.value.currency);
          }
          this.selectedUnpaidTransferFC.addValidators(Validators.required);
          this.selectedUnpaidTransferFC.updateValueAndValidity();
        }
      })
    );
  }

  whisper(e: { originalEvent: Event, query: string }) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;
    this.usersService.whisper({
        query: e.query,
        organizationIdCustomer: orgId
    }).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        this.userSuggestions = res.map(x => ({ ...x, label: `${this.userNameOrEmailPipe.transform(x.orgUsers, orgId, true)} ${x.email}` }));
      }
    });
  }

  onTransferRefresh(transferId: number) {
    this.refreshingTransfersMap[transferId] = true;
    this.transfersService.getPaymentStatus({ transferId }).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        const foundTransferIndex = this.transfers.findIndex(x => x.id === transferId);
        switch (res.state) {
          case TransferState.COMPLETED:
            this.transfers[foundTransferIndex] = {
              ...res,
              unpaid: null
            };
            this.refreshReservation$?.next();
            this.reduceUnpaidTransferValue(res, res.amount);
            const foundUnpaidTransferIndex = this.unpaidTransfers.findIndex(x => x.orderId === this.transfers[foundTransferIndex].orderId);
            if (foundUnpaidTransferIndex !== -1) {
              const foundUnpaidTransfer = this.unpaidTransfers[foundUnpaidTransferIndex];
              foundUnpaidTransfer.unpaid.value! -= res.amount;
              if (!foundUnpaidTransfer.unpaid.value) {
                this.unpaidTransfers.splice(foundUnpaidTransferIndex, 1);
                if (this.selectedUnpaidTransferFC.value?.id === foundUnpaidTransfer.id) {
                  this.selectedUnpaidTransferFC.setValue(null);
                }
              }
            }
            break;
          case TransferState.CANCELLED:
            this.transfers.splice(foundTransferIndex, 1);
            break;
          case TransferState.PENDING:
          default:
            break;
        }
        this.refreshingTransfersMap[transferId] = false;
      },
      error: (err) => {
        this.refreshingTransfersMap[transferId] = false;
      }
    });
  }

  onTransferCancel(transferId: number) {
    this.cancellingTransfersMap[transferId] = true;
    this.transfersService.cancel({ transferId }).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.cancellingTransfersMap[transferId] = false;
        const foundTransferIndex = this.transfers.findIndex(x => x.id === transferId);
        if (res.state === TransferState.CANCELLED) {
          this.transfers.splice(foundTransferIndex, 1);
        }
        if (res.state === TransferState.COMPLETED) {
          this.refreshReservation$?.next();
          this.transfers[foundTransferIndex] = {
            ...res,
            unpaid: null
          };
          this.reduceUnpaidTransferValue(res, res.amount);
        }
      },
      error: (err) => {
        this.cancellingTransfersMap[transferId] = false;
      }
    });
  }

  private reduceUnpaidTransferValue(transfer: ITransfer, amount: number) {
    const foundUnpaidTransferIndex = this.unpaidTransfers.findIndex(x => x.orderId === transfer.orderId);
    if (foundUnpaidTransferIndex !== -1) {
      const foundUnpaidTransfer = this.unpaidTransfers[foundUnpaidTransferIndex];
      foundUnpaidTransfer.unpaid.value! -= amount;
      if (!foundUnpaidTransfer.unpaid.value) {
        this.unpaidTransfers.splice(foundUnpaidTransferIndex, 1);
        if (this.selectedUnpaidTransferFC.value?.id === foundUnpaidTransfer.id) {
          this.selectedUnpaidTransferFC.setValue(null);
        }
      }
    }
  }

  onUserSuggestionSelect(e: AutoCompleteSelectEvent) {
    this.selectedUserFC.setValue(e.value);
  }

  get selectedUserFC() {
    return this.form.controls['selectedUser'];
  }
  get usersQueryFC() {
    return this.form.controls['usersQuery'];
  }
  get selectedUnpaidTransferFC() {
    return this.form.controls['selectedUnpaidTransfer'];
  }
  get addCreditFC() {
    return this.form.controls['addCredit'];
  }
  get currencyFC() {
    return this.form.controls['currency'];
  }
  get noteFC() {
    return this.form.controls['note'];
  }


  override ngOnDestroy(): void {
    super.ngOnDestroy();
    
    this.subs.forEach(s => s.unsubscribe());
  }
}
