


<div (click)="multiselectEl.show()" class="filter-wrap relative" [class.loading-box]="fetchingTags">
  <p class="filter-title">
    {{ 'admin.event-shop-items-filter.internal-tags.label' | translate }}
  </p>

  <app-filter-item-chips
    [chips]="chips"
    emptyMessage="admin.event-shop-items-filter.internal-tags.all"
    (remove)="onRemoveChip($event)">
  </app-filter-item-chips> 

  <!-- todo use virtualscroll - was bugged when writing... from 7 options showed only 3-->
  <p-multiSelect
    #multiselectEl
    [formControl]="FC"
    [options]="tagsOptions"
    [group]="true"
    [optionValue]="'value'"
    [optionLabel]="'label'"
    [overlayOptions]="{appendTo: 'body'}"
    (onPanelShow)="fetchTags()">
  </p-multiSelect>
</div>