import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
  static minArrayLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!Array.isArray(control.value)) {
        return { notArray: true };
      }
  
      return control.value.length >= minLength ? null : { minArrayLength: { requiredLength: minLength, actualLength: control.value.length } };
    };
  }

  /** Used when there are 2 controls for min/max inputs - to ensure min isn't higher than max.. */
  static minMaxControlsValidator(minControlName: string, maxControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const minControl = control.get(minControlName);
      const maxControl = control.get(maxControlName);
      if (!minControl || !maxControl) {
        console.error('minMaxControlsValidator: min or max control not found');
        return null;
      }

      if (minControl.value === null || maxControl.value === null) {
        return null;
      }

      [minControl, maxControl].forEach((control) => {
        const errors = control.errors || {};
        delete errors?.['minHigherThanMax'];
        delete errors?.['maxLowerThanMin'];
        control.setErrors(Object.keys(errors).length > 0 ? errors : null);
      });

      if (minControl.value > maxControl.value) {
        [minControl, maxControl].forEach((control) => {
          control.setErrors({
            ...(control.errors || {}),
            minHigherThanMax: true
          });
        });
        return { minHigherThanMax: true };
      }
      if (maxControl.value < minControl.value) {
        [minControl, maxControl].forEach((control) => {
          control.setErrors({
            ...(control.errors || {}),
            maxLowerThanMin: true
          });
        });
        return { maxLowerThanMin: true };
      }
      return null;
    };
  } 

  static ibanValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let iban: string | null = control.value;
      if (!iban) return null;
  
      // Remove spaces and convert to uppercase
      iban = iban.replace(/\s+/g, '').toUpperCase();

      // IBAN should be between 15 and 34 characters
      if (iban.length < 15 || iban.length > 34 || !iban.match(/^[A-Z]{2}[\d]{13,32}$/)) {
        return { invalidIban: true };
      }

      // Move the first four characters to the end
      const rearranged = iban.slice(4) + iban.slice(0, 4);

      // Replace each letter with its corresponding number (A=10, B=11, ..., Z=35)
      const numericIban = rearranged.replace(/[A-Z]/g, (char: string) =>
        (char.charCodeAt(0) - 55).toString()
      );

      // Perform the modulo 97 operation
      let checksum = '';
      for (const digit of numericIban) {
        checksum = (BigInt(checksum + digit) % 97n).toString();
      }

      // Valid IBANs have a checksum of 1
      return checksum === '1' ? null : { invalidIban: true };
    };
  }

  static maskInputValidator(minLength?: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlTyped = control as FormControl<string | null>;
      const value = controlTyped.value;
      if (!value) return null;
      if (minLength) {
        console.log(value);
        console.log(minLength);
        if (value.replace(/_/g, '').length < minLength) {
          return { invalidMaskInput: true };
        }
        return null;
      }
      if (value.includes('_')) {
        return { invalidMaskInput: true };
      }
      return null;
    };
  }
}
