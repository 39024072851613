

<app-modal-header
  (close)="close()">
  <span slot="title">
    @if (issueNewInvoice) {
      {{ 'admin.customer-org-orders.invoice.modify.modal.issue-new.title' | translate }}
    } @else {
      <ng-container *ngIf="proForma === true">
        {{ 'admin.customer-org-orders.invoice.modify.modal.pro-forma.title' | translate }}
      </ng-container>
      <ng-container *ngIf="proForma === false">
        {{ 'admin.customer-org-orders.invoice.modify.modal.invoice.title' | translate }}
      </ng-container>
    }
  </span>
  @if (issueNewInvoice) {
    <span slot="subtitle">
      {{ 'admin.customer-org-orders.invoice.modify.modal.issue-new.subtitle' | translate:{ orderNumber: (order | orderNumber) } }}
    </span>
  }
</app-modal-header>

<div [formGroup]="form" class="modal-body">

  <div class="grid pt-2">

    <div class="field col-12">
      <span class="p-float-label">
        <input type="text" id="invoiceNumber" pInputText formControlName="invoiceNumber" class="w-full">
        <label for="invoiceNumber">{{ 'admin.customer-org-orders.invoice.modify.modal.form.invoice-number.label' | translate }}</label>
      </span>
    </div>


    <div class="field col-12">
      <span class="p-float-label">
        <input type="text" id="legalName" pInputText formControlName="legalName" class="w-full">
        <label for="legalName">{{ 'admin.customer-org-orders.invoice.modify.modal.form.legal-name.label' | translate }}</label>
      </span>
    </div>

    <div class="field col-12">
      <span class="p-float-label">
        <input (click)="onOrgAddressChange()" readonly type="text" id="address" pInputText [value]="form.controls.addressStructured.getRawValue() | address" class="w-full">
        <label for="address">{{ 'admin.customer-org-orders.invoice.modify.modal.form.address.label' | translate }}</label>
      </span>
    </div>

    <div class="field col-12 mb-0">
      <span class="p-float-label">
        <input type="text" id="companyNumber" pInputText formControlName="companyNumber" class="w-full">
        <label for="companyNumber">{{ 'admin.customer-org-orders.invoice.modify.modal.form.company-number.label' | translate }}</label>
      </span>
    </div>

    <div class="field col-12 mt-0">
      <p-checkbox [binary]="true" formControlName="vatPayer" label="{{ 'admin.customer-org-orders.invoice.modify.modal.form.vat-payer.label' | translate }}"></p-checkbox>
    </div>

    <div *ngIf="form.controls.vatPayer.value" class="field col-12">
      <span class="p-float-label">
        <input type="text" id="vatNumber" pInputText formControlName="vatNumber" class="w-full">
        <label for="vatNumber">{{ 'admin.customer-org-orders.invoice.modify.modal.form.vat-number.label' | translate }}</label>
      </span>
    </div>

    <div class="field col-12 mt-0">
      <span class="p-float-label">
        <label>{{ 'admin.customer-org-orders.invoice.modify.modal.form.payment-method.label' | translate }}</label>
        <p-selectButton [options]="apiTypeOptions" formControlName="apiType" optionLabel="label" optionValue="value" class="w-full">
          <ng-template let-item pTemplate>
            {{ item.label | translate }}
          </ng-template>    
        </p-selectButton>
      </span>
    </div>

    <p-divider></p-divider>

    
    <div *ngIf="!invoice?.payerCompanyBillingInfo?.name" class="field col-12">
      <span class="p-float-label">
        <input type="text" id="payerName" pInputText formControlName="payerName" class="w-full">
        <label for="payerName">{{ 'admin.customer-org-orders.invoice.modify.modal.form.payer-name.label' | translate }}</label>
      </span>
    </div>

    <ng-container formGroupName="payerCompanyBillingInfo">
      <div *ngIf="invoice?.payerCompanyBillingInfo?.name" class="field col-12">
        <span class="p-float-label">
          <input type="text" id="payerName" pInputText formControlName="name" class="w-full">
          <label for="payerName">{{ 'admin.customer-org-orders.invoice.modify.modal.form.payer-name.label' | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input type="text" id="ico" pInputText formControlName="ico" class="w-full">
          <label for="ico">{{ 'admin.customer-org-orders.invoice.modify.modal.form.ico.label' | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input type="text" id="vatNumber" pInputText formControlName="vatNumber" class="w-full">
          <label for="vatNumber">{{ 'admin.customer-org-orders.invoice.modify.modal.form.payer-vat.label' | translate }}</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input (click)="onPayerAddressChange()" readonly type="text" id="payerAddress" pInputText [value]="form.controls.payerCompanyBillingInfo.controls.address.getRawValue() | address" class="w-full">
          <label for="payerAddress">{{ 'admin.customer-org-orders.invoice.modify.modal.form.payer-address.label' | translate }}</label>
        </span>
      </div>
  
    </ng-container>


    <p-divider></p-divider>

    <div class="field col-12">
      <app-date-input *ngIf="form.controls.createdAt as FC" floatingLabel="{{ 'admin.customer-org-orders.invoice.modify.modal.form.created-at.label' | translate }}" [FC]="FC"></app-date-input>
    </div>
    
    <div class="field col-12">
      <app-date-input *ngIf="form.controls.maturity as FC" floatingLabel="{{ 'admin.customer-org-orders.invoice.modify.modal.form.maturity.label' | translate }}" [FC]="FC"></app-date-input>
    </div>

    @if (paidAtFC) {
      <div class="field col-12">
        <app-date-input floatingLabel="{{ 'admin.customer-org-orders.invoice.modify.modal.form.paid-at.label' | translate }}" [FC]="paidAtFC"></app-date-input>
      </div>
    }
    
    <div *ngIf="form.controls.vatPayer.value" class="field col-12">
      <app-date-input *ngIf="form.controls.taxableTransactionDate as FC" floatingLabel="{{ 'admin.customer-org-orders.invoice.modify.modal.form.taxable-date.label' | translate }}" [FC]="FC"></app-date-input>
    </div>
  
    <div class="field col-12">
      <span class="p-float-label">
        <textarea pInputTextarea formControlName="text" class="w-full"></textarea>
        <label>{{ 'admin.customer-org-orders.invoice.modify.modal.form.invoice-text.label' | translate }}</label>
      </span>
    </div>

    <p-divider></p-divider>


    <h6 class="col-12 m-0 mt-2">
      {{ 'admin.customer-org-orders.invoice.modify.modal.form.invoice-items.label' | translate }}
    </h6>

    <div formArrayName="invoiceItems" class="field col-12 py-0 flex flex-column">
      <ng-container *ngFor="let FG of form.controls.invoiceItems.controls; let i = index">
        <div [formGroupName]="i" class="flex">

          <div class="field py-0 mr-2">
            <span class="flex flex-column">
              <label class="text-xs" *ngIf="i === 0" for="quantity-{{ i }}">{{ 'admin.customer-org-orders.invoice.modify.modal.form.quantity.label' | translate }}</label>
              <p-inputNumber id="quantity-{{ i }}" [min]="1" formControlName="count" class="p-fluid" [inputStyle]="{'width': '2ch', 'box-sizing': 'content-box'}"></p-inputNumber>
            </span>
          </div>
          <div class="field py-0 mx-2 flex-1">
            <span class="flex flex-column">
              <label class="text-xs" *ngIf="i === 0" for="item-{{ i }}">{{ 'admin.customer-org-orders.invoice.modify.modal.form.item-title.label' | translate }}</label>
              <input type="text" id="item-{{ i }}" pInputText formControlName="title" class="w-full">
            </span>
          </div>
          <div class="field py-0 mx-2">
            <span class="flex flex-column">
              <label class="text-xs" *ngIf="i === 0" for="base-price-{{ i }}">{{ 'admin.customer-org-orders.invoice.modify.modal.form.unit-price.label' | translate }}</label>
              <p-inputNumber id="base-price-{{ i }}" mode="decimal" suffix=" {{currency}}" [maxFractionDigits]="2" formControlName="unitPriceWithoutVat" class="w-full p-fluid" [inputStyle]="{'width': '10ch', 'box-sizing': 'content-box'}"></p-inputNumber>
              @if (FG.controls.unitPriceWithoutVat.hasError('min')) {
                <small class="text-red-500">{{ 'admin.customer-org-orders.invoice.modify.modal.form.min-error.label' | translate:{ min: minMaxIndexMap[i].min } }}</small>
              }
              @if (FG.controls.unitPriceWithoutVat.hasError('max')) {
                <small class="text-red-500">{{ 'admin.customer-org-orders.invoice.modify.modal.form.max-error.label' | translate:{ max: minMaxIndexMap[i].max } }}</small>
              }
            </span>
          </div>
          <div class="field py-0 mx-2">
            <span class="flex flex-column">
              <label class="text-xs" *ngIf="i === 0" for="vat-{{ i }}">{{ 'admin.customer-org-orders.invoice.modify.modal.form.vat.label' | translate }}</label>
              <p-inputNumber id="vat-{{ i }}" formControlName="vat" class="p-fluid" [min]="0" [inputStyle]="{'width': '2ch', 'box-sizing': 'content-box'}"></p-inputNumber>
            </span>
          </div>
          <div class="field py-0 ml-2">
            <span class="flex flex-column">
              <label class="text-xs" *ngIf="i === 0" for="total-price-{{ i }}">{{ 'admin.customer-org-orders.invoice.modify.modal.form.unit-price-with-vat.label' | translate }}</label>
              <p-inputNumber id="total-price-{{ i }}" mode="decimal" suffix=" {{currency}}" [maxFractionDigits]="2" formControlName="unitPriceWithVat" class="w-full p-fluid" [inputStyle]="{'width': '10ch', 'box-sizing': 'content-box'}"></p-inputNumber>
              @if (FG.controls.unitPriceWithVat.hasError('min')) {
                <small class="text-red-500">{{ 'admin.customer-org-orders.invoice.modify.modal.form.min-error.label' | translate:{ min: minMaxIndexMap[i].min } }}</small>
              }
              @if (FG.controls.unitPriceWithVat.hasError('max')) {
                <small class="text-red-500">{{ 'admin.customer-org-orders.invoice.modify.modal.form.max-error.label' | translate:{ max: minMaxIndexMap[i].max } }}</small>
              }
            </span>
          </div>

        </div>
      </ng-container>
    </div>

    <p-divider></p-divider>

    <div class="field col-12">
      <span class="p-float-label">
        <input type="text" id="invoiceFooterText" pInputText formControlName="invoiceFooterText" class="w-full">
        <label for="invoiceFooterText">{{ 'admin.customer-org-orders.invoice.modify.modal.form.invoice-footer-text.label' | translate }}</label>
      </span>
    </div>


  </div>



<!-- 
  <div class="field col-12">
    <span class="p-float-label">
      <input type="text" id="" pInputText formControlName="" class="w-full">
      <label for=""></label>
    </span>
  </div> -->

</div>

<div class="modal-footer flex-column" [class.loading-box]="fetchingFinalInvoice">

  @if (maturityValueChanged) {
    <div class="flex flex-column align-self-start">
      <p-checkbox [binary]="true" [formControl]="form.controls.changeMaturityInOrder" label="{{ 'admin.customer-org-orders.invoice.modify.modal.form.maturity.change-order.label' | translate }}"></p-checkbox>
      <p-checkbox [binary]="true" [formControl]="form.controls.changeMaturityOtherInvoices" label="{{ 'admin.customer-org-orders.invoice.modify.modal.form.maturity.change-invoices.label' | translate }}"></p-checkbox>
    </div>
  }

  <div class="flex w-full" [class.justify-content-between]="(invoice?.type === InvoiceTypeDB.PROFORMA && !issueNewInvoice) || issueNewInvoice">
    @if (invoice?.type === InvoiceTypeDB.PROFORMA && !issueNewInvoice) {
      @if (finalInvoice) {
        <button
          (click)="onShowFinalInvoice()"
          pButton
          label="{{ 'admin.customer-org-orders.invoice.modify.modal.show-final-invoice-btn.label' | translate:{ invoiceNumber: finalInvoice.invoiceNumber } }}"
          class="">
        </button>
      } @else {
        <button
          (click)="onSaveAsInvoice()"
          [loading]="saving"
          [disabled]="saving"
          pButton
          label="{{ 'admin.customer-org-orders.invoice.modify.modal.save-as-invoice-btn.label' | translate }}"
          class="">
        </button>
      }
    }

    @if (issueNewInvoice) {
      <button
        (click)="onIssueNewInvoice(InvoiceTypeDB.FINAL)"
        [loading]="sending"
        [disabled]="sending"
        pButton
        label="{{ 'admin.customer-org-orders.invoice.modify.modal.issue-invoice-btn.label' | translate }}"
        class="">
      </button>
      <button
        (click)="onIssueNewInvoice(InvoiceTypeDB.PROFORMA)"
        [loading]="sending"
        [disabled]="sending"
        pButton
        label="{{ 'admin.customer-org-orders.invoice.modify.modal.issue-proforma-btn.label' | translate }}"
        class="">
      </button>
    } @else {
      <button
        (click)="onSubmit(customerChangeTemplate)"
        [loading]="sending"
        [disabled]="sending"
        pButton
        label="{{ 'admin.customer-org-orders.invoice.modify.modal.submit-btn.label' | translate }}"
        class="">
      </button>
    }
  </div>

</div>

<ng-template #customerChangeTemplate>
  <div>
    <span>{{ 'admin.modify-invoice-payer-info-change-modal.text-one' | translate }}</span>
    <span (click)="onOrderNumberOpen()" class="underline cursor-pointer">{{ 'admin.modify-invoice-payer-info-change-modal.text-order-number' | translate:{ orderNumber: (order | orderNumber) } }}</span>
    <span>{{ 'admin.modify-invoice-payer-info-change-modal.text-two' | translate }}</span>
  </div>
</ng-template>
