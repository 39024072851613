import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, catchError, finalize, from, of, take, tap } from 'rxjs';
import { EventPublishMissingInfoModalComponent } from 'src/app/pages/admin/org-admin/events/admin-event/components/event-state-changer/modals/event-publish-missing-info-modal/event-publish-missing-info-modal.component';
import { EventStateChangerModalService } from 'src/app/pages/admin/org-admin/events/admin-event/components/event-state-changer/modals/event-state-changer-modal.service';
import { IShopItemUpdateSectionData } from '../../models/shop-item/shop-item-update-data.model';
import { IShopItem } from '../../models/shop-item/shop-item.model';
import { ICallablesShopItemsCreateInstanceData, ICallablesShopItemsRemoveInstanceData, ICallablesShopItemsSaveAsTemplateData, ICallablesShopItemsSetAsRepresentativeData, ICallablesShopItemsSettingModulesCloneData, ICallablesShopItemsSettingModulesCreateData, ICallablesShopItemsSettingModulesDeleteData, ICallablesShopItemsSettingModulesUpdateData, ICallablesShopItemsSettingModulesUpdateUiIndexesData, IShopItemUpdatePublicationTypeData, IShopItemUpdateReservationTypeData, IShopItemUpdateStateData, IShopItemsSetApplicableDiscountsData, ShopItemsService } from '../entities/shop-items/shop-items.service';
import { UtilsService } from '../utils.service';
import { StoreService } from './store.service';
import { DiscountsService, IDiscountsApplicableCreateData, IDiscountsApplicableRemoveData } from '../entities/discounts/discounts.service';
import { INextBestOfferCreateData, INextBestOfferDeleteData, NextBestOffersService } from '../entities/next-best-offers/next-best-offers.service';
import { SelectedOrgService } from '../selected-org.service';
import { AccreditationsService, IAccreditationsDeleteData } from '../entities/accreditations/accreditations.service';
import { IAutomaticCommunication} from '../entities/message-template/message-template-service';
import { IOrganization } from '../../models/organization/organization.model';
import { IEvent } from '../../models/event/event.model';

export interface IShopItemAdminState {
  shopItem$: BehaviorSubject<IShopItemAdminStoreType | null>;
  fetchingShopItem$: BehaviorSubject<boolean>;
  updatingShopItem$: BehaviorSubject<boolean>;

  updatingApplicableDiscounts$: BehaviorSubject<number[]>;
  updatingAccreditations$: BehaviorSubject<number[]>;

  creatingSettingModule$: BehaviorSubject<number>;
  updatingSettingModule$: BehaviorSubject<string[]>;
};

interface IFetchParams {
  orgId: number,
  shopItemUuid?: string,
  shopItemId?: number,
  singleSgEventId?: number,
  singleSgEventUuid?: string
}

export type IShopItemAdminStoreType = IShopItem & {
  automaticCommunication: IAutomaticCommunication;
  organization: IOrganization;
  instanceOf: IShopItem | null;
  event: IEvent;
  _count?: {
    notes: number;
  }
  // ...
};



@Injectable({
  providedIn: 'root'
})
export class ShopItemAdminStoreService implements OnDestroy {

  private initState: IShopItemAdminState = {
    shopItem$: new BehaviorSubject<IShopItemAdminStoreType | null>(null),
    fetchingShopItem$: new BehaviorSubject<boolean>(false),
    updatingShopItem$: new BehaviorSubject<boolean>(false),

    updatingApplicableDiscounts$: new BehaviorSubject<number[]>([]),
    updatingAccreditations$: new BehaviorSubject<number[]>([]),

    creatingSettingModule$: new BehaviorSubject<number>(0),
    updatingSettingModule$: new BehaviorSubject<string[]>([])
  };
  state = this.utilsService.initializeState(this.initState) as IShopItemAdminState;

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private shopItemsService: ShopItemsService,
    private store: StoreService,
    private eventStateChangerModalService: EventStateChangerModalService,
    private discountsService: DiscountsService,
    private nextBestOffersService: NextBestOffersService,
    private selectedOrgService: SelectedOrgService,
    private accreditationsService: AccreditationsService
  ) {
    this.subs.push(
      this.store.actions.sessionCard_sessionChanged$.subscribe((d) => {
        // if session is in this shopItem
        const shopItem = this.state.shopItem$.getValue();
        if (shopItem && shopItem?.sessionGroup?.sessions?.find(s => s.id === d.sessionId)) {
          this.updateShopItemInState(shopItem.id, {});
        }
      }),
  
      
      this.store.actions.eventDiscountsFormSection_reinitDiscounts$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),
      this.store.actions.siDiscountsFormSection_reinitDiscounts$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siAccreditationsFormSection_reinitAccreditations$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siCategoriesModule_reinitCategories$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siSgLecturersModule_reinitSgLecturers$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siSiCoordinatorsModule_reinitCoordinators$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siSessionsModule_reinitSessions$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.copySessionModal_sessionCopied$.subscribe((d) => {
        // if session is in this shopItem
        const shopItem = this.state.shopItem$.getValue();
        if (shopItem && shopItem?.sessionGroup?.sessions?.find(s => s.id === d.sessionId)) {
          this.updateShopItemInState(shopItem.id, {});
        }
      }),

      this.store.actions.sgLecturerHourRateModal_lecturerHourRateChanged$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.orgUsers_memberUpdated$.subscribe(() => {
        // update members
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siAutomaticCommunicationModule_automaticCommunicationsUpdated$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siPublicTagsModule_reinitPublicTags$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),

      this.store.actions.siInternalTagsModule_reinitInternalTags$.subscribe(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      }),
    )
  }

  public init(d: IFetchParams) {
    this.utilsService.resetState(this.initState, this.state);

    this.state.fetchingShopItem$.next(true);
    this.fetchShopItem(d).pipe(
      take(1),
      finalize(() => this.state.fetchingShopItem$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.shopItem$.next(res);
      }
    });
  }

  public archive() {
    const shopItemId = this.state.shopItem$.getValue()?.id;
    if (!shopItemId) return of(null);

    this.state.fetchingShopItem$.next(true);
    return this.shopItemsService.archive(shopItemId).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        this.state.shopItem$.next(null);
      })
    );
  }


  public updateBySection(data: IShopItemUpdateSectionData, succSilent: boolean = true) {
    const shopItem = this.state.shopItem$.getValue();

    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.updateBySection(data, succSilent).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(res => {
        this.updateShopItemInState(data.shopItemId, {});

        if (shopItem) this.store.actions.adminShopItem_shopItemUpdated$.next({ id: shopItem.id, uuid: shopItem.uuid });
      })
    );
  }

  public createSettingModule(data: ICallablesShopItemsSettingModulesCreateData) {
    this.state.creatingSettingModule$.next(this.state.creatingSettingModule$.getValue() + 1);
    return this.shopItemsService.createSettingModule(data, true).pipe(
      take(1),
      tap(() => {
        this.updateShopItemInState(data.shopItemId, {
          callback: () => this.state.creatingSettingModule$.next(this.state.creatingSettingModule$.getValue() - 1)
        });
      })
    );
  }

  public updateSettingModules(data: ICallablesShopItemsSettingModulesUpdateData[]) {
    const shopItem = this.state.shopItem$.getValue();

    this.state.updatingSettingModule$.next([ ...this.state.updatingSettingModule$.getValue(), ...data.map(d => d.moduleUuid) ]);
    return this.shopItemsService.updateSettingModules(data).pipe(
      take(1),
      tap(() => {
        this.updateShopItemInState(data[0].shopItemId, {
          callback: () => this.state.updatingSettingModule$.next(this.state.updatingSettingModule$.getValue().filter(moduleUuid => !data.map(d => d.moduleUuid).includes(moduleUuid)) )
        });

        if (shopItem) this.store.actions.adminShopItem_shopItemUpdated$.next({ id: shopItem.id, uuid: shopItem.uuid });
      }),
      catchError(() => {
        this.state.updatingSettingModule$.next(this.state.updatingSettingModule$.getValue().filter(moduleUuid => !data.map(d => d.moduleUuid).includes(moduleUuid)));
        return of(null);
      })
    );
  }

  public updateSettingModulesUiIndexes(data: ICallablesShopItemsSettingModulesUpdateUiIndexesData) {
    // this.state.updatingSettingModule$.next([ ...this.state.updatingSettingModule$.getValue(), ...data.moduleUuids ]);
    return this.shopItemsService.updateSettingModulesUiIndexes(data, true).pipe(
      take(1),
      tap(() => {

      })
    );
  }

  public deleteSettingModule(data: ICallablesShopItemsSettingModulesDeleteData) {
    this.state.updatingSettingModule$.next([ ...this.state.updatingSettingModule$.getValue(), data.uuid ]);
    return this.shopItemsService.deleteSettingModule(data, true).pipe(
      take(1),
      tap(() => {
        this.updateShopItemInState(data.shopItemId, {
          callback: () => this.state.updatingSettingModule$.next(this.state.updatingSettingModule$.getValue().filter(uuid => uuid !== data.uuid))
        });
      })
    );
  }

  public cloneSettingModule(data: ICallablesShopItemsSettingModulesCloneData) {
    this.state.updatingSettingModule$.next([ ...this.state.updatingSettingModule$.getValue(), data.uuid ]);
    return this.shopItemsService.cloneSettingModule(data).pipe(
      take(1),
      tap(() => {
        this.updateShopItemInState(data.originShopItemId, {
          callback: () => this.state.updatingSettingModule$.next(this.state.updatingSettingModule$.getValue().filter(uuid => uuid !== data.uuid))
        });
      })
    );
  }

  public updatePublicationType(data: IShopItemUpdatePublicationTypeData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.updatePublicationType(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        this.updateShopItemInState(data.shopItemId, {});
      })
    );
  }

  public updateReservationType(data: IShopItemUpdateReservationTypeData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.updateReservationType(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        this.updateShopItemInState(data.shopItemId, {});
      })
    );
  }

  public updateState(data: IShopItemUpdateStateData, reinit: boolean = false) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.updateState(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(res => {
        if (res?.eventMissingData) {
          // open modal for missing data
          const resendPromise = this.eventStateChangerModalService.openEventPublishMissingInfoModal(EventPublishMissingInfoModalComponent, res.eventMissingData);
          from(resendPromise).pipe(take(1)).subscribe(resend => {
            if (resend) {
              this.updateState(data, true).subscribe(); // reinit protoze v formy se updatuji pouze jednou - a jelikoz tyto zmeny jsou z jineho formu, tak davam reinit ... jinak by musel u vsech formu patchValue pri kazde zmene a to je blbe
              // this.store.actions.eventAdmin_reinit$.next(); // reinit event
            }
          });
          return;
        }
        this.updateShopItemInState(data.shopItemId, {});
      })
    );
  }

  public createNextBestOffer(data: INextBestOfferCreateData) {
    this.state.updatingShopItem$.next(true);
    return this.nextBestOffersService.create(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        this.updateShopItemInState(data.shopItemId, {});
      })
    );
  }

  public duplicate() {
    const shopItemId = this.state.shopItem$.getValue()?.id;
    if (!shopItemId) return of([]);

    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.duplicate({ shopItemIds: [shopItemId] }).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap((res) => {
        this.store.actions.adminShopItem_shopItemDuplicated$.next(res.map(x => ({ id: x.id, uuid: x.uuid })));
      })
    );
  }

  public deleteNextBestOffer(data: INextBestOfferDeleteData) {
    this.state.updatingShopItem$.next(true);
    return this.nextBestOffersService.delete(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      })
    );
  }

  public setDiscounts(data: IShopItemsSetApplicableDiscountsData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.setShopItemApplicableDiscounts(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(res => {
        this.updateShopItemInState(data.shopItemId, {});
      })
    );
  }
  public applyDiscount(data: IDiscountsApplicableCreateData) {
    this.state.updatingShopItem$.next(true);
    return this.discountsService.createApplicableDiscount(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(res => {
        this.updateShopItemInState(data.shopItemId, {});
      })
    )
  }
  public removeDiscount(data: IDiscountsApplicableRemoveData) {
    this.state.updatingShopItem$.next(true);
    this.state.updatingApplicableDiscounts$.next([ ...this.state.updatingApplicableDiscounts$.getValue(), ...data.applicableDiscountIds ]);
    return this.discountsService.removeApplicableDiscounts(data).pipe(
      take(1),
      finalize(() => {
        this.state.updatingShopItem$.next(false);
        this.state.updatingApplicableDiscounts$.next(this.state.updatingApplicableDiscounts$.getValue().filter(id => !data.applicableDiscountIds.includes(id)));
      }),
      tap(res => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      })
    );
  }

  public removeAccreditation(data: IAccreditationsDeleteData) {
    this.state.updatingShopItem$.next(true);
    this.state.updatingAccreditations$.next([ ...this.state.updatingAccreditations$.getValue(), data.accreditationId ]);
    return this.accreditationsService.deleteAccreditation(data).pipe(
      take(1),
      finalize(() => {
        this.state.updatingShopItem$.next(false);
        this.state.updatingAccreditations$.next(this.state.updatingAccreditations$.getValue().filter(id => id !== data.accreditationId));
      }),
      tap(res => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      })
    );
  }

  public archiveDiscountTemplate(discountTemplateId: number) {
    this.state.updatingShopItem$.next(true);
    return this.discountsService.archiveDiscountTemplate(discountTemplateId).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(res => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      })
    );
  }

  public setAsRepresentative(data: ICallablesShopItemsSetAsRepresentativeData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.setAsRepresentative(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap(() => {
        const shopItemId = this.state.shopItem$.getValue()?.id;
        if (shopItemId) this.updateShopItemInState(shopItemId, {});
      })
    );
  }

  public saveAsTemplate(data: ICallablesShopItemsSaveAsTemplateData): Observable<IShopItem> {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.saveAsTemplate(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap((res) => {
        // do not update - new shopitem is created and it should be redirected to it
        // with code below there could be a race condition
        // const shopItemId = this.state.shopItem$.getValue()?.id;
        // if (shopItemId) this.updateShopItemInState(shopItemId, { reinit: true });
      })
    );
  }

  public createInstance(data: ICallablesShopItemsCreateInstanceData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.createInstance(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap((res) => {
        // do not update - new shopitem is created and it should be redirected to it
        // with code below there could be a race condition
        // const shopItemId = this.state.shopItem$.getValue()?.id;
        // if (shopItemId) this.updateShopItemInState(shopItemId, { reinit: true });
      })
    );
  }

  public removeInstance(data: ICallablesShopItemsRemoveInstanceData) {
    this.state.updatingShopItem$.next(true);
    return this.shopItemsService.removeInstance(data).pipe(
      take(1),
      finalize(() => this.state.updatingShopItem$.next(false)),
      tap((res) => {
        this.updateShopItemInState(data.shopItemId, { });
      })
    );
  }

  public updateShopItemInState(
    shopItemId: number,
    {
      callback,
      reinit
    }: {
      callback?: (res: any) => void,
      reinit?: boolean
    }) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;

    if (reinit) this.init({ orgId, shopItemId: this.state.shopItem$.getValue()?.id });
    else {
      this.state.updatingShopItem$.next(true);
      this.fetchShopItem({ orgId, shopItemId }).pipe(
        take(1),
        finalize(() => this.state.updatingShopItem$.next(false))
      ).subscribe({
        next: (res) => {
          this.state.shopItem$.next(res);
          if (callback) callback(res);
        }
      });
    }
  }


  private fetchShopItem(d: IFetchParams) {
    return this.shopItemsService.getForSiAdminStore({
      id: d.shopItemId,
      singleSgEventId: d.singleSgEventId,
      shopItemUuid: d.shopItemUuid,
      singleSgEventUuid: d.singleSgEventUuid,
      orgId: d.orgId
    }).pipe(

    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

}
