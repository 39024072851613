import { AsyncPipe, CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Listbox, ListboxClickEvent, ListboxModule } from 'primeng/listbox';
import { Observable } from 'rxjs';

export interface IShortCodeMenuItem {
  label: string;
  shortCode: any;
  shortCodeValue: string;
  exampleValue: string | number | null | Observable<any>;
  exampleIsHtml?: boolean;
  command: (event?: any) => void;
}

@Component({
  selector: 'app-short-codes-menu',
  standalone: true,
  imports: [
    AsyncPipe,
    ListboxModule
  ],
  templateUrl: './short-codes-menu.component.html',
  styleUrl: './short-codes-menu.component.scss'
})
export class ShortCodesMenuComponent implements AfterViewInit {
  Observable = Observable;

  @ViewChild('listbox') listbox: ElementRef | undefined;

  @Input() menuItems: IShortCodeMenuItem[] = [];

  constructor(
    private hostRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    // focus filter input on init
    setTimeout(() => {
      const input = this.hostRef.nativeElement?.querySelector('input');
      input?.focus();
    });
  }

  onMenuItemClick(e: ListboxClickEvent) {
    e.option.command();
  }

  isAsyncaable(val: any) {
    return val instanceof Observable || val instanceof Promise;
  }
}
