import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, finalize, from, map, Observable, of, skip, Subject, Subscription, switchMap, take, tap } from 'rxjs';
import { EventPublishMissingInfoModalComponent } from 'src/app/pages/admin/org-admin/events/admin-event/components/event-state-changer/modals/event-publish-missing-info-modal/event-publish-missing-info-modal.component';
import { EventStateChangerModalService } from 'src/app/pages/admin/org-admin/events/admin-event/components/event-state-changer/modals/event-state-changer-modal.service';
import { IEventUpdateSectionData } from '../../models/event/event-update-data.model';
import { IEvent } from '../../models/event/event.model';
import { EventsService, IEventPublishableMissingInfoSetData, IEventStateUpdateData } from '../entities/events/events.service';
import { NavigationService } from '../navigation.service';
import { StoreService } from './store.service';
import { IShopItemDataMap, IShopItemGetForAdminEventFiltersData, IShopItemGetForAdminEventResult, IShopItemSwitchUiIndexData, ShopItemsService } from '../entities/shop-items/shop-items.service';
import { IShopItem } from '../../models/shop-item/shop-item.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DiscountsService } from '../entities/discounts/discounts.service';
import { IDiscountTemplate } from '../../models/discount/discount-template.model';
import { UserPreferencesStoreService } from './user-preferences-store.service';
import { EventShopItemFilterItemType } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/event-shop-items-filter.component';
import { EventShopItemOrderByType, IEventShopItemFilterItem_ORDER_BY } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-order-by/event-si-filter-order-by.component';
import { IEventShopItemFilterItem_COORDINATOR } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-coordinator/event-si-filter-item-coordinator.component';
import { IEventShopItemFilterItem_LOCATION } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-location/event-si-filter-item-location.component';
import { IEventShopItemFilterItem_INTERNAL_TAG } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-internal-tag/event-si-filter-item-internal-tag.component';
import { IEventShopItemFilterItem_PUBLIC_TAG } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-public-tag/event-si-filter-item-public-tag.component';
import { IEventShopItemFilterItem_CATEGORY } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-category/event-si-filter-item-category.component';
import { IEventShopItemFilterItem_DATE_RANGE } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-date-range/event-si-filter-item-date-range.component';
import { IEventShopItemFilterItem_STATE } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-state/event-si-filter-item-state.component';
import { IEventShopItemFilterItem_RESERVATION_STATE } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-item-reservation-state/event-si-filter-item-reservation-state.component';
import { defaultEventShopItemsColumnItems, EventShopItemsTableColumnItemType } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-table/event-shop-items-table.component';

export interface IEventAdminState {
  event$: BehaviorSubject<IEvent | null>;
  fetchingEvent$: BehaviorSubject<boolean>;
  updatingEvent$: BehaviorSubject<number>;

  shopItems$: BehaviorSubject<IShopItem[]>;
  totalShopItemCount$: BehaviorSubject<number>;
  dataMap$: BehaviorSubject<{ [shopItemId: number]: { [key in EventShopItemsTableColumnItemType]?: any; } }>;
  shopItemFilter$: BehaviorSubject<IShopItemGetForAdminEventFiltersData>;
  fetchingShopItems$: BehaviorSubject<boolean>;
  updatingShopItems$: BehaviorSubject<number[]>;

  discountTemplates$: BehaviorSubject<IEventAdminDiscountTemplate[]>;
  fetchingDiscountTemplates$: BehaviorSubject<boolean>;
  updatingDiscountTemplates$: BehaviorSubject<number[]>;
};

export type IEventAdminDiscountTemplate = IDiscountTemplate & { _count: { applicableDiscounts: number } };

@Injectable({
  providedIn: 'root'
})
export class EventAdminStoreService implements OnDestroy {

  private initState: IEventAdminState = {
    event$: new BehaviorSubject<IEvent | null>(null),
    fetchingEvent$: new BehaviorSubject<boolean>(false),
    updatingEvent$: new BehaviorSubject<number>(0),

    shopItems$: new BehaviorSubject<IShopItem[]>([]),
    totalShopItemCount$: new BehaviorSubject<number>(0),
    dataMap$: new BehaviorSubject<IShopItemDataMap>({}),
    shopItemFilter$: new BehaviorSubject<IShopItemGetForAdminEventFiltersData>({}), 
    fetchingShopItems$: new BehaviorSubject<boolean>(false),
    updatingShopItems$: new BehaviorSubject<number[]>([]),

    discountTemplates$: new BehaviorSubject<IEventAdminDiscountTemplate[]>([]),
    fetchingDiscountTemplates$: new BehaviorSubject<boolean>(false),
    updatingDiscountTemplates$: new BehaviorSubject<number[]>([]),
  };
  private state: IEventAdminState = this.initState;
  private actionSubs: Subscription[] = [];
  private subs: Subscription[] = [];
  private initShopItemsTrigger$ = new Subject<void>(); // trigger pro init shop items - 

  eventUuid: string | undefined;

  constructor(
    private eventsService: EventsService,
    private navService: NavigationService,
    private store: StoreService,
    private eventStateChangerModalService: EventStateChangerModalService,
    private shopItemsService: ShopItemsService,
    private discountsService: DiscountsService,
    private userPreferencesStore: UserPreferencesStoreService,
  ) {
    this.handleActions();

    this.subs.push(
      combineLatest([this.state.event$, this.userPreferencesStore.state.preferences$]).subscribe(([event, prefs]) => {
        if (!event) return;
        const adminEventShopItemsFilterPrefs = prefs.admin_event_shop_items_filter?.[event.uuid];
        this.state.shopItemFilter$.next({
          coordinatorIds: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.COORDINATOR) as IEventShopItemFilterItem_COORDINATOR | undefined)?.value?.map(x => x.value as number) ?? [],
          locationIds: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.LOCATION) as IEventShopItemFilterItem_LOCATION | undefined)?.value?.map(x => x.value as number) ?? [],
          dateRange: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.DATE_RANGE) as IEventShopItemFilterItem_DATE_RANGE | undefined)?.value?.map(x => x.value as string) ?? [],
          categoryIds: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.CATEGORY) as IEventShopItemFilterItem_CATEGORY | undefined)?.value?.map(x => x.value as number) ?? [],
          publicTagIds: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.PUBLIC_TAG) as IEventShopItemFilterItem_PUBLIC_TAG | undefined)?.value?.map(x => x.value as number) ?? [],
          internalTagIds: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.INTERNAL_TAG) as IEventShopItemFilterItem_INTERNAL_TAG | undefined)?.value?.map(x => x.value as number) ?? [],
          orderBy: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.ORDER_BY) as IEventShopItemFilterItem_ORDER_BY | undefined)?.value?.value as EventShopItemOrderByType,
          shopItemStates: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.STATE) as IEventShopItemFilterItem_STATE | undefined)?.value?.map(x => x.value) ?? [],
          shopItemReservationStates: (adminEventShopItemsFilterPrefs?.find(x => x.type === EventShopItemFilterItemType.RESERVATION_STATE) as IEventShopItemFilterItem_RESERVATION_STATE | undefined)?.value?.map(x => x.value) ?? [],
        });
        this.initShopItems();
      }),


      this.initShopItemsTrigger$.pipe(
        switchMap(() => {
          this.state.fetchingShopItems$.next(true);
          return this.getFreshShopItems().pipe(
            take(1),
            finalize(() => this.state.fetchingShopItems$.next(false)),
            catchError(err => {
              return of({
                shopItems: [],
                dataMap: {},
                totalShopItemCount: 0
              });
            })
          );
        })
      ).subscribe({
        next: (res) => {
          this.state.shopItems$.next(res.shopItems);
          this.state.totalShopItemCount$.next(res.totalShopItemCount);
          this.state.dataMap$.next(res.dataMap);
        }
      }), 
    )
  }

  public init(eventUuid?: string) {
    this.eventUuid = eventUuid ? eventUuid : this.state.event$.getValue()?.uuid;

    this.reset();

    this.state.fetchingEvent$.next(true);
    this.getFreshEvent().pipe(
      take(1),
      finalize(() => this.state.fetchingEvent$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.event$.next(res);
      }
    });

    this.initDiscounts();

    this.initShopItems();
  }

  private initDiscounts() {
    this.state.fetchingDiscountTemplates$.next(true);
    this.getFreshDiscounts().pipe(
      take(1),
      finalize(() => this.state.fetchingDiscountTemplates$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.discountTemplates$.next(res);
      }
    });
  }
  

  private initShopItems() {
    this.initShopItemsTrigger$.next();
  }

  public updateEventBySection(data: IEventUpdateSectionData, succSilent: boolean = true) {
    this.setEventUpdating(true);
    return this.eventsService.updateSection(data, succSilent).pipe(
      take(1),
      finalize(() => this.setEventUpdating(false)),
      tap(res => {
        this.updateEventInState();
      })
    )
  }

  public updateState(data: IEventStateUpdateData, reinitAfter: boolean = false) {
    this.setEventUpdating(true);
    return this.eventsService.updateState(data).pipe(
      take(1),
      finalize(() => this.setEventUpdating(false)),
      map(res => {
        if (res?.eventMissingData) {
          // open modal for missing data
          const resendPromise = this.eventStateChangerModalService.openEventPublishMissingInfoModal(EventPublishMissingInfoModalComponent, res.eventMissingData);
          from(resendPromise).pipe(take(1)).subscribe(resend => {
            if (resend) this.updateState(data, true).subscribe(); // reinit protoze v formy se updatuji pouze jednou - a jelikoz tyto zmeny jsou z jineho formu, tak davam reinit ... jinak by musel u vsech formu patchValue pri kazde zmene a to je blbe
            return null;
          });
          return null;
        }

        this.updateEventInState(reinitAfter);

        return res;
      })
    );
  }

  public setEventPublishableMissingInfo(data: IEventPublishableMissingInfoSetData) {
    this.setEventUpdating(true);
    return this.eventsService.setEventPublishableMissingInfo(data).pipe(
      take(1),
      finalize(() => this.setEventUpdating(false)),
      tap(x => {
        this.store.actions.organization_organizationUpdated$.next(null);
        this.updateEventInState();
      })
    );
  }

  public updateUiIndexes(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.state.shopItems$.getValue(), event.previousIndex, event.currentIndex);

    const currShopItems = this.state.shopItems$.getValue();
    const shopItemIds = currShopItems.map(x => x.id);

    const data: IShopItemSwitchUiIndexData = {};
    currShopItems.forEach((shopItem, index) => {
      data[shopItem.id] = index;
    });

    return this.shopItemsService.switchUiIndex(data).pipe(
      take(1),
      finalize(() => null),
      tap(res => {
        this.updateShopItemsInState(shopItemIds, false, true);
      })
    );

  }

  public instanciateRepresentativeShopItem(id: number, eventId: number) {
    this.setShopItemsUpdating([id], true);
    return this.shopItemsService.createInstance({
      representativeShopItemId: id,
      eventId,
    }).pipe(
      take(1),
      finalize(() => this.setShopItemsUpdating([id], false)),
      tap((res) => {
        if (res.eventUuid === this.eventUuid) {
          this.updateShopItemsInState([id, res.shopItemId]);
        }
      })
    );
  }

  public duplicateShopItem(ids: number[]) {
    this.setShopItemsUpdating(ids, true);
    return this.shopItemsService.duplicate({ shopItemIds: ids }).pipe(
      take(1),
      finalize(() => this.setShopItemsUpdating(ids, false)),
      tap(() => {
        this.updateShopItemsInState([], true);
      })
    );
  }

  public archiveShopItem(id: number) {
    this.setShopItemsUpdating([id], true);
    return this.shopItemsService.archive(id).pipe(
      take(1),
      finalize(() => this.setShopItemsUpdating([id], false)),
      tap(() => {
        this.updateShopItemsInState([id]);
      })
    );
  }

  public syncShopItemWithWP(id: number) {
    this.setShopItemsUpdating([id], true);
    return this.shopItemsService.fullWpSync(id).pipe(
      take(1),
      finalize(() => this.setShopItemsUpdating([id], false)),
      tap(() => {
        this.updateShopItemsInState([id]);
      })
    );
  }




  private updateEventInState(reinit: boolean = false) {
    if (reinit) this.init(this.eventUuid);
    else {
      this.setEventUpdating(true);
      this.getFreshEvent().pipe(
        take(1),
        finalize(() => this.setEventUpdating(false))
      ).subscribe({
        next: (res) => {
          this.state.event$.next(res);

          this.updateShopItemsInState([], true, true);
        }
      });
    }
  }

  private updateDiscountTemplatesInState(reinit: boolean = false) {
    if (reinit) this.initDiscounts();
    else {
      this.state.fetchingDiscountTemplates$.next(true);
      this.getFreshDiscounts().pipe(
        take(1),
        finalize(() => this.state.fetchingDiscountTemplates$.next(false))
      ).subscribe({
        next: (res) => {
          const currentDiscountTemplates = this.state.discountTemplates$.getValue();
          const newDiscountTemplates = currentDiscountTemplates.map(item => {
            const f = res.find(x => x.id === item.id);
            if (f) return f;
            else return item;
          });
          this.state.discountTemplates$.next(newDiscountTemplates);
        }
      });
    }
  }

  private updateShopItemsInState(shopItemsId: number[], reinit: boolean = false, silent: boolean = false) {
    if (reinit) this.initShopItems();
    else {
      if (!silent) this.setShopItemsUpdating(shopItemsId, true);
      this.getFreshShopItems().pipe(
        take(1),
        finalize(() => { if(!silent) this.setShopItemsUpdating(shopItemsId, false) })
      ).subscribe({
        next: (res) => {
          const currentShopItems = this.state.shopItems$.getValue();
          
          // update shop items in state
          const newShopItems = currentShopItems.map(item => {
            if (shopItemsId.includes(item.id)) {
              const f = res.shopItems.find(x => x.id === item.id);
              if (f) return f;
              else return null;
            }
            return item;
          }).filter((x): x is IShopItem => x !== null);
          this.state.shopItems$.next(newShopItems);
          this.state.dataMap$.next(res.dataMap);
        }
      });
    }
  }

  private setEventUpdating(bool: boolean) {
    if (bool) this.state.updatingEvent$.next(this.state.updatingEvent$.getValue() + 1);
    else this.state.updatingEvent$.next(this.state.updatingEvent$.getValue() - 1);
  }

  private getFreshEvent() {
    if (!this.eventUuid) {
      return of(null);
    }
    // get event
    return this.eventsService.getSingle({ uuid: this.eventUuid, include: { organization: { include: { invoiceProfile: true } }, brandProfile: true } });
  }

  private getFreshDiscounts() {
    if (!this.eventUuid) {
      return of([]);
    }
    // get discounts
    return this.discountsService.getEventDiscounts({ eventUuid: this.eventUuid });
  }

  private setShopItemsUpdating(shopItemIds: number[], bool: boolean) {
    if (bool) this.state.updatingShopItems$.next([ ...this.state.updatingShopItems$.getValue(), ...shopItemIds ]);
    else this.state.updatingShopItems$.next(this.state.updatingShopItems$.getValue().filter(id => !shopItemIds.includes(id)));
  }
  private getFreshShopItems(): Observable<IShopItemGetForAdminEventResult> {
    if (!this.eventUuid) {
      return of({
        shopItems: [],
        dataMap: {},
        totalShopItemCount: 0
      });
    }
    // get shop items
    return this.shopItemsService.getForAdminEvent({ eventUuid: this.eventUuid, filter: this.state.shopItemFilter$.value });
  }

  private archiveEvent(eventId: number) {
    this.state.updatingEvent$.next(this.state.updatingEvent$.getValue() + 1);
    return this.eventsService.archiveEvent(eventId).pipe(
      take(1),
      finalize(() => this.state.updatingEvent$.next(this.state.updatingEvent$.getValue() - 1)),
      tap(res => {
        this.state.event$.next(null);
        this.store.actions.eventAdmin_eventArchived$.next(eventId);
        this.navService.goToAdminEvents();
      })
    );
  }


  public archiveDiscountTemplate(discountTemplateId: number) {
    this.state.updatingDiscountTemplates$.next([ ...this.state.updatingDiscountTemplates$.getValue(), discountTemplateId ]);
    return this.discountsService.archiveDiscountTemplate(discountTemplateId).pipe(
      take(1),
      finalize(() => this.state.updatingDiscountTemplates$.next(this.state.updatingDiscountTemplates$.getValue().filter(id => id !== discountTemplateId))),
      tap(res => {
        this.updateDiscountTemplatesInState(false);
      })
    );
  }






  private handleActions() {
    this.actionSubs.push(
      this.store.actions.eventAdmin_archiveEvent$.subscribe(eventId => {
        this.archiveEvent(eventId).subscribe();
      }),

      this.store.actions.eventAdmin_reinit$.subscribe(() => {
        this.init();
      }),

      this.store.actions.newShopItemModal_shopItemCreated$.subscribe(r => {
        this.initShopItems();
      }),

      this.store.actions.adminShopItem_shopItemUpdated$.subscribe(r => {
        this.updateShopItemsInState([r.id]);
      }),

      this.store.actions.shopItemReservationInvitationModal_invitationsSent$.subscribe(r => {
        this.updateShopItemsInState([r.shopItemId]);
      }),

      this.store.actions.adminShopItem_shopItemDuplicated$.subscribe(r => {
        this.initShopItems();
      }),

      this.store.actions.adminShopItemDuplicateModal_shopItemsDuplicated$.subscribe(r => {
        this.initShopItems();
      }),

      this.store.actions.adminShopItem_eventUpdated$.subscribe(() => {
        this.updateEventInState();
      }),
      
      this.store.actions.eventDiscountsFormSection_reinitDiscounts$.subscribe(() => {
        this.initDiscounts();
      }),
      this.store.actions.siDiscountsFormSection_reinitDiscounts$.subscribe(() => {
        this.initDiscounts();
      }),
    );
  }

  private reset() {
    this.state = this.initState;
  }

  public getState() {
    return this.state;
  }

  ngOnDestroy(): void {
    this.actionSubs.forEach(s => s.unsubscribe());
    this.subs.forEach(s => s.unsubscribe());
  }
}
