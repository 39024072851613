import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopItemModuleBaseComponent } from '../_shop-item-module-base/shop-item-module-base.component';
import { ShopItemModuleWrapperComponent } from '../_shop-item-module-wrapper/shop-item-module-wrapper.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Editor, EditorModule } from 'primeng/editor';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChipsModule } from 'primeng/chips';
import { IShortCodeMenuItem } from 'src/app/shared/components/short-codes-menu/short-codes-menu.component';
import { ShortCodesOverlayComponent } from 'src/app/shared/components/short-codes-menu/short-codes-overlay/short-codes-overlay.component';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { EditorWrapComponent } from 'src/app/shared/components/editor-wrap-component/editor-wrap-component.component';
import { EditorOverlayDirective } from 'src/app/shared/directives/editor-overlay.directive';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { TinyEditorOverlayDirective } from 'src/app/shared/directives/tiny-editor-overlay.directive';
import { InputTextOverlayDirective } from 'src/app/shared/directives/input-text-overlay.directive';
import { UserHelperService } from 'src/app/shared/services/helpers/user-helper.service';


export type ISiDescriptionFG = FormGroup<ISiDescriptionForm>;
interface ISiDescriptionForm {
  shortDescription: FormControl<string | null>;
  description: FormControl<string | null>;
}

export const getSiDescriptionFG = (): ISiDescriptionFG => {
  return new FormGroup<ISiDescriptionForm>({
    shortDescription: new FormControl(null),
    description: new FormControl(null),
  });
};


export enum ShopItemDescriptionShortCode {
  ROLE_A_COUNTER_BAR = 'role_A_counter_bar',
  ROLE_B_COUNTER_BAR = 'role_B_counter_bar',
  ROLE_A_COUNTER = 'role_A_counter',
  ROLE_B_COUNTER = 'role_B_counter',
  ROLE_A_WL_COUNTER = 'role_A_wl_counter',
  ROLE_B_WL_COUNTER = 'role_B_wl_counter',
  RESERVATIONS_COUNTER = 'reservations_counter',
  WL_COUNTER = 'wl_counter',
  ITEM_TITLE = 'item_title',
  ITEM_MIN_CAPACITY = 'item_min_capacity',
  ITEM_TARGET_CAPACITY = 'item_target_capacity',
  ITEM_CAPACITY = 'item_capacity',
  ITEM_HALF_CAPACITY = 'item_half_capacity',
  AVAILABLE_CAPACITY = 'available_capacity',
  PRICE = 'price',
  LOCATION = 'location',
  DAY_FROM = 'day_from',
  DAY_TO = 'day_to',
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
  TIME_FROM = 'time_from',
  TIME_TO = 'time_to',
  YEAR_FROM = 'year_from',
  YEAR_TO = 'year_to',
  NTH_SESSION_DAY_FROM = 'session_day_from',
  NTH_SESSION_DAY_TO = 'session_day_to',
  NTH_SESSION_DATE_FROM = 'session_date_from',
  NTH_SESSION_DATE_TO = 'session_date_to',
  NTH_SESSION_YEAR_FROM = 'session_year_from',
  NTH_SESSION_YEAR_TO = 'session_year_to',
  NTH_SESSION_TIME_FROM = 'session_time_from',
  NTH_SESSION_TIME_TO = 'session_time_to',
  NTH_SESSION_LOCATION = 'session_location',
  LAST_SESSION_DAY_FROM = 'last_session_day_from',
  LAST_SESSION_DAY_TO = 'last_session_day_to',
  LAST_SESSION_DATE_FROM = 'last_session_date_from',
  LAST_SESSION_DATE_TO = 'last_session_date_to',
  LAST_SESSION_YEAR_FROM = 'last_session_year_from',
  LAST_SESSION_YEAR_TO = 'last_session_year_to',
  LAST_SESSION_TIME_FROM = 'last_session_time_from',
  LAST_SESSION_TIME_TO = 'last_session_time_to',
  LAST_SESSION_LOCATION = 'last_session_location',
  ICON = 'icon',
  TIME_ALLOCATION = 'time_allocation',
  COORDINATOR_NAME = 'coordinator_name',
  COORDINATOR_EMAIL = 'coordinator_email',
  CUSTOM_TEXT = 'custom_text',
  ACCREDITATION_NUMBER = 'accreditation_number',
  ACCREDITATION_AUTHORITY = 'accreditation_authority',
  ACCREDITATION_AUTHORITY_CODE = 'accreditation_authority_code',
  TEACHERS = 'teachers',
}
export enum ShopItemDescriptionIcon {
  CALENDAR = 'calendar',
  CLOCK = 'clock',
  MAP_MARKER = 'map-marker',
}

@Component({
  selector: 'app-shop-item-module-description',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    EditorModule, ChipsModule,
    ShopItemModuleWrapperComponent, EditorOverlayDirective, ShortCodesOverlayComponent, EditorWrapComponent
  ],
  templateUrl: './shop-item-module-description.component.html',
  styleUrls: ['./shop-item-module-description.component.scss'],
  providers: [
    LocalizedDatePipe,
    PricePipe,
  ]
})
export class ShopItemModuleDescriptionComponent extends ShopItemModuleBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('sed') shortDescriptionEditor: EditorWrapComponent | undefined;
  get shortDescriptionEditorOverlay () { return this.shortDescriptionEditor?.editorOverlay };

  @ViewChild('ed') descriptionEditor: EditorWrapComponent | undefined;
  get descriptionEditorOverlay () { return this.descriptionEditor?.editorOverlay };

  sedShortcodeMenuItems: IShortCodeMenuItem[]  = [];
  edShortcodeMenuItems: IShortCodeMenuItem[]  = [];

  constructor(
    private translate: TranslateService,
    private siHelper: ShopItemHelperService,
    private ldp: LocalizedDatePipe,
    private pricePipe: PricePipe,
    private userHelper: UserHelperService,
  ) {
    super();

    this.subs.push(
      this.state.shopItem$.subscribe(shopItem => {
        this.initEditorOverlays();
      }),
      this.translate.onLangChange.subscribe(() => {
        this.initEditorOverlays();
      })
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();

  }

  ngAfterViewInit(): void {
    this.initEditorOverlays();
  }
  
  private initEditorOverlays() {
    if (!this.shopItem) return;

    const entity = this.siHelper.getShopItemEntity(this.shopItem);
    if (this.shortDescriptionEditorOverlay) {
      this.sedShortcodeMenuItems = createShopItemDescriptionShortCodeMenuItems({
        entity,
        editorOverlay: this.shortDescriptionEditorOverlay,
        shopItem: this.shopItem,
        translate: this.translate,
        ldp: this.ldp,
        pricePipe: this.pricePipe,
        siHelper: this.siHelper,
        utils: this.utilsService,
        userHelper: this.userHelper,
      });
    }
    if (this.descriptionEditorOverlay) {
      this.edShortcodeMenuItems = createShopItemDescriptionShortCodeMenuItems({
        entity,
        editorOverlay: this.descriptionEditorOverlay,
        shopItem: this.shopItem,
        translate: this.translate,
        ldp: this.ldp,
        pricePipe: this.pricePipe,
        siHelper: this.siHelper,
        utils: this.utilsService,
        userHelper: this.userHelper,
      });
    }

  }





  override patchForm(): void {
    if (!this.control || !this.shopItem) return;

    this.utilsService.deepFormReset(this.control);

    this.control.patchValue({
      description: this.shopItem.description,
      shortDescription: this.shopItem.shortDescription,
    });
  }

  override get control() {
    return this.data.moduleDefinition?.control as ISiDescriptionFG | undefined;
  }
}


export function createShopItemDescriptionShortCodeMenuItems({
  entity,
  editorOverlay,
  shopItem,
  translate,
  ldp,
  pricePipe,
  siHelper,
  utils,
  userHelper,
}:{
  entity: 'SI' | 'SG',
  editorOverlay: EditorOverlayDirective | TinyEditorOverlayDirective | InputTextOverlayDirective,
  shopItem?: IShopItem,
  translate: TranslateService,
  ldp: LocalizedDatePipe,
  pricePipe: PricePipe,
  siHelper: ShopItemHelperService,
  utils: UtilsService,
  userHelper: UserHelperService,
}): IShortCodeMenuItem[] {
  const siMenuItems: IShortCodeMenuItem[] = [];
  const sgMenuItems: IShortCodeMenuItem[] = [];

  const firstSession = shopItem?.sessionGroup?.sessions?.[0];
  const dateFrom = firstSession?.startAt ? ldp.transform(firstSession.startAt, 'd.M.', undefined, 'UTC+0') : ldp.transform(new Date(), 'd.M.');
  const dateTo = firstSession?.endAt ? ldp.transform(firstSession.endAt, 'd.M.', undefined, 'UTC+0') : ldp.transform(new Date(), 'd.M.');
  let dayFrom = firstSession?.startAt ? ldp.transform(firstSession.startAt, 'cccccc', undefined, 'UTC+0') : ldp.transform(new Date(), 'cccccc');
  let dayTo = firstSession?.endAt ? ldp.transform(firstSession.endAt, 'cccccc', undefined, 'UTC+0') : ldp.transform(new Date(), 'cccccc');
  const timeFrom = firstSession?.startAt ? ldp.transform(firstSession.startAt, 'H:mm', undefined, 'UTC+0') : ldp.transform(new Date(), 'H:mm');
  const timeTo = firstSession?.endAt ? ldp.transform(firstSession.endAt, 'H:mm', undefined, 'UTC+0') : ldp.transform(new Date(), 'H:mm');
  const yearFrom = firstSession?.startAt ? ldp.transform(firstSession.startAt, 'y', undefined, 'UTC+0') : ldp.transform(new Date(), 'y');
  const yearTo = firstSession?.endAt ? ldp.transform(firstSession.endAt, 'y', undefined, 'UTC+0') : ldp.transform(new Date(), 'y');
  const location = firstSession ? siHelper.getSessionLocation(firstSession, false) : null;
  const capacity = shopItem?.quantity ?? 20;
  const targetCapacity = shopItem?.targetQuantity;
  const minCapacity = shopItem?.minQuantity;

  const lastSession = shopItem?.sessionGroup?.sessions?.[shopItem.sessionGroup.sessions.length - 1];
  const lastSessionDateFrom = lastSession?.startAt ? ldp.transform(lastSession.startAt, 'd.M.', undefined, 'UTC+0') : ldp.transform(new Date(), 'd.M.');
  const lastSessionDateTo = lastSession?.endAt ? ldp.transform(lastSession.endAt, 'd.M.', undefined, 'UTC+0') : ldp.transform(new Date(), 'd.M.');
  let lastSessionDayFrom = lastSession?.startAt ? ldp.transform(lastSession.startAt, 'cccccc', undefined, 'UTC+0') : ldp.transform(new Date(), 'cccccc');
  let lastSessionDayTo = lastSession?.endAt ? ldp.transform(lastSession.endAt, 'cccccc', undefined, 'UTC+0') : ldp.transform(new Date(), 'cccccc');
  const lastSessionTimeFrom = lastSession?.startAt ? ldp.transform(lastSession.startAt, 'H:mm', undefined, 'UTC+0') : ldp.transform(new Date(), 'H:mm');
  const lastSessionTimeTo = lastSession?.endAt ? ldp.transform(lastSession.endAt, 'H:mm', undefined, 'UTC+0') : ldp.transform(new Date(), 'H:mm');
  const lastSessionYearFrom = lastSession?.startAt ? ldp.transform(lastSession.startAt, 'y', undefined, 'UTC+0') : ldp.transform(new Date(), 'y');
  const lastSessionYearTo = lastSession?.endAt ? ldp.transform(lastSession.endAt, 'y', undefined, 'UTC+0') : ldp.transform(new Date(), 'y');
  const lastSessionLocation = lastSession ? siHelper.getSessionLocation(lastSession, false) : null;

  const accreditation = shopItem?.accreditations?.at(0) ?? null;

  for (const [key, value] of Object.entries(ShopItemDescriptionShortCode)) {
    const shortCode = value;
    const label = translate.instant(`shop-item-description.short-code-label.${shortCode}`);
    let shortCodeValue = '';
    let item: IShortCodeMenuItem | undefined;

    switch (value as ShopItemDescriptionShortCode) {
      case ShopItemDescriptionShortCode.DATE_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dateFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.DATE_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dateTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.DAY_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dayFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.DAY_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dayTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ITEM_TITLE:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: shopItem?.title ?? '',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ITEM_CAPACITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: capacity,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ITEM_MIN_CAPACITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: minCapacity ?? '',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
      break;
      case ShopItemDescriptionShortCode.ITEM_TARGET_CAPACITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: targetCapacity ?? '',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ITEM_HALF_CAPACITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 2),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.AVAILABLE_CAPACITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 1.5),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LOCATION:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: location,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_DATE_FROM:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dateFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_DATE_TO:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dateTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_DAY_FROM:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dayFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_DAY_TO:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: dayTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_LOCATION:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: location,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_TIME_FROM:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: `${timeFrom}`,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_TIME_TO:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: `${timeTo}`,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_YEAR_FROM:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: yearFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.NTH_SESSION_YEAR_TO:
        shortCodeValue = `[${shortCode} nth="N"]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: yearTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_DATE_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionDateFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_DATE_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionDateTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_DAY_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionDayFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_DAY_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionDayTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_LOCATION:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionLocation,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_TIME_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: `${lastSessionTimeFrom}`,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_TIME_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: `${lastSessionTimeTo}`,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_YEAR_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionYearFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.LAST_SESSION_YEAR_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: lastSessionYearTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.PRICE:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: shopItem?.price ? pricePipe.transform(shopItem.price) : '10 €',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.RESERVATIONS_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 3),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_A_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 3),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_A_COUNTER_BAR:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: null,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_A_WL_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 5),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_B_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 5),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_B_COUNTER_BAR:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: null,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ROLE_B_WL_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 5),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.TIME_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: timeFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.TIME_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: timeTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.WL_COUNTER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: Math.floor(capacity / 5),
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.YEAR_FROM:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: yearFrom,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.YEAR_TO:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: yearTo,
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ICON:
        for (let [iconKey, iconValue] of Object.entries(ShopItemDescriptionIcon)) {
          const iconShortCodeValue = `[${shortCode} ${iconValue}]`;
          const iconLabel = translate.instant(`shop-item-description.short-code-label.${shortCode}.${iconValue}`);
          const icon: IShortCodeMenuItem = {
            label: iconLabel,
            shortCode: shortCode,
            shortCodeValue: iconShortCodeValue,
            exampleValue: `<i class="pi pi-${iconValue}"></i>`,
            exampleIsHtml: true,
            command: () => editorOverlay.editorInsertText(iconShortCodeValue)
          }
          siMenuItems.push(icon);
          sgMenuItems.push(icon);
        }
        break;
      case ShopItemDescriptionShortCode.TIME_ALLOCATION:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: '2 h',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.COORDINATOR_NAME:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: 'John Doe',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.COORDINATOR_EMAIL:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: 'john@doe.com',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.CUSTOM_TEXT:
        shortCodeValue = `[${shortCode} id=""]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: '',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        siMenuItems.push(item);
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ACCREDITATION_NUMBER:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: accreditation?.accreditationTemplate?.number ?? '123456',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ACCREDITATION_AUTHORITY:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: accreditation?.accreditationTemplate?.authorityName ?? 'Ministry of Education',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.ACCREDITATION_AUTHORITY_CODE:
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: accreditation?.accreditationTemplate?.authorityNameShortcut ?? 'MOE',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      case ShopItemDescriptionShortCode.TEACHERS:
        const teachersNameObjs = shopItem?.sessionGroup?.sessionGroupLecturers?.map(x => x.lecturer?.user).filter(x => x)?.map(x => userHelper.getOrgUserObj(x!.orgUsers, shopItem.organizationId)) ?? [];
        const teacherFullNames = teachersNameObjs.map(x => userHelper.getOrgUserFullname(x, false));
        shortCodeValue = `[${shortCode}]`;
        item = {
          label,
          shortCode: shortCode,
          shortCodeValue,
          exampleValue: teacherFullNames.length ? teacherFullNames.join(', ') : 'John Doe, Jane Doe',
          command: () => editorOverlay.editorInsertText(shortCodeValue)
        };
        sgMenuItems.push(item);
        break;
      default:
        utils.logError(`Unknown short code key: ${key}, 98w0weoruiwej`);
        break;
    }
  }

  if (entity === 'SI') return siMenuItems;
  if (entity === 'SG') return sgMenuItems;
  throw new Error(`Unknown entity: ${entity}, qw54eq5weq5w`);
}