<div *ngIf="FC && form" [formGroup]="form" class="flex justify-content-between">
  <p-dropdown class="p-fluid" [class.ng-dirty]="phoneNumberStatus === 'INVALID'" [class.ng-invalid]="phoneNumberStatus === 'INVALID'" class="dc-dropdown" formControlName="dialCode" [options]="dialCodesOptions" [filter]="true" filterBy="country" optionLabel="label" optionValue="value" placeholder="Dialcode">
    <ng-template pTemplate="selectedItem" let-selectedItem>
      {{ selectedItem.selectedLabel }}
    </ng-template>
  </p-dropdown>
  <div class="flex w-full">
    <p-inputMask
      psdInputMask
      [mask]="''.padStart(form.controls.minPhoneLength.value, '9')+'?'+''.padStart(form.controls.maxPhoneLength.value - form.controls.minPhoneLength.value, '9')"
      formControlName="phoneNumber"
      placeholder="{{ placeholder || '' }}"
      class="flex w-full"
      styleClass="pn-inputmask w-full"
      [style]="{'border-left': 'none'}"
      [autoClear]="true"
    ></p-inputMask>
  </div>
</div>
