import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { TagModule } from 'primeng/tag';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OfferState } from 'src/app/shared/enums/offer/offer-states.enum';
import { ReservationCurrentState, ReservationCurrentStateColorMap, ReservationHelperService } from 'src/app/shared/services/helpers/reservation-helper.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrderHelperService } from 'src/app/shared/services/helpers/order-helper.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IReservationTableEntity, ReservationTableEntityType } from 'src/app/shared/services/store/org-reservations-store.service';
import { ReservationInvitationState } from 'src/app/shared/models/reservation-invitation/reservation-invitation.model';

export const InvitationStateColorMap = new Map<ReservationInvitationState, string>([
  [ ReservationInvitationState.REJECTED, '--l-warn' ],
  [ ReservationInvitationState.CREATED, '--blue-300' ],
  [ ReservationInvitationState.EXPIRED, '--text-color-secondary' ],
  [ ReservationInvitationState.ACCEPTED, '--l-success' ],
  [ ReservationInvitationState.CANCELLED, '--text-color-secondary' ],
]);


@Component({
  selector: 'td[app-tag-cell]',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    NgbTooltipModule, TranslateModule
  ],
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss']
})
export class TagCellComponent {
  ReservationState = ReservationState;
  OfferState = OfferState;
  OrderState = OrderState;
  ReservationCurrentState = ReservationCurrentState;
  ReservationCurrentStateColorMap = ReservationCurrentStateColorMap;
  ReservationTableEntityType = ReservationTableEntityType;
  ReservationInvitationState = ReservationInvitationState;
  InvitationStateColorMap = InvitationStateColorMap;
  
  _reservation: IReservationTableEntity | undefined;
  get reservation(): IReservationTableEntity | undefined { return this._reservation; };
  @Input() set reservation(v: IReservationTableEntity | undefined) {
    this._reservation = v;

    if (v?.entityType === ReservationTableEntityType.RESERVATION) {
      this.free = v?.orderItem?.basePriceValue ? !(+v.orderItem.basePriceValue) : false;
      this.currentReservationState = v ? this.resHelper.getCurrentState(v) : null;
      this.orderExpired = v?.order ? this.orderHelper.isExpired(v.order.expiration) : false;
      this.reservationExpired = v?.expiration ? this.orderHelper.isExpired(v.expiration) : false;
    } else if (v?.entityType === ReservationTableEntityType.INVITATION) {
      this.currentInvitationState = v.state;
    }
  };
  currentReservationState: ReservationCurrentState | null = null;
  currentInvitationState: ReservationInvitationState | null = null;
  orderExpired: boolean = false;
  reservationExpired: boolean = false;
  free = false;

  constructor(
    private resHelper: ReservationHelperService,
    private orderHelper: OrderHelperService,
    public utilsService: UtilsService,
  ) {}


}
