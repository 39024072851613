import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Operation } from '../../enums/utils/operations.enum';
import { FormControl } from '@angular/forms';
import { IInvoiceProfile } from '../../models/invoice-profile/invoice-profile.model';
import { ModalService } from '../../services/modal.service';
import { InvoiceModalModalComponent } from '../../modals/invoice-profile-modal/invoice-profile-modal.component';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

export const getInvoiceProfileFC = (): FormControl<IInvoiceProfile | null> => {
  return new FormControl<IInvoiceProfile | null>(null, { nonNullable: true });
}


@Component({
  selector: 'app-invoice-profile-input',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
  ],
  templateUrl: './invoice-profile-input.component.html',
  styleUrls: ['./invoice-profile-input.component.scss']
})
export class InvoiceProfileInputComponent implements OnDestroy {
  Operation = Operation;

  @Input() invoiceProfileFC: FormControl<IInvoiceProfile | null> | undefined;

  subs: Subscription[] = [];

  constructor(
    private modalService: ModalService,
  ) {}


  onChangeInvoiceProfile(operation: Operation) {
    const res = this.modalService.openInvoiceProfileModal(InvoiceModalModalComponent, operation, this.invoiceProfileFC?.value || null);
    this.subs.push(
      res.subscribe(res => {
        this.invoiceProfileFC?.setValue(res.invoiceProfile);
        this.invoiceProfileFC?.markAsDirty();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}

