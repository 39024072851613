import { IOrganization } from "../organization/organization.model";
import { IReservation } from "../reservation/reservation.model";
import { IShopItem } from "../shop-item/shop-item.model";
import { IUser } from "../user/user.model";

export enum ReservationInvitationState {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}


export interface IReservationInvitation {
  id: number;
  uuid: string;

  state: ReservationInvitationState
  expireAt: string;
  rejectionFeedback: string | null;

  userId: number;
  user?: IUser;
  shopItemId: number;
  shopItem?: IShopItem;
  organizationId: number;
  organization?: IOrganization;
  reservationId: number | null;
  reservation?: IReservation | null;

  createdAt: string;
  updatedAt: string | null;
  acceptedAt: string | null;
  rejectedAt: string | null;
  cancelledAt: string | null;
}