<div (click)="onOpenCalendarOverlay(dateRangeSelectEl, $event)" class="filter-wrap relative">
  <p class="filter-title">
    {{ 'admin.event-shop-items-filter.dateRange.label' | translate }}
  </p>

  <div class="flex">
    @if (selectedDateRangeOptions.length) {
      <p-chip
        [label]="(selectedDateRangeOptions[0].label) + ' - ' + (selectedDateRangeOptions[1].label)"
        [removable]="true"
        (onRemove)="$event.stopPropagation();onRemoveDateRange()">
      </p-chip>
    } @else {
      <p-chip [label]="'admin.event-shop-items-filter.dateRange.all' | translate" [removable]="false"></p-chip>
    }
  </div>

  <p-calendar
    #dateRangeSelectEl
    [formControl]="FC"
    selectionMode="range"
    appendTo="body">
  </p-calendar>
</div>