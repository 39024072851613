import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';
import { IMessagesGetForCustomerCardResult } from 'src/app/shared/services/entities/messages/messages.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { AdminCustomerCardModalComponent, AdminCustomerCardTab } from '../../admin-customer-card-modal.component';
import { ReservationDetailModalComponent } from '../../../reservation-detail-modal/reservation-detail-modal.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-message-item',
  standalone: true,
  imports: [
    TranslateModule, NgTemplateOutlet, NgStyle,
    MenuModule, ButtonModule,
    UserNameOrEmailPipe, LocalizedDatePipe,
    NgbTooltipModule
  ],
  templateUrl: './message-item.component.html',
  styleUrl: './message-item.component.scss',
})
export class MessageItemComponent implements OnInit {

  @Input({required: true}) recipientUserId: number | undefined;
  @Input({required: true}) message: IMessagesGetForCustomerCardResult | undefined;

  contentCollapsed: boolean = true;

  messageMenuItems: MenuItem[] = [];
  sanitizedContent: SafeHtml | null = null;

  constructor(
    private navService: NavigationService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.sanitizedContent = this.sanitizeContent(this.content);
  }

  get content() {
    if (this.message?.contentHtml.length) {
      return this.message?.contentHtml;
    } else {
      return this.message?.contentText ?? '';
    }
  }

  get parsedFromName() {
    const regex = /"([^"]*)"/; // Regex to find the content between the first pair of ""
    const match = this.message?.channelContent.from.match(regex); // Apply regex to input string
    return match ? match[1] : null; // Return the matched content or null if no match is found
  }
  get parsedFrom() {
    // remove all " and <> from the string
    return this.message?.channelContent.from.replace(/["<>]/g, '');
  }

  sanitizeContent(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
  isContentHtml(content: string) {
    return content.includes('</');
  }

  onEventClick(eventUuid: string) {
    this.navService.goToAdminEvent({ eventUuid, newTab: true });
  }

  onOrderClick(orderNumber: number) {
    this.modalService.openAdminCustomerCardModal({
      c: AdminCustomerCardModalComponent,
      userId: this.recipientUserId!,
      activeTab: AdminCustomerCardTab.ORDERS,
      fromOrderNumbers: [orderNumber]
    })
  }

  onReservationClick(resrevationId: number) {
    this.modalService.openReservationDetailModal(ReservationDetailModalComponent, resrevationId);
  }
}
