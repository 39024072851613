import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallableNames, DbService } from '../../db.service';
import { IInvoiceProfile } from 'src/app/shared/models/invoice-profile/invoice-profile.model';
import { IInvoiceProfileCreateData } from 'src/app/shared/models/invoice-profile/invoice-profile-create-data.model';
import { IInvoiceProfileUpdateData } from 'src/app/shared/models/invoice-profile/invoice-profile-update-data.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceProfileService {

  constructor(
    private dbService: DbService,
  ) { }

  public getInvoiceProfile(organizationId: number): Observable<IInvoiceProfile | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoiceProfileGet, data: { organizationId } });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

  public createInvoiceProfile(data: IInvoiceProfileCreateData): Observable<IInvoiceProfile> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoiceProfileCreate, data });
    return this.dbService.handleObs(obs);
  }

  public updateInvoiceProfile(data: IInvoiceProfileUpdateData): Observable<IInvoiceProfile> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoiceProfileUpdate, data });
    return this.dbService.handleObs(obs);
  }
}
