import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter.component';
import { SelectItem } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { FilterItemChipsComponent, IFilterItemChip } from 'src/app/pages/admin/org-admin/reservations/reservation-table/reservation-table-filters/reservation-table-filter-item/types/_components/filter-item-chips/filter-item-chips.component';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { ChipModule } from 'primeng/chip';
import { Subscription } from 'rxjs';

export type IEventShopItemFilterItem_DATE_RANGE = {
  type: EventShopItemFilterItemType.DATE_RANGE
  value: SelectItem[] | null;
}

@Component({
  selector: 'app-event-si-filter-item-date-range',
  standalone: true,
  imports: [
    TranslateModule, ReactiveFormsModule,
    CalendarModule, ChipModule,
    FilterItemChipsComponent, LocalizedDatePipe,
  ],
  templateUrl: './event-si-filter-item-date-range.component.html',
  styleUrl: './event-si-filter-item-date-range.component.scss'
})
export class EventSiFilterItemDateRangeComponent implements OnInit, OnDestroy {

  @Input({required: true}) item: IEventShopItemFilterItem_DATE_RANGE | undefined;
  @Output() change = new EventEmitter<IEventShopItemFilterItem_DATE_RANGE>();

  FC = new FormControl<Date[]>([], { nonNullable: true });

  subs: Subscription[] = [];

  constructor(
    private localizedDate: LocalizedDatePipe
  ) {
    this.subs.push(
      this.FC.valueChanges.subscribe(v => {
        if (!this.item) return;
        this.change.emit({ ...this.item, value: this.selectedDateRangeOptions });
      })
    )
  }

  ngOnInit(): void {
    this.FC.setValue(this.item?.value?.map(x => x.value) ?? [], { emitEvent: false });
  }

  onOpenCalendarOverlay(calendar: Calendar, e: Event) {
    calendar.inputfieldViewChild?.nativeElement?.click();
  }

  get selectedDateRangeOptions(): SelectItem[] {
   return this.FC.value.map(x => ({ value: new Date(x).toUTCLikeDate(), label: this.localizedDate.transform(x, 'shortDate') ?? '' })); 
  }

  onRemoveDateRange() {
    this.FC.setValue([]);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
