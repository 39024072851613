import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/tag-cell/tag-cell.component';
import { PriceCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/price-cell/price-cell.component';
import { TypeCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/type-cell/type-cell.component';
import { ShopItemCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/shop-item-cell/shop-item-cell.component';
import { RowIndexCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/row-index-cell/row-index-cell.component';
import { OrderCellComponent } from '../../../../reservations/reservation-table-row/row-cell-components/order-cell/order-cell.component';
import { IReservationTableEntity } from 'src/app/shared/services/store/org-reservations-store.service';

@Component({
  selector: 'tr[app-customer-reservation-table-row]',
  standalone: true,
  imports: [
    CommonModule,
    // cells
    RowIndexCellComponent, ShopItemCellComponent, TypeCellComponent, PriceCellComponent, TagCellComponent, OrderCellComponent,
  ],
  templateUrl: './customer-reservation-table-row.component.html',
  styleUrls: ['./customer-reservation-table-row.component.scss']
})
export class CustomerReservationTableRowComponent {

  @Input() reservation: IReservationTableEntity | undefined;
  @Input() rowIndex: number | undefined;
}
