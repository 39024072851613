




.hidden-measure {
  position: absolute;
  visibility: hidden;
  white-space: pre;
}


