import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { finalize, Subject, take } from 'rxjs';
import { getIcoFC, IIcoFC, OrgIcoInputComponent } from 'src/app/pages/admin/org-admin/settings/components/org-ico-input/org-ico-input.component';
import { getLegalNameFC, ILegalNameFC, OrgLegalNameInputComponent } from 'src/app/pages/admin/org-admin/settings/components/org-legal-name-input/org-legal-name-input.component';
import { FormControl, FormGroup } from '@angular/forms';
import { OrgTermsConditionsInputsComponent } from '../../../form-sections/event-terms-conditions-form-section/components/org-terms-conditions-inputs/org-terms-conditions-inputs.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { EventAdminStoreService, IEventAdminState } from 'src/app/shared/services/store/event-admin-store.service';
import { IEventPublishableMissingInfoSetData } from 'src/app/shared/services/entities/events/events.service';
import { getOrgPrivacyPolicyFG, IOrgPrivacyPolicyFG } from '../../../form-sections/event-privacy-policy-form-section/org-privacy-policy-form-section.component';
import { OrgPrivacyPolicyInputsComponent } from '../../../form-sections/event-privacy-policy-form-section/components/org-privacy-policy-inputs/org-privacy-policy-inputs.component';
import { getOrgTermsConditionsFG, IOrgTermsConditionsFG } from '../../../form-sections/event-terms-conditions-form-section/org-terms-conditions-form-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceProfileInputComponent, getInvoiceProfileFC } from 'src/app/shared/components/invoice-profile-input/invoice-profile-input.component';
import { IInvoiceProfile } from 'src/app/shared/models/invoice-profile/invoice-profile.model';


export interface IEventNotPublishableReasons {
  missingOrgTOS?: boolean;
  missingOrgPrivacyPolicy?: boolean;
  missingOrgLegalName?: boolean;
  missingOrgInvoiceProfile?: boolean;
};

interface IMissingInfoForm {
  orgTos?: FormGroup<IOrgTermsConditionsFG>;
  orgPrivacyPolicy?: FormGroup<IOrgPrivacyPolicyFG>;
  legalName?: ILegalNameFC;
  invoiceProfile?: FormControl<IInvoiceProfile | null>
};

@Component({
  selector: 'app-event-publish-missing-info-modal',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
    ModalHeaderComponent, OrgIcoInputComponent, OrgLegalNameInputComponent, OrgTermsConditionsInputsComponent, OrgPrivacyPolicyInputsComponent, InvoiceProfileInputComponent,
  ],
  templateUrl: './event-publish-missing-info-modal.component.html',
  styleUrls: ['./event-publish-missing-info-modal.component.scss']
})
export class EventPublishMissingInfoModalComponent extends Modal implements OnInit {

  public continue = new Subject<boolean>();

  @Input() reasons: IEventNotPublishableReasons = {};

  form = new FormGroup<IMissingInfoForm>({

  });

  eventAdminState!: IEventAdminState;

  saving: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private eventAdminStore: EventAdminStoreService
  ) {
    super();

    this.eventAdminState = this.eventAdminStore.getState();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.handleFormInit();
  }

  private handleFormInit() {
    if (this.reasons.missingOrgPrivacyPolicy) {
      const FG = getOrgPrivacyPolicyFG();
      this.form.addControl('orgPrivacyPolicy', FG);
    }
    if (this.reasons.missingOrgTOS) {
      const FG = getOrgTermsConditionsFG();
      this.form.addControl('orgTos', FG);
    }
    if (this.reasons.missingOrgLegalName) {
      const FC = getLegalNameFC();
      this.form.addControl('legalName', FC);
    }
    if (this.reasons.missingOrgInvoiceProfile) {
      const FC = getInvoiceProfileFC();
      this.form.addControl('invoiceProfile', FC);
    }

  }

  onCancel() {
    this.continue.next(false);
    this.continue.complete();
    this.close();
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);

    const eventId = this.eventAdminState.event$.getValue()?.id;
    if (this.form.invalid || this.saving || !eventId) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();
    const data: IEventPublishableMissingInfoSetData = {
      eventId,
      orgPrivacyPolicy: formVals.orgPrivacyPolicy,
      orgTos: formVals.orgTos,
      legalName: formVals.legalName ?? undefined
    }
    this.saving = true;
    this.eventAdminStore.setEventPublishableMissingInfo(data).pipe(
      take(1),
      finalize(() => this.saving = false)
    ).subscribe({
      next: (res) => {
        this.continue.next(true);
        this.continue.complete();
        this.close();
      }
    });
  }


  get orgTosFC() {
    return this.form.controls['orgTos'];
  }
  get orgPrivacyPolicyFC() {
    return this.form.controls['orgPrivacyPolicy'];
  }
  get invoiceProfileFC() {
    return this.form.controls.invoiceProfile;
  }
  get legalNameFC() {
    return this.form.controls['legalName'];
  }
}
