import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SubtleInputBase } from '../subtle-input-base';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-input-textarea-subtle',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextareaModule
  ],
  templateUrl: './input-textarea-subtle.component.html',
  styleUrl: './input-textarea-subtle.component.scss'
})
export class InputTextareaSubtleComponent extends SubtleInputBase {

  @Input({required: true}) FC: FormControl | undefined;
  @Input() placeholder: string | null = null;

  
}
