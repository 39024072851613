import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferPaymentMethod } from 'src/app/shared/enums/transfer/transfer-payment-methods';
import { TransferProvider } from 'src/app/shared/enums/transfer/transfer-providers.enum';
import { CallableNames, DbService } from '../../db.service';
import { Language } from 'src/app/shared/enums/utils/languages.enum';
import { IPrice } from 'src/app/shared/models/price/price.model';
import { IOrgCustomDomainsVerifiedFE } from 'src/app/shared/models/organization/custom-domain.model';

export interface IPaymentGetInstructionsData {
  orderId: number;
  installmentNumber?: number;
  lang: Language;
};


export interface IPaymentInstructions {
  installmentPaid: boolean;
  orgCustomDomains: IOrgCustomDomainsVerifiedFE;
  provider?: TransferProvider;
  method?: TransferPaymentMethod;
  details?: IPaymentInstructionsDetails;
}

export enum BankAccountCountryCode {
  CZ = 'CZ',
  SK = 'SK'
}

export interface IPaymentInstructionsDetails {
  paymentUrl?: string;
  stripeClientSecret?: string;
  stripePublishableKey?: string;
  PG_CODE?: string;
  bankAccountNumber?: string;
  accountOwner?: string;
  iban?: string;
  bic?: string;
  variableSymbol?: string;
  price: IPrice;
  bankAccountCountryCode?: BankAccountCountryCode | null;
}

export interface IComgateGetMethodsRequest {
  merchant: string;
  secret: string;
  type?: 'xml' | 'json';
  lang?: string;
  curr?: string;
  country?: string;
}

export interface IComgateMethod {
  id: string;
  name: string;
  description: string;
  logo: string;
}

type IPaymentGetInstructionsResult = {
  instructions: IPaymentInstructions;
  noPayment: false;
} | {
  instructions: null;
  noPayment: true;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getComgateMethods(data: IComgateGetMethodsRequest): Observable<IComgateMethod[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPaymentsGetComgateMethods, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getInstructions(data: IPaymentGetInstructionsData): Observable<IPaymentGetInstructionsResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesPaymentsGetInstructions, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
