




<app-shop-item-module-wrapper [data]="data">

  <ng-container *ngIf="control" [formGroup]="control">

    <div class="mb-2">
      <label for="description">{{ 'admin.shop-item.form-section.description.short-description-input.label' | translate }}</label>
      <app-editor-wrap-component
        #sed
        [FC]="control.controls.shortDescription"
        [placeholder]="'admin.shop-item.form-section.description.short-description-input.placeholder' | translate"
        [overlay]="sedShortcodesOverlay.overlay">

        <ng-container slot="formats">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </span>
          <span class="ql-formats">
            <button aria-label="Link" class="ql-link"></button>
          </span>
        </ng-container>

      </app-editor-wrap-component>
    
    </div>

    <div class="">
      <label for="description">{{ 'admin.shop-item.form-section.description.description-input.label' | translate }}</label>
      
      <app-editor-wrap-component
        #ed
        [FC]="control.controls.description"
        [placeholder]="'admin.shop-item.form-section.description.description-input.placeholder' | translate"
        [overlay]="edShortcodesOverlay.overlay">

        <ng-container slot="formats">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </span>
          <span class="ql-formats">
            <button aria-label="Link" class="ql-link"></button>
          </span>
        </ng-container>

      </app-editor-wrap-component>
      
    </div>


  </ng-container>


</app-shop-item-module-wrapper>

<app-short-codes-overlay
  #sedShortcodesOverlay
  [menuItems]="sedShortcodeMenuItems">
</app-short-codes-overlay>

<app-short-codes-overlay
  #edShortcodesOverlay
  [menuItems]="edShortcodeMenuItems">
</app-short-codes-overlay>


