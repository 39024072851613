import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { IInternalTag } from 'src/app/shared/models/internal-tag/internal-tag.model';
import { IInternalTagGroup } from 'src/app/shared/models/internal-tag/internal-tag-group.model';

interface IInternalTagsGetForSiInternalTagsModalData {
  shopItemId: number;
}
interface IInternalTagsGetForSiInternalTagsModalDataResult {
  shopItem: IShopItem & { internalTags: IInternalTag[], sessionGroup: any };
  orgInternalTagsWithNullGroup: (IInternalTag & { internalTagGroup: null })[];
  orgInternalTagGroups: (IInternalTagGroup & { internalTags: IInternalTag[] })[];
}

export interface IInternalTagsCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
  code: string | null;
  internalTagGroupId: number | null;
  shopItemId: number | null;
};

export interface IInternalTagsUpdateData {
  internalTagId: number;

  title: string;
  shortDescription: string | null;
  code: string | null;
  internalTagGroupId: number | null;
};

interface IInternalTagGroupsGetAllByOrgData {
  orgId: number;
}

export interface IInternalTagGroupCreateData {
  orgId: number;
  
  title: string;
  shortDescription: string | null;
};
export interface IInternalTagGroupUpdateData {
  internalTagGroupId: number;
  
  title: string;
  shortDescription: string | null;
};

interface IInternalTagsSetToShopItemData {
  shopItemId: number;

  internalTagIds: number[];
};

interface IInternalTagsArchiveData {
  internalTagId: number;
};

interface ICallablesInternalTagsGetForFilterItemData {
  orgId: number;
}
type ICallablesInternalTagsGetForFilterItemDataResult = {
  id: number;
  title: string;
  internalTagGroup: {
    id: number;
    title: string;
  } | null;
}[];

interface IInternalTagUnarchiveData {
  internalTagId: number;
};

@Injectable({
  providedIn: 'root'
})
export class InternalTagsService {

  constructor(
    private dbService: DbService
  ) { }

  public unarchive(data: IInternalTagUnarchiveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsUnarchive, data });
    return this.dbService.handleObs(obs);
  }

  public getForFilterItem(data: ICallablesInternalTagsGetForFilterItemData): Observable<ICallablesInternalTagsGetForFilterItemDataResult>{
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsGetForFilterItem, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public archiveInternalTag(data: IInternalTagsArchiveData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsArchive, data });
    return this.dbService.handleObs(obs);
  }

  public setToShopItem(data: IInternalTagsSetToShopItemData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsSetToShopItem, data });
    return this.dbService.handleObs(obs);
  }

  public updateInternalTagGroup(data: IInternalTagGroupUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagGroupUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createInternalTagGroup(data: IInternalTagGroupCreateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagGroupCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getAllOrgInternalTagGroups(data: IInternalTagGroupsGetAllByOrgData): Observable<IInternalTagGroup[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagGroupsGetAllByOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateInternalTag(data: IInternalTagsUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createInternalTag(data: IInternalTagsCreateData): Observable<IInternalTag> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsCreate, data });
    return this.dbService.handleObs(obs);
  }

  public getForSiInternalTagsModal(data: IInternalTagsGetForSiInternalTagsModalData): Observable<IInternalTagsGetForSiInternalTagsModalDataResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInternalTagsGetForSiInternalTagsModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
