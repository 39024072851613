import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventShopItemFilterItemType } from '../../event-shop-items-filter/event-shop-items-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-event-si-filter-type-item',
  standalone: true,
  imports: [
    TranslateModule, FormsModule,
    CheckboxModule
  ],
  templateUrl: './event-si-filter-type-item.component.html',
  styleUrl: './event-si-filter-type-item.component.scss'
})
export class EventSiFilterTypeItemComponent {

  @Input({required: true}) type: EventShopItemFilterItemType | undefined;
  @Input({required: true}) selected: boolean = false;

  @Output() checkboxChange = new EventEmitter<{
    e: CheckboxChangeEvent;
    type: EventShopItemFilterItemType;
  }>();


  onCheckboxChange(e: CheckboxChangeEvent, type: EventShopItemFilterItemType) {
    this.checkboxChange.emit({e, type});
  }
}
