import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from 'src/app/shared/pipes/price.pipe';
import { OrderState } from 'src/app/shared/enums/order/order-states.enum';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';
import { collapseAnimation } from 'src/app/shared/animations/collapse.animation';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerOrderItemWrapComponent } from 'src/app/pages/admin/components/customer-orders/customer-order-list-item/customer-order-item-wrap/customer-order-item-wrap.component';
import { Color } from 'src/app/shared/enums/utils/colors';
import { CustomerCardOrderOrderItemComponent } from './customer-card-order-order-item/customer-card-order-order-item.component';
import { CustomerCardOrderOrderItemDiscountComponent } from './customer-card-order-order-item-discount/customer-card-order-order-item-discount.component';
import { ButtonModule } from 'primeng/button';
import { ModalService } from 'src/app/shared/services/modal.service';
import { finalize, take } from 'rxjs';
import { CustomerCardStoreService } from 'src/app/shared/services/store/customer-card-store.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderHelperService } from 'src/app/shared/services/helpers/order-helper.service';
import { OrderExpirationDatesModalComponent } from '../../order-expiration-dates-modal/order-expiration-dates-modal.component';
import { isOrderInvoiceIssuable } from '../../../../reservations/reservation-table-row/reservation-table-row.component';
import { IModifyInvoiceResult, InvoiceModifyModalComponent } from 'src/app/pages/customer-org-orders/invoice/invoice-modify-modal/invoice-modify-modal.component';
import { InvoicesService } from 'src/app/shared/services/entities/invoices/invoices.service';

@Component({
  selector: 'app-customer-card-order',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
    PricePipe, LocalizedDatePipe, UserNameOrEmailPipe, CustomerOrderItemWrapComponent, CustomerCardOrderOrderItemComponent, CustomerCardOrderOrderItemDiscountComponent,
    NgbTooltipModule
  ],
  templateUrl: './customer-card-order.component.html',
  styleUrls: ['./customer-card-order.component.scss'],
  animations: [ collapseAnimation ]
})
export class CustomerCardOrderComponent implements OnInit {
  OrderState = OrderState;

  @Input() order: IOrderFE | undefined;
  @Input() collapsed: boolean = true;
  @Input() set collapse(val: boolean) {
    if (val) this.collapsed = true;
  }
  @Input() loading: boolean = false;

  fetchingInvoicePlaceholder: boolean = false;
  isOrderInvoiceIssuable: boolean = false;

  constructor(
    private modalService: ModalService,
    private customerCardStore: CustomerCardStoreService,
    private navService: NavigationService,
    public orderHelper: OrderHelperService,
    private invoiceService: InvoicesService
  ) {}

  ngOnInit(): void {
    this.isOrderInvoiceIssuable = isOrderInvoiceIssuable(this.order);
  }

  onItem() {
    this.collapsed = !this.collapsed;
  }

  onChangeDeadline(order: IOrderFE) {
    const choosenDates$ = this.modalService.openOrderExpirationDatesModal(OrderExpirationDatesModalComponent, order.maturity, order.expiration);

    choosenDates$.pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res) {
          this.customerCardStore.updateOrderDates({
            orderId: order.orderId,
            expiration: res.newExpiration,
            maturity: res.newMaturity,
            changeInvoices: res.changeInvoices
          }).subscribe();
        }
      }
    });
  }

  onIssueInvoice() {
    if (!this.order) return;
    this.fetchingInvoicePlaceholder = true;
    this.invoiceService.getPlaceholderData(this.order.orderId).pipe(
      take(1),
      finalize(() => this.fetchingInvoicePlaceholder = false)
    ).subscribe((placeholderInvoice) => {
      const modified = this.modalService.openInvoiceModifyModal(InvoiceModifyModalComponent, placeholderInvoice, this.order!, false, false, true);
      modified.pipe(take(1)).subscribe((res: IModifyInvoiceResult) => {
        if (res.affectedReservationIds && res.affectedReservationIds.length) {
          this.isOrderInvoiceIssuable = false;
        }
      });
    });
  }

  onOpenOrderPage(order: IOrderFE, event: Event) {
    event.stopPropagation();
    this.navService.goToCustomerSingleOrder({
      orderUuid: order.orderUuid,
      newTab: true,
      orgCustomDomains: order.organization.customDomainsVerified
    });
  }

  onOpenOrderInvoicePage(order: IOrderFE, event: Event) {
    event.stopPropagation();
    this.navService.goToOrderInvoice({
      orderUuid: order.orderUuid,
      newTab: true,
      orgCustomDomains: order.organization.customDomainsVerified
    });
  }

  get orderSummaryBgColor() {
    switch (this.order?.state) {
      case OrderState.CANCELLED:
        return Color.UNKNOWN;
      case OrderState.COMPLETED:
        return Color.SUCCESS;
      case OrderState.PENDING:
        return Color.INFO;
      default:
        return Color.UNKNOWN;
    }
  }
}
