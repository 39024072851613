

<div 
  class="row">
  <span class="flex flex-nowrap align-items-baseline column-gap-1">
    <span
      (click)="beneficiaryClickListener ? beneficiaryClickListener.next( beneficiaryUser ) : null"
      [class.clickable]="beneficiaryClickListener"
      class="font-bold name name-ellipsis">
      {{ beneficiaryUser?.orgUsers ?? [] | userNameOrEmail:organizationId:true }}
    </span>

    @if (hasAnyCustomerNotes) {
      <i
        (click)="commentClickListener ? commentClickListener.next(beneficiaryUser) : null"
        [class.cursor-pointer]="commentClickListener"
        class="pi pi-comment text-bluegray-200 text-sm">
      </i>
    }
  </span>

</div>

<div class="row">
  <ng-container *ngIf="beneficiaryUser?.parentId">
    <span>{{ 'admin.reservations.table.cell.beneficiary.child.label' | translate }}</span>
    <span *ngIf="beneficiaryUser?.parentId && additionalUserData?.birthDate">, {{ additionalUserData?.birthDate | age | async }}</span>
  </ng-container>
  <span *ngIf="beneficiaryUser?.email as e" [appTooltipCopy]="e" class="email-ellipsis cursor-pointer">
    {{ e }}
  </span>
</div>
