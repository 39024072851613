import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputResizeHelperService {

  constructor() { }
}


/**
 * Options for auto-resizing a field (input or textarea).
 */
export interface AutoResizeOptions {
  /**
   * Whether to include the field's padding in the width calculation.
   */
  includePadding: boolean;
  /**
   * Whether to use the placeholder text for measuring width if the field’s value is empty or shorter.
   * Defaults to false.
   */
  includePlaceholder?: boolean;
  /**
   * Minimum width in pixels.
   */
  minWidth?: number;
  /**
   * Maximum width in pixels (for inputs only; textareas will always use max-width: 100%).
   */
  maxWidth?: number;
  /**
   * Extra width (in pixels) to add to the calculated width.
   */
  extraWidth?: number;
  /**
   * Whether to include the field's borders in the width calculation.
   */
  includeBorders: boolean;
}

/**
 * Helper function to measure the width of a text string using a hidden span.
 *
 * @param text - The text to measure.
 * @param font - The font settings to use for measurement.
 * @returns The width of the text in pixels.
 */
function measureTextWidth(text: string, font: string): number {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'pre';
  span.style.font = font;
  span.textContent = text || ' ';
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return width;
}

/**
 * Adjusts the width (and for textareas, also the height) of the provided field based on its content.
 *
 * For inputs, the width is based on the rendered width of the entire text.
 * For textareas, the width is based on the widest line, and the height is set to fit the content.
 * Textareas always get a CSS max-width of 100% so they can shrink to fit their container.
 *
 * For textareas that are nearly empty, if the computed scrollHeight is only slightly larger than one line
 * (which would cause an extra row to appear), the height is forced to exactly one line.
 *
 * @param field - The HTMLInputElement or HTMLTextAreaElement to adjust.
 * @param options - The auto-resize options.
 */
export function adjustFieldWidth(
  field: HTMLInputElement | HTMLTextAreaElement,
  options: AutoResizeOptions
): void {
  const computed = window.getComputedStyle(field);
  let textToMeasure = field.value;
  if (options.includePlaceholder && field.placeholder.length > textToMeasure.length) {
    textToMeasure = field.placeholder;
  }

  let measuredWidth = 0;
  if (field.tagName.toLowerCase() === 'textarea') {
    // For textareas, measure each line individually and use the widest.
    const lines = textToMeasure.split('\n');
    lines.forEach((line) => {
      const lineWidth = measureTextWidth(line || field.placeholder || ' ', computed.font);
      if (lineWidth > measuredWidth) {
        measuredWidth = lineWidth;
      }
    });
  } else {
    measuredWidth = measureTextWidth(textToMeasure || field.placeholder || ' ', computed.font);
  }

  // Optionally add padding.
  if (options.includePadding) {
    const paddingLeft = parseFloat(computed.paddingLeft) || 0;
    const paddingRight = parseFloat(computed.paddingRight) || 0;
    measuredWidth += paddingLeft + paddingRight;
  }

  // Optionally add borders.
  if (options.includeBorders) {
    const borderLeft = parseFloat(computed.borderLeftWidth) || 0;
    const borderRight = parseFloat(computed.borderRightWidth) || 0;
    measuredWidth += borderLeft + borderRight;
  }

  // Add any extra width.
  if (options.extraWidth) {
    measuredWidth += options.extraWidth;
  }

  // Enforce minimum width.
  if (options.minWidth && measuredWidth < options.minWidth) {
    measuredWidth = options.minWidth;
  }
  // For inputs only, enforce maximum width if provided.
  if (field.tagName.toLowerCase() !== 'textarea' && options.maxWidth && measuredWidth > options.maxWidth) {
    measuredWidth = options.maxWidth;
  }

  // Apply the computed width.
  field.style.width = measuredWidth + 'px';

  if (field.tagName.toLowerCase() === 'textarea') {
    // Ensure the textarea can shrink to fit its container.
    field.style.maxWidth = '100%';

    // Auto-resize height:
    field.style.height = 'auto';
    const scrollHeight = field.scrollHeight;
    const computedStyle = window.getComputedStyle(field);
    const lineHeight = parseFloat(computedStyle.lineHeight);

    // If the content is nearly empty (no newline) and the scrollHeight is only slightly more than one line,
    // force the height to exactly one line to avoid an extra row.
    let newHeight = scrollHeight;
    if (!field.value.includes('\n') && scrollHeight > lineHeight && scrollHeight < lineHeight * 1.5) {
      newHeight = lineHeight;
    }
    field.style.height = newHeight + 'px';
  }
}

/**
 * Processes a single field (input or textarea) by setting its initial dimensions and
 * attaching an input event listener for dynamic resizing.
 *
 * Processed fields are marked with the "auto-resized" class to avoid duplicate processing.
 *
 * @param field - The HTMLInputElement or HTMLTextAreaElement to process.
 * @param options - The auto-resize options.
 */
function processField(field: HTMLInputElement | HTMLTextAreaElement, options: AutoResizeOptions): void {
  if (field.classList.contains('auto-resized')) {
    return;
  }
  field.classList.add('auto-resized');
  adjustFieldWidth(field, options);
  field.addEventListener('input', () => adjustFieldWidth(field, options));
  // detects programmatic changes to the value property - for example primeng's calendar component wont fire `input` nor `change` events when the date is selected from the modal
  attachValueObserver(field, () => adjustFieldWidth(field, options));
}

/**
 * Processes a container element by:
 * - If the container itself is a field, processing it directly.
 * - Otherwise, finding all input and textarea elements within it and applying auto-resizing.
 * - Also sets up a MutationObserver to watch for any new fields added dynamically.
 *
 * The container (or field) is marked with the "resized-observed" class to avoid duplicate processing.
 *
 * @param container - The container HTMLElement or a field element.
 * @param options - The auto-resize options.
 * @param observerStore - Array to store MutationObservers for later cleanup.
 */
function processContainer(
  container: HTMLElement,
  options: AutoResizeOptions,
  observerStore: MutationObserver[]
): void {
  if (container.classList.contains('resized-observed')) {
    return;
  }
  container.classList.add('resized-observed');

  const tag = container.tagName.toLowerCase();
  if (tag === 'input' || tag === 'textarea') {
    processField(container as HTMLInputElement | HTMLTextAreaElement, options);
    return;
  }

  container.querySelectorAll('input, textarea').forEach((field) => {
    processField(field as HTMLInputElement | HTMLTextAreaElement, options);
  });

  const containerObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLElement) {
          if (node.tagName.toLowerCase() === 'input' || node.tagName.toLowerCase() === 'textarea') {
            processField(node as HTMLInputElement | HTMLTextAreaElement, options);
          } else {
            node.querySelectorAll('input, textarea').forEach((nestedField) => {
              processField(nestedField as HTMLInputElement | HTMLTextAreaElement, options);
            });
          }
        }
      });
    });
  });
  containerObserver.observe(container, { childList: true, subtree: true });
  observerStore.push(containerObserver);
}

/**
 * Sets up a global MutationObserver that continuously watches for new elements with the specified class.
 *
 * When an element is found:
 * - If it is an input or textarea, it is processed directly.
 * - Otherwise, if it’s a container element, all its descendant fields are processed
 *   and a local observer is attached for future dynamic changes.
 *
 * This observer will run indefinitely until you explicitly disconnect it.
 *
 * @param elementClass - The class name for elements to process (e.g., "resized").
 * @param observerStore - Array where all MutationObservers are stored for later cleanup.
 * @param options - The auto-resize options.
 * @returns The global MutationObserver instance.
 */
export function observeResizedContainers(
  elementClass: string,
  observerStore: MutationObserver[],
  options: AutoResizeOptions
): MutationObserver {
  document
    .querySelectorAll(`.${elementClass}:not(.resized-observed)`)
    .forEach((el) => {
      const tag = el.tagName.toLowerCase();
      if (tag === 'input' || tag === 'textarea') {
        processField(el as HTMLInputElement | HTMLTextAreaElement, options);
        el.classList.add('resized-observed');
      } else {
        processContainer(el as HTMLElement, options, observerStore);
      }
    });

  const globalObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLElement) {
          if (node.classList.contains(elementClass) && !node.classList.contains('resized-observed')) {
            const tagName = node.tagName.toLowerCase();
            if (tagName === 'input' || tagName === 'textarea') {
              processField(node as HTMLInputElement | HTMLTextAreaElement, options);
              node.classList.add('resized-observed');
            } else {
              processContainer(node, options, observerStore);
            }
          }
          node.querySelectorAll(`.${elementClass}:not(.resized-observed)`).forEach((descendant) => {
            const tagName = descendant.tagName.toLowerCase();
            if (tagName === 'input' || tagName === 'textarea') {
              processField(descendant as HTMLInputElement | HTMLTextAreaElement, options);
              descendant.classList.add('resized-observed');
            } else {
              processContainer(descendant as HTMLElement, options, observerStore);
            }
          });
        }
      });
    });
  });

  globalObserver.observe(document.body, { childList: true, subtree: true });
  observerStore.push(globalObserver);
  return globalObserver;
}


function attachValueObserver(field: HTMLInputElement | HTMLTextAreaElement, callback: () => void): void {
  const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(field), 'value');
  if (descriptor && descriptor.set) {
    const originalSetter = descriptor.set;
    Object.defineProperty(field, 'value', {
      get: descriptor.get,
      set: function(val: any) {
        originalSetter.call(this, val);
        callback();
      },
      configurable: true,
      enumerable: true
    });
  }
}