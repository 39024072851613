



<app-collapsible-form-section [formSection]="formSection">

  <div slot="moreActions">

  </div>

  <div *ngIf="formSection?.control" [formGroup]="$any(formSection!.control)" slot="body">

    <div class="box">

      <app-org-privacy-policy-inputs [parentForm]="$any(formSection!.control)"></app-org-privacy-policy-inputs>

    </div>

  </div>

</app-collapsible-form-section>
